import {
  Box,
  Button,
  Grid,
  Icon,
  Typography,
  unityTheme,
} from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setRentalStructures } from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { RootState } from "../../../calc-store/reducers";
import { structureRentalTypes } from "../../../common/constants";
import { RentalTypes } from "../../../common/enums";
import IStructureRentalParm from "../../../interfaces/IStructureRentalParm";
import { Dropdown, TextBox } from "../../controls";
import { Divider } from "../../controls/Divider/Divider";
import { SubSectionFieldsWrap } from "./../../../shared/theme/structureRentalStyle";

const GetStructureRentals = (
  rentalStructures: IStructureRentalParm[],
  deleteFunction,
  valuesChange
) => {
  return rentalStructures.map((structure) => (
    <SubSectionFieldsWrap
      key={structure.RentalId}
      theme={unityTheme}
      className="sub-fields-wrap"
    >
      <Box theme={unityTheme} className="sub-sections-fields">
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={12} md={3}>
            <TextBox
              id="txtTermFrom"
              label="Term From"
              type="number"
              value={structure.TermFrom}
              onChange={(e) => valuesChange("TermFrom", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3}>
            <TextBox
              id="txtTermTo"
              label="Term To"
              type="number"
              value={structure.TermTo}
              onChange={(e) => valuesChange("TermTo", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3}>
            <TextBox
              id="txtAmount"
              label="Amount"
              type="text"
              value={structure.Amount}
              masking
              currency
              disabled={
                structure.RentalType == RentalTypes.InterestOnly ||
                structure.RentalType == RentalTypes.Zero
              }
              onChange={(e) => valuesChange("Amount", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3}>
            <Dropdown
              label="Type"
              items={structureRentalTypes}
              value={structure.RentalType}
              onChange={(e) =>
                valuesChange("RentalType", e.target.value, structure.RentalId)
              }
              testid="cbxStructureRentalType"
            />
          </Grid>
        </Grid>
      </Box>
      <Box theme={unityTheme} className="sub-section-action">
        <Button
          theme={unityTheme}
          key={"btnDelete" + structure.RentalId}
          onClick={() => deleteFunction(structure.RentalId)}
          secondary
          variant="outlined"
          iconText={<Icon name="CloseBlack" />}
        />
      </Box>
    </SubSectionFieldsWrap>
  ));
};

export const StructureRental = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRepaymentPlanCalculation
  );
  const dispatch = useDispatch();

  const addRental = () => {
    var structures = storeState.structureRentalDetail;
    var count = structures.length;
    var newRental: IStructureRentalParm = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: "0.0",
      TermFrom: count > 0 ? parseFloat(structures[count - 1].TermTo) + 1 : 1,
      TermTo: storeState.contractTerms,
    };
    structures.push(newRental);
    dispatch(setRentalStructures(structures));
  };

  const deleteRental = (rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToDel = structures.findIndex((x) => x.RentalId == rentalId);
    structures.splice(indexToDel, indexToDel > -1 ? 1 : 0);
    dispatch(setRentalStructures(structures));
  };

  const valuesChange = (changeType: string, newValue: any, rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToEdit = structures.findIndex((x) => x.RentalId == rentalId);
    if (indexToEdit > -1) {
      var value = structures[indexToEdit];
      if (changeType == "TermFrom") value.TermFrom = newValue;
      else if (changeType == "TermTo") value.TermTo = newValue;
      else if (changeType == "Amount") value.Amount = newValue;
      else if (changeType == "RentalType") {
        value.RentalType = newValue;
        if (newValue == "Interest Only") value.Amount = 0.0;
      }
    }
    dispatch(setRentalStructures(structures));
  };

  if (storeState.rentalStructure == "true") {
    return (
      <div>
        <Typography component="p" variant="body1" theme={unityTheme}>
          Rental Input:
        </Typography>
        <Box theme={unityTheme} marginTop={3}>
          {GetStructureRentals(
            storeState.structureRentalDetail,
            deleteRental,
            valuesChange
          )}
          <Grid theme={unityTheme} container marginTop={3}>
            <Grid theme={unityTheme} item xs={12}>
              <Button
                className="btn-w-100"
                theme={unityTheme}
                secondary
                text="Add More"
                onClick={() => addRental()}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </div>
    );
  } else {
    return <div></div>;
  }
};
