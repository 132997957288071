import React from "react";
import { Typography, unityTheme } from "@ntpkunity/controls";
import { Table } from "../../controls/Table/Table";
interface AmortizationWithTaxResponseDetail {
  rows: [];
}

export const AmortizationWithTaxResponseDetail: React.FC<
  AmortizationWithTaxResponseDetail
> = ({ rows }: AmortizationWithTaxResponseDetail) => {
  const columns = [] as any;
  columns.push("Rental Number");
  columns.push("Opening Principal");
  columns.push("Rental DueDate");
  columns.push("Rental Amount");
  columns.push("Tax On Rental Amount");
  columns.push("Rental Amount Exclusive Of Tax");
  columns.push("Rental Principal");
  columns.push("Tax On Rental Principal");
  columns.push("Rental Principal Exclusive Of Tax");
  columns.push("Rental Interest");
  columns.push("Tax On Rental Interest");
  columns.push("Rental Interest Exclusive Of Tax");
  columns.push("Periodic Interest");
  columns.push("Tax On Periodic Interest");
  columns.push("Periodic Interest Exclusive Of Tax");
  columns.push("Closing Principal");

  if (rows.length > 0) {
    return (
      <div>
        <Typography component="h6" variant="h6" theme={unityTheme}>
          Repayment Plan
        </Typography>
        <Table columns={columns} rows={rows} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
