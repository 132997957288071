import { useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import FinancialCriteria from "./FinancialCriteria";

export const RepaymentPlan = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRepaymentPlanCalculation
  );
  return <FinancialCriteria tabType="Rental" storeState={storeState} />;
};
