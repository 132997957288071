import ActionTypes from "../actionTypes";

type IExcelData = {
  ExcelData: [];
  ExcelMultiple: [];
  selectedComponent: string;
};

const initialState: IExcelData = {
  ExcelData: [],
  ExcelMultiple: [],
  selectedComponent: "",
};

export function reducerExcelData(
  currentState = initialState,
  action: any
): IExcelData {
  switch (action.type) {
    case ActionTypes.SET_EXCEL_DATA:
      return {
        ...currentState,
        ExcelData: action.payload,
      };
    case ActionTypes.SET_PLANDIALOG_SELECTEDCOMPONENT:
      return {
        ...currentState,
        selectedComponent: action.payload,
      };
    case ActionTypes.SET_PLAN_MULTIPLEDATA:
      return {
        ...currentState,
        ExcelMultiple: action.payload,
      };
    default:
      return currentState;
  }
}
