import { IAffordabilityProps } from "../calc-store/reducers/reducersAffordabilityChecks";
import { Affordability } from "../common/enums";

export const ValidateAffordabilityChecks = (
  state: IAffordabilityProps,
  method: String
) => {
  if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: " Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.contractTerms == "" ||
    state.contractTerms == "0" ||
    state.contractTerms == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Contract Duration in Months.",
    };
  } else if (state.APR == "" || parseFloat(state.APR) <= 0) {
    return {
      visible: true,
      error: "Please provide valid Interest Rate.",
    };
  } else if (state.rentalAmount == "" || parseFloat(state.rentalAmount) <= 0) {
    return {
      visible: true,
      error: "Please provide valid Periodic Repayment Amount.",
    };
  } else if (
    method == Affordability.Finance &&
    (state.downPayment == "" || parseFloat(state.downPayment) <= 0)
  ) {
    return {
      visible: true,
      error: "Please provide valid Down Payment.",
    };
  } else if (
    method == Affordability.DownPayment &&
    (state.financedAmount == "" || parseFloat(state.financedAmount) <= 0)
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending  Amount.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
