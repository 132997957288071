import { IReducerAnnuityStraightLineCalculation } from "../calc-store/reducers/reducersAnnuityStraightLine";
export const ValidateAnnuityStraightLine = (
  state: IReducerAnnuityStraightLineCalculation
) => {
  if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.contractTerms == "" ||
    state.contractTerms == "0" ||
    state.contractTerms == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Contract Duration in Months.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending Amount.",
    };
  } else if (
    state.structureRentalDetail == undefined ||
    state.structureRentalDetail == null ||
    state.structureRentalDetail.length <= 0
  ) {
    return {
      visible: true,
      error: "Please provide the Rental Details.",
    };
  } else if (state.addTax == true) {
    if (
      state.taxRate == undefined ||
      state.taxRate == null ||
      state.taxRate <= 0
    ) {
      return {
        visible: true,
        error: "Please provide the Tax Rate",
      };
    } else {
      return {
        visible: false,
        error: "",
      };
    }
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
