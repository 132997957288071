import { ActionTypes } from "../actionTypes/earlyTermination.actionTypes";
import { IETQuote } from "../../interfaces/earlyTermination/etQuote.type";
import { IComponent } from "../../interfaces/earlyTermination/component.type";
import { ITax } from "../../interfaces/earlyTermination/tax.type";
import { IETRentalDetail } from "../../interfaces/earlyTermination/etRentalDetail.type";
import {
  ETPenaltyMethod,
  ETPenaltyReferenceAmountForPercentage,
  RentalStatus,
} from "../../common/enums";
import { IETPenalty } from "../../interfaces/earlyTermination/etPenalty.type";
import { setSecurityDeposit } from "../actions/actionEarlyTermination";

let defaultReceivable: IComponent[] = [
  {
    Amount: undefined,
    ComponentName: "",
    ComponentId: 1,
  },
];

let defaultPayable: IComponent[] = [
  {
    Amount: undefined,
    ComponentName: "",
    ComponentId: 1,
  },
];

let defaultTax: ITax[] = [
  {
    IsLess: false,
    TaxAmount: undefined,
    TaxName: "",
    TaxId: 1,
  },
];

let defaultRentalDetail: IETRentalDetail[] = [
  {
    ClosingPrincipal: undefined,
    Inclusive: [],
    OpeningPrincipal: undefined,
    Overdues: [],
    PeriodicInterest: undefined,
    RentalAmount: undefined,
    RentalDueDate: new Date(),
    RentalInterest: undefined,
    RentalNo: 1,
    RentalPrincipal: undefined,
    Status: RentalStatus.Paid,
    GrossRental: undefined,
    RentalId: 1,
  },
];

let defaultETPenalty: IETPenalty = {
  ETPenaltyAmount: undefined,
  ETPenaltyMethod: ETPenaltyMethod.None,
  ETPenaltyPercentage: undefined,
  ETPenaltyReferenceAmountForPercentage:
    ETPenaltyReferenceAmountForPercentage.None,
  NoOfDays: undefined,
};

const initialState: IETQuote = {
  ContractInterestRate: undefined,
  ClientEtDate: new Date(),
  EtQuoteGenerationDate: new Date(),
  OverDueInterestRate: undefined,
  ETPenalty: defaultETPenalty,
  Payables: defaultPayable,
  Receivables: defaultReceivable,
  RentalDetails: defaultRentalDetail,
  Taxes: defaultTax,
  ETMethod: "",
  AssetPrice: undefined,
  TotalOverDueAmount: undefined,
  etCompRentalDetails: [],
  SundryCharges: undefined,
  Additionalcharges: undefined,
  LatePaymentCharges: undefined,
  ETNetReceivableType: undefined,
  SecurityDeposit: undefined,
  UnallocatedAmount: undefined,
  selectComponent: "Main",
};

export function reducersEarlyTermination(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_CONTRACT_INTEREST_RATE: {
      return {
        ...currentState,
        ContractInterestRate: action.payload,
      };
    }
    case ActionTypes.SET_ET_METHOD: {
      return {
        ...currentState,
        ETMethod: action.payload,
      };
    }
    case ActionTypes.SET_OVER_DUE_INTEREST_RATE: {
      return {
        ...currentState,
        OverDueInterestRate: action.payload,
      };
    }
    case ActionTypes.SET_ET_QUOTE_GENERATION_DATE: {
      return {
        ...currentState,
        EtQuoteGenerationDate: action.payload,
      };
    }
    case ActionTypes.SET_CLIENT_ET_DATE: {
      return {
        ...currentState,
        ClientEtDate: action.payload,
      };
    }
    case ActionTypes.SET_ET_PENALTY_METHOD: {
      return {
        ...currentState,
        ETPenalty: {
          ...currentState.ETPenalty,
          ETPenaltyMethod: action.payload,
        },
      };
    }
    case ActionTypes.SET_ET_PENALTY_REFERENCE_AMOUNT_FOR_PERCENTAGE: {
      return {
        ...currentState,
        ETPenalty: {
          ...currentState.ETPenalty,
          ETPenaltyReferenceAmountForPercentage: action.payload,
        },
      };
    }
    case ActionTypes.SET_ET_PENALTY_AMOUNT: {
      return {
        ...currentState,
        ETPenalty: {
          ...currentState.ETPenalty,
          ETPenaltyAmount: action.payload,
        },
      };
    }
    case ActionTypes.SET_ET_PENALTY_PERCENTAGE: {
      return {
        ...currentState,
        ETPenalty: {
          ...currentState.ETPenalty,
          ETPenaltyPercentage: action.payload,
        },
      };
    }
    case ActionTypes.SET_NO_OF_DAYS: {
      return {
        ...currentState,
        ETPenalty: { ...currentState.ETPenalty, NoOfDays: action.payload },
      };
    }
    case ActionTypes.SET_RECEIVABLES: {
      return {
        ...currentState,
        Receivables: action.payload,
      };
    }
    case ActionTypes.SET_PAYABLES: {
      return {
        ...currentState,
        Payables: action.payload,
      };
    }
    case ActionTypes.SET_TAXES: {
      return {
        ...currentState,
        Taxes: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_DETAILS: {
      return {
        ...currentState,
        RentalDetails: action.payload,
      };
    }
    case ActionTypes.SET_COMP_RENTAL_DETAIL: {
      return {
        ...currentState,
        etCompRentalDetails: action.payload,
      };
    }
    case ActionTypes.SET_ASSET_PRICE: {
      return {
        ...currentState,
        AssetPrice: action.payload,
      };
    }
    case ActionTypes.SET_TOTAL_OVERDUE_AMOUNT: {
      return {
        ...currentState,
        TotalOverDueAmount: action.payload,
      };
    }
    case ActionTypes.SET_SUNDRY_CHARGES: {
      return {
        ...currentState,
        SundryCharges: action.payload,
      };
    }
    case ActionTypes.SET_ADDITIONAL_CHARGES: {
      return {
        ...currentState,
        Additionalcharges: action.payload,
      };
    }
    case ActionTypes.SET_LATE_PAYMENT_CHARGES: {
      return {
        ...currentState,
        LatePaymentCharges: action.payload,
      };
    }
    case ActionTypes.SET_PURCHASE_RETURN: {
      return {
        ...currentState,
        ETNetReceivableType: action.payload,
      };
    }
    case ActionTypes.SET_SECURITY_DEPOSIT: {
      return {
        ...currentState,
        SecurityDeposit: action.payload,
      };
    }
    case ActionTypes.SET_UNALLOCATED_AMOUNT: {
      return {
        ...currentState,
        UnallocatedAmount: action.payload,
      };
    }
    case ActionTypes.SET_COMPONENT_NAME: {
      return {
        ...currentState,
        selectComponent: action.payload,
      };
    }
    default:
      return currentState;
  }
}
