import { Box, Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import {
  setInclusiveTax,
  setRentalComponent,
  setTaxRate,
} from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { RootState } from "../../../calc-store/reducers";
import { Dropdown, TextBox } from "../../controls";
import { rentalComponentsNoTax, yesNo } from "../../../common/constants";

interface IRepaymentPlanTax {
  visible: boolean;
}
export const RepaymentTax = ({ visible = false }: IRepaymentPlanTax) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRepaymentPlanCalculation
  );
  const dispatch = useDispatch();
  if (storeState.repaymentStructure == "true" || visible) {
    return (
      <Box theme={unityTheme}>
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"taxRate"}
              id="taxRate"
              label="Tax Rate"
              value={storeState.taxRate}
              type="text"
              onChange={(e) => {
                dispatch(setTaxRate(e));
              }}
              endAdornment="%"
              masking
              scale={4}
              numeric
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={yesNo}
              label="Is Tax Inclusive"
              onChange={(e) => {
                dispatch(setInclusiveTax(e.target.value));
              }}
              value={storeState.inclusivetax}
              testid="cbxIsTaxInclusive"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalComponentsNoTax}
              label="Rental Component"
              onChange={(e) => {
                dispatch(setRentalComponent(e.target.value));
              }}
              value={storeState.rentalComponent}
              testid="cbxAnnuityRentalComponent"
            />
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <div></div>;
  }
};
