import ActionTypes from "../actionTypes";
export function setCommissionRentalMode(parm: any) {
  return {
    type: ActionTypes.SET_Commission_Rental_Mode,
    payload: parm,
  };
}
export function setCommissionRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.Set_Commission_Rental_Frequency,
    payload: parm,
  };
}
export function setCommissionStartDate(parm: any) {
  return {
    type: ActionTypes.Set_Commission_Start_Date,
    payload: parm,
  };
}
export function setCommissionDiscountRate(parm: number = 0.0) {
  return {
    type: ActionTypes.Set_Commission_Discount_Rate,
    payload: parm,
  };
}
export function setCommissionFinanceAmount(parm: any) {
  return {
    type: ActionTypes.Set_Commission_Finance_Amount,
    payload: parm,
  };
}
export function setBasicCommission(parm: string) {
  return {
    type: ActionTypes.Set_Basic_Commission,
    payload: parm,
  };
}

export function setMinimumCommission(parm: string) {
  return {
    type: ActionTypes.Set_Minimum_Commission,
    payload: parm,
  };
}

export function setCommissionRentalStructures(parm: any) {
  return {
    type: ActionTypes.Set_Commission_Rental_Structures,
    payload: parm,
  };
}
export function setCommissionResponse(parm: any) {
  return {
    type: ActionTypes.Set_Commission_Response,
    payload: parm,
  };
}
export function setCommissionResidualValue(parm: any) {
  return {
    type: ActionTypes.SET_COMMISSION_RESIDUAL_VALUE,
    payload: parm,
  };
}
