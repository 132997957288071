import ActionTypes from "../actionTypes";
import ISubsidyRentalSlabs from "../../interfaces/ISubsidyRentalSlabs";

let subsidyRentalSlabs: ISubsidyRentalSlabs[] = [
  {
    startTerm: 1,
    endTerm: 1,
    amount: 0,
    rentalId: 1,
  },
];

export interface IReducerSubsidyCalculation {
  rentalMode: string;
  rentalFrequency: string;
  rentalSlabs: ISubsidyRentalSlabs[];
  residualValue: string;
  financedAmount: string;
  financierRate: string;
  totalSubsidyAmount: string;
  manufacturerSubsidyAmount: string;
  dealerSubsidyAmount: string;
  subsidyType: string;
  customerRate: string;
  dealerRate: string;
  manufacturerRate: string;
}

const initialState: IReducerSubsidyCalculation = {
  rentalMode: "",
  rentalFrequency: "",
  rentalSlabs: subsidyRentalSlabs,
  residualValue: "",
  financedAmount: "",
  financierRate: "",
  totalSubsidyAmount: "",
  subsidyType: "Rate",
  customerRate: "",
  dealerRate: "",
  manufacturerRate: "",
  manufacturerSubsidyAmount: "",
  dealerSubsidyAmount: "",
};
export function reducersSubsidyCalculation(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_SUBSIDY_RENTAL_MODE: {
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_RENTAL_FREQ:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_SUBSIDY_RENTAL_SLABS: {
      return {
        ...currentState,
        rentalSlabs: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_FINANCIER_RATE: {
      return {
        ...currentState,
        financierRate: action.payload,
      };
    }
    case ActionTypes.SET_TOTAL_SUBSIDY_AMOUNT: {
      return {
        ...currentState,
        totalSubsidyAmount: action.payload,
      };
    }
    case ActionTypes.SET_MANUFACTURER_SUBSIDY_AMOUNT: {
      return {
        ...currentState,
        manufacturerSubsidyAmount: action.payload,
      };
    }
    case ActionTypes.SET_DEALER_SUBSIDY_AMOUNT: {
      return {
        ...currentState,
        dealerSubsidyAmount: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_TYPE: {
      return {
        ...currentState,
        subsidyType: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_MANUFACTURER_RATE: {
      return {
        ...currentState,
        manufacturerRate: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_DEALER_RATE: {
      return {
        ...currentState,
        dealerRate: action.payload,
      };
    }
    case ActionTypes.SET_SUBSIDY_CUSTOMER_RATE: {
      return {
        ...currentState,
        customerRate: action.payload,
      };
    }
    default:
      return currentState;
  }
}
