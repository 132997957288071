import * as React from "react";
import { Radio, IRadioProps, unityTheme } from "@ntpkunity/controls";
import { flexTheme } from "../../../shared";

const Radiobutton = ({
  row = true,
  options,
  onChange,
  value = "",
  defaultValue,
}) => {
  return (
    <Radio
      options={options}
      row={row}
      onChange={onChange}
      value={value}
      theme={flexTheme}
      fullWidth
      defaultValue={defaultValue}
    />
  );
};

export default Radiobutton;
