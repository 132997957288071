import React from "react";
import { unityTheme, Box } from "@ntpkunity/controls";
import { Table } from "../../controls/Table/Table";

interface IRentalDetail {
  rows: [];
}
const RentalDetail: React.FC<IRentalDetail> = ({ rows }: IRentalDetail) => {
  const columns = [] as any;
  columns.push("Term From");
  columns.push("Term To");
  columns.push("Rental Amount");
  columns.push("Rental Type");
  if (rows.length > 0) {
    return (
      <Box theme={unityTheme} mt={4} mb={4}>
        <Table columns={columns} rows={rows} />
      </Box>
    );
  }
};
export default RentalDetail;
