import { IReducerAmortizationCalculation } from "../calc-store/reducers/reducersAmortizationCalculation";
import { AmortizationMethods } from "../common/enums";

export const ValidateAmortizationPlan = (
  state: IReducerAmortizationCalculation
) => {
  if (
    state.amortizationCalcMethod == AmortizationMethods.StraightLine ||
    state.amortizationCalcMethod == AmortizationMethods.ActualNoOfDays ||
    state.amortizationCalcMethod == AmortizationMethods.StraightLineProRata
  ) {
    if (
      state.amountToAmortize == "" ||
      state.amountToAmortize == "0" ||
      state.amountToAmortize == undefined
    ) {
      return {
        visible: true,
        error: "Please provide valid Amount to Amortize.",
      };
    } else if (
      (state.amortizationCalcMethod == AmortizationMethods.StraightLine ||
        state.amortizationCalcMethod ==
          AmortizationMethods.StraightLineProRata) &&
      (state.contractTerms == "" ||
        state.contractTerms == "0" ||
        state.contractTerms == undefined)
    ) {
      return {
        visible: true,
        error: "Please provide valid Contract Duration in Months.",
      };
    } else if (state.startDate == "") {
      return {
        visible: true,
        error: "Please select Start Date.",
      };
    } else if (
      state.amortizationCalcMethod == AmortizationMethods.StraightLineProRata &&
      state.startDate >= state.endDate
    ) {
      return {
        visible: true,
        error: "End date must be greater than start date",
      };
    } else {
      return {
        visible: false,
        error: "",
      };
    }
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
