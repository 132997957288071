import * as React from "react";
import {
  unityTheme,
  Tooltip,
  DatePicker as NtpkDatePicker,
} from "@ntpkunity/controls";

type IDatePicker = {
  label: string;
  onChange?(e): void;
  value?: Date;
  testId?: string;
  inputFormat?: string;
  onKeyDown?(): void;
  sameDateSelection?: boolean;
};
export const DatePicker = ({
  label,
  onChange,
  value,
  testId,
  inputFormat = "DD-MMM-YYYY",
  onKeyDown = undefined,
  sameDateSelection = true,
}: IDatePicker) => {
  const onKeyDownEvent = (e) => {
    if (onKeyDown != undefined) {
      onKeyDown?.();
    } else {
      e.preventDefault();
    }
  };
  return (
    <Tooltip theme={unityTheme} title={label} placement={"right-end"}>
      <NtpkDatePicker
        value={value}
        data-testid={testId}
        theme={unityTheme}
        label={label}
        onChange={onChange}
        inputFormat={inputFormat}
        onKeyDown={onKeyDownEvent}
        sameDateSelection={sameDateSelection}
      />
    </Tooltip>
  );
};
