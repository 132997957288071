import ActionTypes from "../actionTypes";

export function setExtRentalCalcMethod(parm: any) {
  return {
    type: ActionTypes.SET_EXT_RENTAL_CALC_METHOD,
    payload: parm,
  };
}
export function setExtRentalMode(parm: any) {
  return {
    type: ActionTypes.SET_EXT_RENTAL_MODE,
    payload: parm,
  };
}
export function setExtRentalFreq(parm: any) {
  return {
    type: ActionTypes.SET_EXT_RENTAL_FREQ,
    payload: parm,
  };
}
export function setExtRvAmount(parm: any) {
  return {
    type: ActionTypes.SET_EXT_RV_AMOUNT,
    payload: parm,
  };
}
export function setExtContractTerms(parm: string = "") {
  return {
    type: ActionTypes.SET_EXT_CONTRACT_TERMS,
    payload: parm,
  };
}
export function setExtRentalComponent(parm: string = "") {
  return {
    type: ActionTypes.SET_EXT_RENTAL_COMPONENT,
    payload: parm,
  };
}
export function setExtApr(parm: string = "") {
  return {
    type: ActionTypes.SET_EXT_APR,
    payload: parm,
  };
}
export function setExtRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_EXT_RENTAL_STRUCTURES,
    payload: parm,
  };
}
export function setExtTaxRate(parm: string = "") {
  return {
    type: ActionTypes.SET_EXT_TAX_RATE,
    payload: parm,
  };
}
export function setExtIsTaxInclusive(parm: any) {
  return {
    type: ActionTypes.SET_EXT_IS_TAX_INCLUSIVE,
    payload: parm,
  };
}
export function setAddTax(parm: boolean) {
  return {
    type: ActionTypes.SET_EXT_ADD_TAX,
    payload: parm,
  };
}
export function SetRepaymentResponse(parm: any) {
  return {
    type: ActionTypes.SET_EXT_REPAYMENT_RESPONSE,
    payload: parm,
  };
}
