import { IReducerCommissionCalculation } from "../calc-store/reducers/reducersUpSellCommission";
export const ValidateCommissionCalculation = (
  state: IReducerCommissionCalculation
) => {
  if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.financeAmount == "" ||
    state.financeAmount == "0" ||
    state.financeAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending  amount.",
    };
  } else if (
    state.structureRentalDetail == undefined ||
    state.structureRentalDetail == null ||
    state.structureRentalDetail.length <= 0
  ) {
    return {
      visible: true,
      error: "Please provide the rental details.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
