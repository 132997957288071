import ActionTypes from "../actionTypes";
import IStructureFinancialParam from "../../interfaces/IStructureFinancialParam";
import {
  RentalCalculationMethods,
  RentalModes,
  RentalPlanCalculationMethods,
} from "../../common/enums";

let structureRentalDetail: IStructureFinancialParam[] = [];

export interface IReducerRentalCalculation {
  rentalCalcMethod: string;
  rentalMode: string;
  rentalFrequency: string;
  residualValue: string;
  APR: string;
  contractTerms: string;
  financedAmount: string;
  startDate: Date;
  rentalModeDisabled: boolean;
  rentalStructure: string;
  structureRentalDetail: IStructureFinancialParam[];
  repaymentPlanApiResponse: any;
  repaymentPlanDetail: [];
  rentalDetailResponse: [];
  repaymentPlanCalculation: string;
  extensionDays: number;
  incomeposting: boolean;
  incomePostingResponse: [];
}

const initialState: IReducerRentalCalculation = {
  rentalCalcMethod: RentalCalculationMethods.Annuity360,
  rentalMode: RentalModes.Arrear,
  rentalFrequency: "Monthly",
  residualValue: "",
  APR: "",
  contractTerms: "",
  financedAmount: "",
  startDate: new Date(),
  rentalModeDisabled: false,
  rentalStructure: "false",
  structureRentalDetail: [],
  repaymentPlanApiResponse: undefined,
  rentalDetailResponse: [],
  repaymentPlanDetail: [],
  repaymentPlanCalculation: RentalPlanCalculationMethods.Annuity360,
  extensionDays: undefined,
  incomeposting: false,
  incomePostingResponse: [],
};
export function reducerRentalPlan(
  currentState = initialState,
  action: any
): IReducerRentalCalculation {
  switch (action.type) {
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_CALC_METHOD:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_MODE:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_FREQ:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURE:
      return {
        ...currentState,
        rentalStructure: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURES: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE: {
      return {
        ...currentState,
        rentalDetailResponse: action.payload,
      };
    }
    case ActionTypes.RENTAL_PLAN_RESET_STRUCTURE_RENTALS: {
      return {
        ...currentState,
        structureRentalDetail: [],
      };
    }
    case ActionTypes.RENTAL_PLAN_START_DATE: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.SET_REPAYMENT_PLAN_DETAIL:
      return {
        ...currentState,
        repaymentPlanDetail: action.payload,
      };
    case ActionTypes.SET_REPAYMENT_PLAN_CALCULATION:
      return {
        ...currentState,
        repaymentPlanCalculation: action.payload,
      };
    case ActionTypes.SET_RP_API_RESPONSE:
      return {
        ...currentState,
        repaymentPlanApiResponse: action.payload,
      };
    case ActionTypes.SET_FINANCIAL_EXTENSION_DAYS:
      return {
        ...currentState,
        extensionDays: action.payload,
      };
    case ActionTypes.SET_INCOME_POSTING:
      return {
        ...currentState,
        incomeposting: action.payload,
      };
    case ActionTypes.SET_INCOME_POSTING_RESPONSE:
      return {
        ...currentState,
        incomePostingResponse: action.payload,
      };
    default:
      return currentState;
  }
}
