import { styled } from "@mui/material";
import {
  unityTheme,
  Button,
  Icon,
  Table as NtpkTable,
  Grid,
  Box,
  IBoxProps,
} from "@ntpkunity/controls";
import { useEffect, useState } from "react";
import generateExcel from "zipcelx";

const TableWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.table-wrap": {
    ".u-table-wrap": {
      ".u-table": {
        [theme.breakpoints.down("sm")]: {
          ".u-table-head": {
            th: {
              whiteSpace: "nowrap",
            },
            td: {
              whiteSpace: "nowrap",
            },
          },
          ".u-table-body": {
            th: {
              whiteSpace: "nowrap",
            },
            td: {
              whiteSpace: "nowrap",
            },
          },
        },
      },
    },
  },
}));
type ITable = {
  columns: any;
  rows: any;
  fileName?: string;
  showExport?: boolean;
  externalExport?: boolean;
  externalExportFunc?(): void;
  enableExport?: boolean;
};
export const Table = ({
  rows = [],
  columns = [],
  fileName = "Data-export",
  showExport = false,
  externalExport = false,
  externalExportFunc,
  enableExport = true,
}: ITable) => {
  const [exportBtnText, setExportBtnText] = useState("Export");
  useEffect(() => {
    if (!enableExport) {
      setExportBtnText("Wait...");
    } else {
      setExportBtnText("Export");
    }
  }, [enableExport]);
  function getHeader(column) {
    var header = {
      value: column,
      type: "string",
    };
    return header;
  }
  function getrowdata(row) {
    let array = [];
    row.map((r) => {
      let val = r;
      if (typeof r === "object" && r.props !== null) {
        val = r.props.value;
      }
      array.push({
        value: val,
        type: "string",
      });
    });
    return array;
  }
  const getExcel = () => {
    const config = {
      filename: fileName,
      sheet: {
        data: [],
      },
    };
    const dataSet = config.sheet.data;
    const headerRow = [];
    columns.forEach((headerGroup) => {
      if (headerGroup !== "") {
        headerRow.push(getHeader(headerGroup));
      }
    });
    dataSet.push(headerRow);
    if (rows.length > 0) {
      rows.map((rental: any) => {
        var dataArr = [];
        rental.content.map((rent) => {
          dataArr.push(rent);
        });
        dataSet.push(getrowdata(dataArr));
      });
    } else {
      let rowData = [];
      rowData.push({
        value: "No data",
        type: "string",
      });
      dataSet.push(rowData);
    }
    return generateExcel(config);
  };
  var getButton = () => {
    if (showExport == true) {
      return (
        <div>
          {/* <Button theme={unityTheme} text="Email" size="small" /> */}
          <Button
            theme={unityTheme}
            text={exportBtnText}
            onClick={externalExport ? externalExportFunc : getExcel}
            size="small"
            disabled={!enableExport}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <TableWrap theme={unityTheme} className="table-wrap">
      <Grid
        container
        xs={12}
        theme={unityTheme}
        alignItems="center"
        justifyContent="flex-end"
      >
        {/* <Button
            theme={unityTheme}
            secondary
            text="Export"
            onClick={getExcel}
            size="small"
          /> */}
        {getButton()}
      </Grid>
      <NtpkTable theme={unityTheme} rows={rows} columns={columns} />
    </TableWrap>
  );
};
