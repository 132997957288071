const enum ActionTypes {
  SET_ADD_TAX = "SET_ADD_TAX",
  SET_RENTAL_STRUCTURES = "SET_RENTAL_STRUCTURES",
  SET_RENTAL_STRUCTURE = "SET_RENTAL_STRUCTURE",
  SET_RENTAL_FREQ = "SET_RENTAL_FREQ",
  SET_RENTAL_MODE = "SET_RENTAL_MODE",
  SET_RENTAL_CALC_METHOD = "SET_RENTAL_CALC_METHOD",
  SET_CONTRACT_TERMS = "SET_CONTRACT_TERMS",
  SET_APR = "SET_APR",
  SET_FINANCED_AMOUNT = "SET_FINANCED_AMOUNT",
  SET_RESIDUAL_VALUE = "SET_RESIDUAL_VALUE",
  SET_RENTAL_DETAIL_RESPONSE = "SET_RENTAL_DETAIL_RESPONSE",
  RESET_STRUCTURE_RENTALS = "RESET_STRUCTURE_RENTALS",
  RESET_REPAYMENT_PLAN = "RESET_REPAYMENT_PLAN",
  SET_REPAYMENT_CALC_METHOD = "SET_REPAYMENT_CALC_METHOD",
  SET_START_DATE = "SET_START_DATE",
  SET_AMORTIZATION_RESPONSE = "SET_AMORTIZATION_RESPONSE",
  SET_SUBSIDY_RENTAL_SLABS = "SET_SUBSIDY_RENTAL_SLABS",
  SET_SUBSIDY_FINANCIER_RATE = "SET_SUBSIDY_FINANCIER_RATE",
  SET_TOTAL_SUBSIDY_AMOUNT = "SET_TOTAL_SUBSIDY_AMOUNT",
  SET_SUBSIDY_RENTAL_MODE = "SET_SUBSIDY_RENTAL_MODE",
  SET_SUBSIDY_RENTAL_FREQ = "SET_SUBSIDY_RENTAL_FREQ",
  SET_SUBSIDY_FINANCED_AMOUNT = "SET_SUBSIDY_FINANCED_AMOUNT",
  SET_SUBSIDY_RESIDUAL_VALUE = "SET_SUBSIDY_RESIDUAL_VALUE",
  SET_SUBSIDY_TYPE = "SET_SUBSIDY_TYPE",
  SET_SUBSIDY_MANUFACTURER_RATE = "SET_SUBSIDY_MANUFACTURER_RATE",
  SET_SUBSIDY_DEALER_RATE = "SET_SUBSIDY_DEALER_RATE",
  SET_SUBSIDY_CUSTOMER_RATE = "SET_SUBSIDY_CUSTOMER_RATE",
  SET_MANUFACTURER_SUBSIDY_AMOUNT = "SET_MANUFACTURER_SUBSIDY_AMOUNT",
  SET_DEALER_SUBSIDY_AMOUNT = "SET_DEALER_SUBSIDY_AMOUNT",
  SET_AMORTIZATION_METHOD = "SET_AMORTIZATION_METHOD",
  SET_SUBSIDY_CONTRACT_TERMS = "SET_SUBSIDY_CONTRACT_TERMS",
  SET_AMORTIZATION_CONTRACT_TERMS = "SET_AMORTIZATION_CONTRACT_TERMS",
  SET_AMORTIZATION_START_DATE = "SET_AMORTIZATION_START_DATE",
  SET_AMOUNT_TO_AMORTIZE = "SET_AMOUNT_TO_AMORTIZE",
  SET_AMORTIZATION_COMPONENT_NAME = "SET_AMORTIZATION_COMPONENT_NAME",
  SET_AMORTIZATION_TAX_RATA = "SET_AMORTIZATION_TAX_RATA",
  DISABLE_RENTAL_MODE = "DISABLE_RENTAL_MODE",
  SET_APR_RESPONSE = "SET_APR_RESPONSE",
  SET_APR_RENTAL_STRUCTURES = "SET_APR_RENTAL_STRUCTURES",
  SET_APR_RESIDUAL_VALUE = "SET_APR_RESIDUAL_VALUE",
  SET_APR_RENTAL_MODE = "SET_APR_RENTAL_MODE",
  SET_APR_RENTAL_FREQUENCY = "SET_APR_RENTAL_FREQUENCY",
  SET_APR_FINANCED_AMOUNT = "SET_APR_FINANCED_AMOUNT",
  SET_RP_RENTAL_CALC_METHOD = "SET_RP_RENTAL_CALC_METHOD",
  SET_RP_RENTAL_MODE = "SET_RP_RENTAL_MODE",
  SET_RP_RENTAL_FREQ = "SET_RP_RENTAL_FREQ",
  SET_RP_RENTAL_STRUCTURE = "SET_RP_RENTAL_STRUCTURE",
  SET_RP_RENTAL_STRUCTURES = "SET_RP_RENTAL_STRUCTURES",
  SET_RP_CONTRACT_TERMS = "SET_RP_CONTRACT_TERMS",
  SET_RP_APR = "SET_RP_APR",
  SET_RP_FINANCED_AMOUNT = "SET_RP_FINANCED_AMOUNT",
  SET_RP_RESIDUAL_VALUE = "SET_RP_RESIDUAL_VALUE",
  SET_RP_TAX = "SET_RP_TAX",
  SET_RP_INCLUSIVE_TAX = "SET_RP_INCLUSIVE_TAX",
  SET_RP_RENTAL_COMPONENT = "SET_RP_RENTAL_COMPONENT",
  SET_RP_RENTAL_DETAIL_RESPONSE = "SET_RP_RENTAL_DETAIL_RESPONSE",
  SET_RP_AMORTIZATION_RESPONSE = "SET_RP_AMORTIZATION_RESPONSE",
  RESET_RP_STRUCTURE_RENTALS = "RESET_RP_STRUCTURE_RENTALS",
  SET_RP_START_DATE = "SET_RP_START_DATE",
  DISABLE_RP_RENTAL_MODE = "DISABLE_RP_RENTAL_MODE",
  SET_AMORTIZATION_END_DATE = "SET_AMORTIZATION_END_DATE",
  SET_RP_IS_INCOME_POSTING = "SET_RP_IS_INCOME_POSTING",
  Set_Amortization_By_Annuity_Response = "Set_Amortization_By_Annuity_Response",
  Set_Extension_Days = "Set_Extension_Days",
  SET_RP_API_RESPONSE = "SET_RP_API_RESPONSE",
  SET_Commission_Rental_Mode = "SET_Commission_Rental_Mode",
  Set_Commission_Rental_Frequency = "Set_Commission_Rental_Frequency",
  Set_Commission_Start_Date = "Set_Commission_Start_Date",
  Set_Commission_Discount_Rate = "Set_Commission_Discount_Rate",
  Set_Commission_Finance_Amount = "Set_Commission_Finance_Amount",
  Set_Basic_Commission = "Set_Basic_Commission",
  Set_Minimum_Commission = "Set_Minimum_Commission",
  Set_Commission_Rental_Structures = "Set_Commission_Rental_Structures",
  Set_Commission_Response = "Set_Commission_Response",
  SET_ANNUITY_RENTAL_MODE = "SET_ANNUITY_RENTAL_MODE",
  SET_ANNUITY_RENTAL_FREQUENCY = "SET_ANNUITY_RENTAL_FREQUENCY",
  SET_ANNUITY_CONTRACT_TERMS = "SET_ANNUITY_CONTRACT_TERMS",
  SET_ANNUITY_FINANCED_AMOUNT = "SET_ANNUITY_FINANCED_AMOUNT",
  SET_ANNUITY_APR = "SET_ANNUITY_APR",
  SET_ANNUITY_RESIDUAL_VALUE = "SET_ANNUITY_RESIDUAL_VALUE",
  SET_ANNUITY_RENTAL_STRUCTURE = "SET_ANNUITY_RENTAL_STRUCTURE",
  SET_ANNUITY_RENTAL_STRICTURES = "SET_ANNUITY_RENTAL_STRICTURES",
  SET_ANNUITY_TAX_RATE = "SET_ANNUITY_TAX_RATE",
  SET_ANNUITY_IS_INCLUSIVE_TAX = "SET_ANNUITY_IS_INCLUSIVE_TAX",
  SET_ANNUITY_COMPONENT_NAME = "SET_ANNUITY_COMPONENT_NAME",
  SET_ANNUITY_ADD_TAX = "SET_ANNUITY_ADD_TAX",
  SET_ANNUITY_INCOME_EXPENSE = "SET_ANNUITY_INCOME_EXPENSE",
  SET_ANNUITY_INCOME_EXPENSES = "SET_ANNUITY_INCOME_EXPENSES",
  SET_ANNUITY_IS_ROUNDING_REQUIRED = "SET_ANNUITY_IS_ROUNDING_REQUIRED",
  SET_ANNUITY_ROUNDING_UP_TO = "SET_ANNUITY_ROUNDING_UP_TO",
  SET_RENTAL_PLAN_RENTAL_STRUCTURES = "SET_RENTAL_PLAN_RENTAL_STRUCTURES",
  SET_RENTAL_PLAN_RENTAL_STRUCTURE = "SET_RENTAL_PLAN_RENTAL_STRUCTURE",
  SET_RENTAL_PLAN_RENTAL_FREQ = "SET_RENTAL_PLAN_RENTAL_FREQ",
  SET_RENTAL_PLAN_RENTAL_MODE = "SET_RENTAL_PLAN_RENTAL_MODE",
  SET_RENTAL_PLAN_RENTAL_CALC_METHOD = "SET_RENTAL_PLAN_RENTAL_CALC_METHOD",
  SET_RENTAL_PLAN_CONTRACT_TERMS = "SET_RENTAL_PLAN_CONTRACT_TERMS",
  SET_RENTAL_PLAN_APR = "SET_RENTAL_PLAN_APR",
  SET_RENTAL_PLAN_FINANCED_AMOUNT = "SET_RENTAL_PLAN_FINANCED_AMOUNT",
  SET_RENTAL_PLAN_RESIDUAL_VALUE = "SET_RENTAL_PLAN_RESIDUAL_VALUE",
  SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE = "SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE",
  SET_REPAYMENT_PLAN_DETAIL = "SET_REPAYMENT_PLAN_DETAIL",
  SET_REPAYMENT_PLAN_CALCULATION = "SET_REPAYMENT_PLAN_CALCULATION",
  RENTAL_PLAN_RESET_STRUCTURE_RENTALS = "RENTAL_PLAN_RESET_STRUCTURE_RENTALS",
  RENTAL_PLAN_START_DATE = "RENTAL_PLAN_START_DATE",
  RENTAL_PLAN_DISABLE_RENTAL_MODE = "RENTAL_PLAN_DISABLE_RENTAL_MODE",
  SET_COMMISSION_RESIDUAL_VALUE = "SET_COMMISSION_RESIDUAL_VALUE",
  SET_EXCEL_DATA = "SET_EXCEL_DATA",
  SET_EXT_RENTAL_CALC_METHOD = "SET_EXT_RENTAL_CALC_METHOD",
  SET_EXT_RENTAL_MODE = "SET_EXT_RENTAL_MODE",
  SET_EXT_RENTAL_FREQ = "SET_EXT_RENTAL_FREQ",
  SET_EXT_RV_AMOUNT = "SET_EXT_RV_AMOUNT",
  SET_EXT_CONTRACT_TERMS = "SET_EXT_CONTRACT_TERMS",
  SET_EXT_RENTAL_COMPONENT = "SET_EXT_RENTAL_COMPONENT",
  SET_EXT_APR = "SET_EXT_APR",
  SET_EXT_TAX_RATE = "SET_EXT_TAX_RATE",
  SET_EXT_IS_TAX_INCLUSIVE = "SET_EXT_IS_TAX_INCLUSIVE",
  SET_EXT_ADD_TAX = "SET_EXT_ADD_TAX",
  SET_EXT_RENTAL_STRUCTURES = "SET_EXT_RENTAL_STRUCTURES",
  SET_EXT_REPAYMENT_RESPONSE = "SET_EXT_REPAYMENT_RESPONSE",
  SET_BASE_RATE_SOURCE_RESPONSE = "SET_BASE_RATE_SOURCE_RESPONSE",
  SET_BASE_RATE_FREQUENCY_RESPONSE = "SET_BASE_RATE_FREQUENCY_RESPONSE",
  SET_BASE_RATE_VALUE = "SET_BASE_RATE_VALUE",
  SET_BASE_RATE_CONFIGURATION_RESPONSE = "SET_BASE_RATE_CONFIGURATION_RESPONSE",
  SET_BASE_RATE_CONFIGURATION = "SET_BASE_RATE_CONFIGURATION",
  SET_PLANDIALOG_SELECTEDCOMPONENT = "SET_PLANDIALOG_SELECTEDCOMPONENT",
  SET_PLAN_MULTIPLEDATA = "SET_PLAN_MULTIPLEDATA",
  SET_ANNUITY_START_DATE = "SET_ANNUITY_START_DATE",
  SET_REVERSE_DOWN_PAYMENT = "SET_REVERSE_DOWN_PAYMENT",
  SET_REVERSE_FINANCED_AMOUNT = "SET_REVERSE_FINANCED_AMOUNT",
  SET_INCEPTION_FEE = "SET_INCEPTION_FEE",
  SET_MATURITY_FEE = "SET_MATURITY_FEE",
  SET_RENTAL_PLAN_RENTAL_CALC_METHOD_APR = "SET_RENTAL_PLAN_RENTAL_CALC_METHOD_APR",
  SET_RENTAL_PLAN_RENTAL_MODE_APR = "SET_RENTAL_PLAN_RENTAL_MODE_APR",
  SET_RENTAL_PLAN_RENTAL_FREQ_APR = "SET_RENTAL_PLAN_RENTAL_FREQ_APR",
  SET_RENTAL_PLAN_RENTAL_STRUCTURE_APR = "SET_RENTAL_PLAN_RENTAL_STRUCTURE_APR",
  SET_RENTAL_PLAN_CONTRACT_TERMS_APR = "SET_RENTAL_PLAN_CONTRACT_TERMS_APR",
  SET_RENTAL_PLAN_APR_APR = "SET_RENTAL_PLAN_APR_APR",
  SET_RENTAL_PLAN_FINANCED_AMOUNT_APR = "SET_RENTAL_PLAN_FINANCED_AMOUNT_APR",
  SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE_APR = " SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE_APR",
  RENTAL_PLAN_RESET_STRUCTURE_RENTALS_APR = "RENTAL_PLAN_RESET_STRUCTURE_RENTALS_APR",
  RENTAL_PLAN_START_DATE_APR = "RENTAL_PLAN_START_DATE_APR",
  SET_REPAYMENT_PLAN_DETAIL_APR = "SET_REPAYMENT_PLAN_DETAIL_APR",
  SET_REPAYMENT_PLAN_CALCULATION_APR = "SET_REPAYMENT_PLAN_CALCULATION_APR",
  SET_RP_API_RESPONSE_APR = "SET_RP_API_RESPONSE_APR",
  RENTAL_PLAN_DISABLE_RENTAL_MODE_APR = "RENTAL_PLAN_DISABLE_RENTAL_MODE_APR",
  SET_RENTAL_PLAN_RESIDUAL_VALUE_APR = "SET_RENTAL_PLAN_RESIDUAL_VALUE_APR",
  SET_RENTAL_PLAN_RENTAL_STRUCTURES_APR = "SET_RENTAL_PLAN_RENTAL_STRUCTURES_APR",
  SET_SEP_RENTALS = "SET_SEP_RENTALS",
  SET_DEFERRED_TAX = "SET_DEFERRED_TAX",
  SET_VAT_TREATMENT = "SET_VAT_TREATMENT",
  SET_VAT_AMOUNT = "SET_VAT_AMOUNT",
  SET_VAT_PERCENTAGE = "SET_VAT_PERCENTAGE",
  SET_FINANCIAL_EXTENSION_DAYS = "SET_FINANCIAL_EXTENSION_DAYS",
  SET_INCOME_POSTING = "SET_INCOME_POSTING",
  SET_INCOME_POSTING_RESPONSE = "SET_INCOME_POSTING_RESPONSE",
  SET_FINANCIAL_REQUEST_DATA = "SET_FINANCIAL_REQUEST_DATA",
  SET_FINANCIAL_RESPONSE_DATA = "SET_FINANCIAL_RESPONSE_DATA",
  SET_NOTIFICATION = "SET_NOTIFICATION",
  SET_RENTAL_CALCULATION_PROGRESS = "SET_RENTAL_CALCULATION_PROGRESS",
  SET_CALCULATED_NFA = "SET_CALCULATED_NFA",
  SET_CALCULATED_COMMISSION = "SET_CALCULATED_COMMISSION",
  SET_SLIDER_OPEN = "SET_SLIDER_OPEN",
  SET_RATES_VISIBILITY = "SET_RATES_VISIBILITY",
  SET_CALCULATION_IN_PROGRESS = "SET_CALCULATION_IN_PROGRESS",
  SET_RESET_VALUES = "SET_RESET_VALUES",
  SET_GROSS_PROFIT = "SET_GROSS_PROFIT",
}
export default ActionTypes;
