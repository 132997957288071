import {
  Box,
  Grid,
  Icon,
  Typography,
  unityTheme,
  IBoxProps,
} from "@ntpkunity/controls";
import { Dropdown, TextBox, Radiobutton } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { StructureRental } from "./StructureRental";
import { RentalCalculationMethods } from "../../../common/enums";
import {
  rentalCalculationFrequencies,
  rentalFrequencies,
  rentalModes,
  yesNoValues,
} from "../../../common/constants";
import {
  resetStructureRentals,
  setAPR,
  setContractTerms,
  setFinancedAmount,
  setRentalFrequency,
  setRentalMode,
  setRentalStructure,
  setRentalStructures,
  setResidualValue,
} from "../../../calc-store/actions/actionsRentalCalculation";
import RentalDetail from "./RentalDetail";
import { CalculateRentals } from "./CalculateRentals";
import { RentalCalcMethod } from "./RentalCalcMethod";
import IStructureRentalParm from "../../../interfaces/IStructureRentalParm";
import { styled } from "@mui/material/styles";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".sub-section": {
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
  },
}));

interface IFinancialCriteria {
  tabType: string;
}

const renderRentalCalcMethod = (tabType: string) => {
  if (tabType == "Rental") return <RentalCalcMethod />;
  else return <div></div>;
};

const FinancialCriteria: React.FC<IFinancialCriteria> = ({
  tabType,
}: IFinancialCriteria) => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRentalCalculation
  );
  const dispatch = useDispatch();

  const renderRentalsDetail = (tabType: string) => {
    if (tabType == "Rental")
      return <RentalDetail rows={storeState.rentalDetailResponse} />;
    else return <div></div>;
  };
  const addDefaultRental = () => {
    var structures = storeState.structureRentalDetail;
    var count = structures.length;
    var newRental: IStructureRentalParm = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: "0.0",
      TermFrom: 1,
      TermTo: 1,
    };
    structures.push(newRental);
    dispatch(setRentalStructures(structures));
  };
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <MainContent theme={unityTheme} className="main-content">
        <CalculationHeader text={"Rental Calculation"} />
        <Box theme={unityTheme} mb={4} data-testid="RentalControlsMethod">
          <RentalCalcMethod />
        </Box>
        <Box theme={unityTheme} mb={3}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalModes}
                label="Payment Mode"
                onChange={(e) => {
                  dispatch(setRentalMode(e.target.value));
                }}
                disabled={storeState.rentalModeDisabled}
                value={storeState.rentalMode}
                helperText="If payment to be received at the beginning of the period or end of the period."
                testid="cbxRentalCalcRentalMode"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalCalculationFrequencies}
                label="Payment Frequency"
                onChange={(e) => {
                  dispatch(setRentalFrequency(e.target.value));
                }}
                value={storeState.rentalFrequency}
                helperText="The frequency of the installment amount to be paid."
                testid="cbxRentalCalcRentalFrequency"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtTerms"
                id="txtTerms"
                label="Contract Duration in Months"
                value={storeState.contractTerms}
                type="text"
                helperText="Contract Duration in Months."
                masking
                numeric
                scale={0}
                thousandSeparator={false}
                onChange={(e) => {
                  dispatch(setContractTerms(e));
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtAPR"
                id="txtAPR"
                label="Interest Rate"
                value={storeState.APR}
                type="text"
                helperText="Interest Rate."
                onChange={(e) => {
                  dispatch(setAPR(e));
                }}
                endAdornment={"%"}
                numeric={true}
                masking={true}
                scale={4}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtFinancedAmount"
                id="txtFinancedAmount"
                label="Lending Amount"
                value={storeState.financedAmount}
                type="text"
                helperText="Final lending Amount."
                onChange={(e) => {
                  dispatch(setFinancedAmount(e));
                }}
                masking
                scale={2}
                currency
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtResidualValue"
                id="txtResidualValue"
                label="Residual Value"
                value={storeState.residualValue}
                type="text"
                helperText="Residual Value of the assest paid at the end of the contract."
                onChange={(e) => {
                  dispatch(setResidualValue(e));
                }}
                masking
                scale={2}
                currency
                optional
              />
            </Grid>
          </Grid>
        </Box>
        <Box theme={unityTheme} mb={4}>
          <Grid theme={unityTheme} container spacing={3}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="txtStructureRentals"
            >
              <Typography
                component="span"
                variant="subtitle2"
                theme={unityTheme}
              >
                Irregular Payments:
              </Typography>
              <Box theme={unityTheme} mt={2}>
                <Radiobutton
                  options={yesNoValues}
                  onChange={(e) => {
                    dispatch(resetStructureRentals());
                    if (e == "true") {
                      addDefaultRental();
                    }
                    dispatch(setRentalStructure(e));
                  }}
                  value={storeState.rentalStructure}
                  defaultValue={storeState.rentalStructure}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <StructureRental />
        <CalculateRentals />

        <Box theme={unityTheme}>
          <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
            <Grid theme={unityTheme} item xs={12}>
              {renderRentalsDetail(tabType)}
            </Grid>
          </Grid>
        </Box>
      </MainContent>
    </LayoutWithSideNavComponent>
  );
};

export default FinancialCriteria;
