import styled from "@emotion/styled";
import {
  Grid,
  IBoxProps,
  IGridProps,
  unityTheme,
  Box,
} from "@ntpkunity/controls";
import "./Divider.css";
export type IDivider = {
  caption?: string;
};
const Divider2 = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  "&.Divider": {
    backgroundColor: "white",
    marginTop: -40,
  },
}));
export const Divider = ({ caption }: IDivider) => {
  const getWithCaption = () => {
    if (caption != undefined || caption == null) {
      return (
        <div>
          <hr />
          <Divider2 className="Divider" theme={unityTheme}>
            <Grid theme={unityTheme} textAlign="center">
              <span>{caption}</span>
            </Grid>
          </Divider2>
        </div>
      );
    } else {
      return <hr />;
    }
  };
  return <div>{getWithCaption()}</div>;
};
