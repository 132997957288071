import { Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubsidyCustomerRate,
  setSubsidyDealerRate,
  setSubsidyFinancierRate,
  setSubsidyManufacturerRate,
  setSubsidyResidualValue,
} from "../../../calc-store/actions/actionsSubsidyCalculation";
import { RootState } from "../../../calc-store/reducers";
import { SubsidyTypes } from "../../../common/enums";
import { TextBox } from "../../controls";

interface ISubsidyControls {
  subsidyType: string;
}
export const SubsidyControls = ({ subsidyType }: ISubsidyControls) => {
  const storeState = useSelector(
    (state: RootState) => state.reducersSubsidyCalculation
  );
  const dispatch = useDispatch();

  const rvTextBox = () => {
    return (
      <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
        <TextBox
          testid={"txtResidualValue"}
          id="txtResidualValue"
          label="Residual Value"
          value={storeState.residualValue}
          type="text"
          helperText="Residual Value of the asset paid at the end of the contract."
          onChange={(e) => {
            dispatch(setSubsidyResidualValue(e));
          }}
          currency
          scale={2}
          masking
          optional
        />
      </Grid>
    );
  };

  if (subsidyType == SubsidyTypes.Rate) {
    return (
      <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
        {rvTextBox()}
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"txtFinancierRate"}
            id="txtFinancierRate"
            label="Financier Rate"
            value={storeState.financierRate}
            type="text"
            helperText="The Financier Rate for the Subsidy."
            onChange={(e) => {
              dispatch(setSubsidyFinancierRate(e));
            }}
            endAdornment="%"
            disabled={storeState.subsidyType != SubsidyTypes.Rate}
            numeric
            scale={4}
            masking
          />
        </Grid>
      </Grid>
    );
  } else if (subsidyType == SubsidyTypes.Split) {
    return (
      <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
        {rvTextBox()}
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"txtCustomerRate"}
            id="txtCustomerRate"
            label="Customer Rate"
            value={storeState.customerRate}
            type="text"
            helperText="The Customer Rate for the Subsidy."
            onChange={(e) => {
              dispatch(setSubsidyCustomerRate(e));
            }}
            endAdornment="%"
            disabled={storeState.subsidyType != SubsidyTypes.Split}
            numeric
            scale={4}
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"txtManufactureSubsidyRate"}
            id="txtManufactureSubsidyRate"
            label="Manufacturer Subsidy Rate"
            value={storeState.manufacturerRate}
            type="text"
            helperText="The Manufacturer Rate for the Subsidy."
            onChange={(e) => {
              dispatch(setSubsidyManufacturerRate(e));
            }}
            endAdornment="%"
            disabled={storeState.subsidyType != SubsidyTypes.Split}
            numeric
            scale={4}
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"txtDealerSubsidyRate"}
            id="txtDealerSubsidyRate"
            label="Dealer Subsidy Rate"
            value={storeState.dealerRate}
            type="text"
            helperText="The Dealer Rate for the Subsidy."
            onChange={(e) => {
              dispatch(setSubsidyDealerRate(e));
            }}
            endAdornment="%"
            disabled={storeState.subsidyType != SubsidyTypes.Split}
            numeric
            scale={4}
            masking
          />
        </Grid>
      </Grid>
    );
  } else {
    return <div></div>;
  }
};
