import React from "react";
import { Typography, unityTheme } from "@ntpkunity/controls";
import { Table } from "../../controls/Table/Table";
interface IAmortizationResponse {
  rows: [];
}
export const AmortizationResponse: React.FC<IAmortizationResponse> = ({
  rows,
}: IAmortizationResponse) => {
  const columns = [] as any;
  columns.push("Sr. #");
  columns.push("Rental #");
  columns.push("Posting Date");
  columns.push("No Of Days");
  columns.push("From Date");
  columns.push("To Date");
  columns.push("Amount");
  if (rows.length > 0) {
    return (
      <div>
        <Typography component="h6" variant="h6" theme={unityTheme}>
          Income Posting:
        </Typography>
        <Table columns={columns} rows={rows} />
      </div>
    );
  } else {
    return <div></div>;
  }
};
