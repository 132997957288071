import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";
export const SubSectionFieldsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.sub-fields-wrap": {
    position: "relative",
    ".sub-sections-fields": {
      maxWidth: "calc(100% - 74px)",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        ".MuiGrid-container": {
          ".MuiGrid-item": {
            "&:last-child": {
              maxWidth: "calc(100% - 74px)",
              // paddingRight: 74,
            },
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        ".MuiGrid-container": {
          ".MuiGrid-item": {
            "&:last-child": {
              paddingRight: 0,
            },
          },
        },
      },
    },
    ".sub-section-action": {
      position: "absolute",
      top: 23,
      right: 0,
      zIndex: 10,
      [theme.breakpoints.down("md")]: {
        top: "auto",
        bottom: 0,
      },
    },
  },
}));
