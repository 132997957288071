import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { IDrawerProps, IBoxProps, Box, Drawer } from "@ntpkunity/controls";

const drawerWidth = 375;
const drawerWidthResponsive = "100%";

type ICustomizedBox = IBoxProps & { open?: boolean };

export const CustomizedContainer = styled(Box)<ICustomizedBox>(
  ({ theme, open }) => ({
    width: "calc(100% - 78px)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("md")]: {
      width: drawerWidthResponsive,
      zIndex: theme.zIndex.drawer - 1,
      marginTop: 72,
    },
    position: "relative",
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        width: drawerWidthResponsive,
        zIndex: theme.zIndex.drawer - 1,
        marginTop: 72,
        "&:before": {
          display: "block",
          content: "' '",
          backgroundColor: "rgba(0,0,0,0.2)",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 100,
        },
      },
      ".nav-open-toggle": {
        display: "block",
        width: 40,
        height: 40,
        border: "1px solid" + theme.palette.grey[100],
        borderRadius: "50%",
        textAlign: "center",
        lineHeight: "33px",
        position: "absolute",
        zIndex: 20,
        backgroundColor: theme.palette.common.white,
        bottom: 35,
        left: -25,
        ".MuiButtonBase-root": {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    }),
    header: {
      borderBottom: "1px solid" + theme.palette.grey[100],
      padding: "22px 0",
      ".MuiGrid-container": {
        alignItems: "center",
      },
      ".tenant-title": {
        color: theme.palette.grey[600],
      },
      ".tenant-name": {
        fontWeight: theme.typography.fontWeightMedium,
        padding: "0 4px",
        position: "relative",
        color: theme.palette.grey[900],
      },
      ".header-nav-right": {
        ".header-link-item": {
          margin: "0 12px",
          display: "inline-block",
          position: "relative",
          textDecoration: "none",
          "&:last-child": {
            marginRight: 0,
          },
          "&.item-icon-only": {
            width: 24,
            height: 24,
          },
        },
      },
      ".status-icon": {
        backgroundColor: theme.palette.primary.main,
        display: "block",
        width: 8,
        height: 8,
        borderRadius: "50%",
        border: "2px solid" + theme.palette.common.white,
        position: "absolute",
        right: 0,
        bottom: 0,
      },
    },
    ".right-body-content": {
      padding: "0 40px",
      backgroundColor: theme.palette.common.white,
      borderRadius: "16px",
      marginLeft: 16,
      height: "calc(100vh - 32px)",
      overflowY: "overlay",
      overflowX: "hidden",
      scrollbarWidth: "thin",
      position: "relative",
      "@-moz-document url-prefix()": {
        overflowY: "auto",
      },
      "&::-webkit-scrollbar": {
        width: 9,
        height: 9,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 0px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "rgba(0, 0, 0, 0.16)",
        borderRadius: 4,
        border: "3px solid transparent",
        backgroundClip: "content-box",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.16)",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        borderRadius: theme.shape.borderRadius,
        padding: "0 24px",
        marginTop: 8,
        height: "calc(100vh - 96px)",
      },
    },
  })
);

export const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&.u-side-nav": {
    ".aside-content-wrap": {
      padding: "12px",
      [theme.breakpoints.down("md")]: {
        padding: "8px 8px 0px 8px",
      },
    },
    ".u-list-item": {
      ".u-list-item-text": {
        opacity: 1,
      },
    },
    ".logo-wrap": {
      ".logo-area": {
        ".menu-closed-logo": {
          display: "none",
        },
        ".menu-open-logo": {
          [theme.breakpoints.down("md")]: {
            maxHeight: 24,
          },
        },
      },
      ".menu-action": {
        ".menu-icon": {
          ".toggle-bar": {
            backgroundColor: "transparent",
            transition: "all 0.6s ease-in-out",
            "&:after": {
              top: 0,
              left: 6,
              boxShadow: "0 10px 0 0" + theme.palette.grey[300],
              transform: "translateY(-8px) rotate(45deg)",
              transition: "all 0.3s ease-in-out",
            },
            "&:before": {
              top: 0,
              left: 6,
              boxShadow: "0 -10px 0 0 " + theme.palette.grey[300],
              transform: "translateY(6px) rotate(-45deg)",
              transition: "all 0.3s ease-in-out",
            },
          },
        },
      },
    },
    ".user-icon": {
      display: "none",
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 16px)",
    maxHeight: "calc(100% - 16px)",
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

export const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "&.MuiDrawer-paper": {
    background:
      "linear-gradient(180deg, #790082 0%, #790082 0.01%, #3967E9 100%)",
  },
  overflowX: "hidden",
  width: `calc(78px)`,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(78px)`,
  // },
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 16px)",
  },
  "&.u-side-nav": {
    ".aside-content-wrap": {
      padding: "12px",
      "&.content-top": {
        justifyContent: "center",
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
        "&.action-content": {
          padding: 0,
        },
      },
    },
    ".u-list-item": {
      justifyContent: "center",
      ".u-list-item-icon": {
        marginRight: 0,
        "&.collapse-icon": {
          display: "none",
        },
      },
      ".u-list-item-text": {
        opacity: 0,
      },
      "&:focus": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
      "&:active": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
      "&.active": {
        backgroundColor: "#F2F2F2 !important",
        ".u-list-item-icon": {
          svg: {
            path: {
              stroke: theme.palette.primary.main + "!important",
            },
          },
        },
      },
    },
    ".nav-group-title": {
      textAlign: "center",
    },
    ".u-nested-list": {
      display: "none",
    },
    ".logo-wrap": {
      padding: 24,
      ".logo-area": {
        ".menu-open-logo": {
          display: "none",
        },
        ".menu-closed-logo": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            maxHeight: 24,
          },
        },
      },
      ".menu-action": {
        ".docs-btn": {
          display: "none",
        },
      },
    },
    ".divider-wrap": {
      display: "none",
    },
    ".u-nav-wrap": {
      display: "none",
    },
    ".nav-toggle": {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  ".side-nav-actions": {
    justifyContent: "center",
    ".toggle-nav-btn": {
      padding: 8,
      width: "auto",
      height: "auto",
      transform: "rotate(0deg)",
    },
    ".MuiFormGroup-root": {
      display: "none",
    },
    ".user-info": {
      display: "none",
    },
    ".user-icon": {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
});

export const CustomizedDrawer = styled(Drawer)<IDrawerProps>(
  ({ theme, open }): any => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    ".MuiDrawer-paper": {
      height: "calc(100vh - 32px)",
      top: 16,
      left: 16,
      borderRight: "none",
      borderRadius: "16px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        maxHeight: "calc(100% - 16px)",
        top: 8,
        left: 8,
        borderRadius: theme.shape.borderRadius,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 16px)",
    },
  })
);

export const LayoutWrap = styled(Box)<IBoxProps>(({ theme }): any => ({
  display: "flex",
  ".u-aside-wrap": {
    display: "flex",
    ".side-nav-area-wrap": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowX: "hidden",
      overflowY: "overlay",
      scrollbarWidth: "thin",
      "@-moz-document url-prefix()": {
        overflowY: "auto",
      },
      "&::-webkit-scrollbar": {
        width: 9,
        height: 9,
      },
      "&::-webkit-scrollbar-track": {
        padding: "0 0px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        transition: "all 0.3s",
        backgroundColor: "rgba(0, 0, 0, 0.16)",
        borderRadius: 4,
        border: "3px solid transparent",
        backgroundClip: "content-box",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.16)",
      },
    },
    ".aside-content-wrap": {
      "&.content-top": {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      },
      "&.action-content": {
        padding: "0px 12px 12px 12px",
        [theme.breakpoints.down("md")]: {
          padding: 0,
        },
      },
      ".MuiDivider-root": {
        margin: "12px 0",
      },
    },
    ".logo-wrap": {
      padding: "24px 32px",
      display: "flex",
      alignItems: "center",
      ".logo-area": {
        flexGrow: 1,
        ".toggle-btn": {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        [theme.breakpoints.down("md")]: {
          height: 24,
        },
      },
      ".menu-action": {
        display: "flex",
        gap: 16,
        flexShrink: 0,
        ".MuiIconButton-root": {
          padding: 0,
          ".MuiTouchRipple-root": {
            display: "none",
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        ".menu-toggle": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        ".menu-icon": {
          height: 24,
          width: 24,
          cursor: "pointer",
          [theme.breakpoints.down("md")]: {
            zIndex: 99,
            position: "relative",
          },
          ".toggle-bar": {
            position: "relative",
            top: "50%",
            transition: "all 0.3s ease-in-out",
            "&:after": {
              height: 2,
              width: 16,
              transition: "all 0.3s ease-in-out",
              boxShadow: "0 10px 0 0" + theme.palette.common.white,
              content: "''",
              position: "absolute",
              top: -6,
              left: 0,
            },
            "&:before": {
              height: 2,
              width: 16,
              transition: "all 0.3s ease-in-out",
              boxShadow: "0 -10px 0 0" + theme.palette.common.white,
              content: "''",
              position: "absolute",
              top: 6,
              left: 0,
            },
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: 24,
      },
    },
    ".nav-group-title": {
      fontSize: 10,
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[600],
      lineHeight: theme.typography.body1.lineHeight,
      display: "block",
    },
    ".u-nav-wrap": {
      ".u-list-item": {
        position: "relative",
        "&:hover": {
          backgroundColor: "transparent",
          ".u-list-item-text": {
            color: "#2D007D",
          },
          ".u-list-item-icon": {
            "&.collapse-icon": {
              svg: {
                path: {
                  stroke: "#2D007D",
                },
              },
            },
          },
        },
        "&:focus": {
          backgroundColor: "transparent",
          ".u-list-item-text": {
            color: "#2D007D",
          },
          ".u-list-item-icon": {
            "&.collapse-icon": {
              svg: {
                path: {
                  stroke: "#2D007D",
                },
              },
            },
          },
        },
        "&.selected": {
          backgroundColor: "transparent",
          ".u-list-item-text": {
            color: "#2D007D",
          },
          ".u-list-item-icon": {
            "&.collapse-icon": {
              svg: {
                path: {
                  stroke: "#2D007D",
                },
              },
            },
          },
        },
      },
      ".u-nested-list": {
        ".u-list-item": {
          "&:hover": {
            backgroundColor: "#F5F7FE",
            ".u-list-item-text": {
              color: theme.palette.grey[600],
            },
          },
          "&.selected": {
            backgroundColor: "rgba(45, 0, 125, 0.1)",
            ".u-list-item-text": {
              ".MuiTypography-root": {
                fontWeight: theme.customVariables.fontWeightSemiBold,
              },
            },
            "&:before": {
              opacity: 1,
            },
            "&:hover": {
              ".u-list-item-text": {
                color: "#2D007D",
              },
            },
          },
          "&:before": {
            display: "block",
            content: "' '",
            backgroundColor: "#2D007D",
            width: 2,
            height: "100%",
            position: "absolute",
            left: -13,
            top: 0,
            opacity: 0,
            transition: "all 0.3s ease-in-out 0s",
          },
        },
      },
    },
    ".nav-toggle": {
      padding: 12,
      display: "none",
      ".toggle-btn": {
        padding: 0,
        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
    },
  },
  ".side-nav-actions": {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    ".toggle-nav-btn": {
      padding: 0,
      width: 40,
      height: 40,
      borderRadius: theme.shape.borderRadius,
      transition: "all 0.3s ease-in-ou 0s",
      transform: "rotate(180deg)",
      ".MuiTouchRipple-root": {
        display: "none",
      },
    },
    ".MuiFormGroup-root": {
      label: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    ".user-info": {
      backgroundColor: "#F5F7FE",
      width: "100%",
      padding: "8px 16px 8px 8px",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      color: "#12131A",
      textDecoration: "none",
      ".user-initials": {
        width: 48,
        height: 48,
        backgroundColor: theme.palette.common.white,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 16,
        fontWeight: theme.typography.h2.fontWeight,
      },
      ".user-fullname": {
        fontWeight: theme.customVariables.fontWeightSemiBold,
      },
      "&.desktop-view": {
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    ".open-left-menu-action": {
      width: "100%",
      ".u-dropdown-menu": {
        ".MuiMenu-paper": {
          width: 304,
        },
      },
    },
  },
}));
