import ActionTypes from "../actionTypes";

export function setExcelData(parm: any) {
  return {
    type: ActionTypes.SET_EXCEL_DATA,
    payload: parm,
  };
}

export function setPlanDialogSelectedComponent(parm: any) {
  return {
    type: ActionTypes.SET_PLANDIALOG_SELECTEDCOMPONENT,
    payload: parm,
  };
}

export function setPlanMultipleData(parm: any) {
  return {
    type: ActionTypes.SET_PLAN_MULTIPLEDATA,
    payload: parm,
  };
}
