import ActionTypes from "../actionTypes";
import IStructureCommissionParm from "../../interfaces/IStructureCommissionParm";

let structureRentalDetail: IStructureCommissionParm[] = [
  {
    StartTerm: 1,
    EndTerm: 1,
    Amount: 0.0,
    RentalId: 1,
  },
];
export interface IReducerCommissionCalculation {
  financeAmount: string;
  rentalMode: string;
  rentalFrequency: string;
  structureRentalDetail: IStructureCommissionParm[];
  minimumCommission: string;
  percentageOfBasicCommission: string;
  discountRate: string;
  startDate: any;
  rvamount: any;
  commissionResponse: string;
}
const initialState: IReducerCommissionCalculation = {
  financeAmount: "",
  rentalMode: "",
  rentalFrequency: "",
  discountRate: "",
  commissionResponse: "",
  minimumCommission: "",
  rvamount: "",
  structureRentalDetail: structureRentalDetail,
  percentageOfBasicCommission: "",
  startDate: new Date(),
};
export function reducersUpSellCommission(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_Commission_Rental_Mode: {
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Rental_Frequency: {
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Start_Date: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Discount_Rate: {
      return {
        ...currentState,
        discountRate: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Finance_Amount: {
      return {
        ...currentState,
        financeAmount: action.payload,
      };
    }
    case ActionTypes.Set_Basic_Commission: {
      return {
        ...currentState,
        percentageOfBasicCommission: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Rental_Structures: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.Set_Minimum_Commission: {
      return {
        ...currentState,
        minimumCommission: action.payload,
      };
    }
    case ActionTypes.Set_Commission_Response: {
      return {
        ...currentState,
        commissionResponse: action.payload,
      };
    }
    case ActionTypes.SET_COMMISSION_RESIDUAL_VALUE: {
      return {
        ...currentState,
        rvamount: action.payload,
      };
    }
    default:
      return currentState;
  }
}
