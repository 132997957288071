import ActionTypes from "../actionTypes";

export function setBaseRateSourceResponse(parm: any) {
  return {
    type: ActionTypes.SET_BASE_RATE_SOURCE_RESPONSE,
    payload: parm,
  };
}
export function setBaseRateFrequencyResponse(parm: any) {
  return {
    type: ActionTypes.SET_BASE_RATE_FREQUENCY_RESPONSE,
    payload: parm,
  };
}

export function setBaseRateValue(parm: any) {
  return {
    type: ActionTypes.SET_BASE_RATE_VALUE,
    payload: parm,
  };
}
export function setBaseRateConfigurationResponse(parm: any) {
  return {
    type: ActionTypes.SET_BASE_RATE_CONFIGURATION_RESPONSE,
    payload: parm,
  };
}
export function setBaseRateConfiguration(parm: any) {
  return {
    type: ActionTypes.SET_BASE_RATE_CONFIGURATION,
    payload: parm,
  };
}
