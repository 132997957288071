import ActionTypes from "../actionTypes";
import IStructureFinancialParam from "../../interfaces/IStructureFinancialParam";
import {
  RentalCalculationMethods,
  RentalModes,
  RentalPlanCalculationMethods,
} from "../../common/enums";

let structureRentalDetail: IStructureFinancialParam[] = [];

export interface IReducerRentalCalculation {
  rentalCalcMethod: string;
  rentalMode: string;
  rentalFrequency: string;
  residualValue: string;
  APR: string;
  vatAmount: string;
  vatPercentage: string;
  contractTerms: string;
  deferredTax: boolean;
  financedAmount: string;
  vatTreatment: string;
  startDate: Date;
  rentalModeDisabled: boolean;
  rentalStructure: string;
  structureRentalDetail: IStructureFinancialParam[];
  repaymentPlanApiResponse: any;
  repaymentPlanDetail: [];
  rentalDetailResponse: [];
  repaymentPlanCalculation: string;
}

const initialState: IReducerRentalCalculation = {
  rentalCalcMethod: RentalCalculationMethods.Annuity,
  rentalMode: RentalModes.Advance,
  rentalFrequency: "Monthly",
  residualValue: "",
  APR: "",
  vatAmount: "",
  vatPercentage: "",
  vatTreatment: "",
  contractTerms: "",
  financedAmount: "",
  startDate: new Date(),
  rentalModeDisabled: false,
  rentalStructure: "false",
  deferredTax: false,
  structureRentalDetail: [],
  repaymentPlanApiResponse: undefined,
  rentalDetailResponse: [],
  repaymentPlanDetail: [],
  repaymentPlanCalculation: RentalPlanCalculationMethods.Annuity,
};
export function reducerRentalAPR(
  currentState = initialState,
  action: any
): IReducerRentalCalculation {
  switch (action.type) {
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_CALC_METHOD_APR:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_MODE_APR:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_FREQ_APR:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURE_APR:
      return {
        ...currentState,
        rentalStructure: action.payload,
      };
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURES_APR: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_CONTRACT_TERMS_APR: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_APR_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_FINANCED_AMOUNT_APR: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_RESIDUAL_VALUE_APR: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE_APR: {
      return {
        ...currentState,
        rentalDetailResponse: action.payload,
      };
    }
    case ActionTypes.RENTAL_PLAN_RESET_STRUCTURE_RENTALS_APR: {
      return {
        ...currentState,
        structureRentalDetail: [],
      };
    }
    case ActionTypes.RENTAL_PLAN_START_DATE_APR: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.SET_REPAYMENT_PLAN_DETAIL_APR:
      return {
        ...currentState,
        repaymentPlanDetail: action.payload,
      };
    case ActionTypes.SET_REPAYMENT_PLAN_CALCULATION_APR:
      return {
        ...currentState,
        repaymentPlanCalculation: action.payload,
      };
    case ActionTypes.SET_RP_API_RESPONSE_APR:
      return {
        ...currentState,
        repaymentPlanApiResponse: action.payload,
      };
    case ActionTypes.SET_DEFERRED_TAX:
      return {
        ...currentState,
        deferredTax: action.payload,
      };
    case ActionTypes.SET_VAT_TREATMENT:
      return {
        ...currentState,
        vatTreatment: action.payload,
      };
    case ActionTypes.SET_VAT_AMOUNT:
      return {
        ...currentState,
        vatAmount: action.payload,
      };
    case ActionTypes.SET_VAT_PERCENTAGE:
      return {
        ...currentState,
        vatPercentage: action.payload,
      };
    default:
      return currentState;
  }
}
