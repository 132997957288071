import React from "react";
import { Table } from "../../controls/Table/Table";

interface IRentalDetail {
  rows: [];
}
const RentalDetail: React.FC<IRentalDetail> = ({ rows }: IRentalDetail) => {
  const columns = [] as any;
  columns.push("Term From");
  columns.push("Term To");
  columns.push("Terms");
  columns.push("Change Rate");
  columns.push("Monthly Rental");
  if (rows.length > 0) {
    return (
      <Table
        columns={columns}
        rows={rows}
        showExport
        fileName={"Rental Detail"}
      />
    );
  } else {
    <div></div>;
  }
};
export default RentalDetail;
