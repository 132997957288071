import { Grid, Typography, unityTheme } from "@ntpkunity/controls";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setInception,
  setMaturity,
} from "../../../calc-store/actions/actionalAdditionalCharges";
import { RootState } from "../../../calc-store/reducers";
import TextBox from "../../controls/TextBox/TextBox";

const AdditionalCharges = () => {
  const storeState = useSelector(
    (State: RootState) => State.reducersAdditionalCharges
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setInception(0));
    dispatch(setMaturity(0));
  }, []);
  return (
    <div>
      <hr />
      <Typography component="span" variant="subtitle2" theme={unityTheme}>
        Additional Charges:
      </Typography>
      <Grid
        theme={unityTheme}
        container
        spacing={3}
        marginTop={1}
        marginBottom={2}
      >
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            label="Inception Fee"
            value={storeState.inception}
            type="text"
            onChange={(e) => {
              dispatch(setInception(e));
            }}
            scale={2}
            numeric
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            label="Maturity Fee"
            value={storeState.maturity}
            type="text"
            onChange={(e) => {
              dispatch(setMaturity(e));
            }}
            scale={2}
            numeric
            masking
          />
        </Grid>
      </Grid>
      <hr />
    </div>
  );
};

export default AdditionalCharges;
