import { Box, Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "../../controls/DateHelper/DatePicker";
import {
  disableRentalMode,
  setRentalCalculationMethod,
  setRentalDetailResponse,
  setRentalMode,
  setStartDate,
} from "../../../calc-store/actions/actionsRentalCalculation";
import { RootState } from "../../../calc-store/reducers";
import { rentalCalculationMethods } from "../../../common/constants";
import { RentalCalculationMethods } from "../../../common/enums";
import { Dropdown } from "../../controls";

export const RentalCalcMethod = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRentalCalculation
  );
  const dispatch = useDispatch();

  const onChangeRentalCalc = (e: any) => {
    dispatch(setRentalCalculationMethod(e.target.value));
    dispatch(setRentalDetailResponse([]));
    if (
      e.target.value == RentalCalculationMethods.Flat ||
      e.target.value == RentalCalculationMethods.RentalAmountFlatPLUS
    ) {
      //dispatch(disableRentalMode(true));
      //dispatch(setRentalMode(""));
    } else dispatch(disableRentalMode(false));
  };

  const onShowStartDate = (param) => {
    if (
      param == RentalCalculationMethods.Annuity360 ||
      param == RentalCalculationMethods.Annuity365
    ) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <DatePicker
            label="Start Date"
            testId="txtStartDate"
            value={storeState.startDate}
            onChange={(e: Date) => dispatch(setStartDate(e))}
          />
        </Grid>
      );
    }
  };
  return (
    <Grid theme={unityTheme} container item spacing={3}>
      <Grid theme={unityTheme} item xs={12} sm={8} md={8} lg={8}>
        <Dropdown
          items={rentalCalculationMethods}
          label="Rental Calculation"
          onChange={(e) => {
            onChangeRentalCalc(e);
          }}
          helperText=""
          value={storeState.rentalCalcMethod}
          testid="cbxRentalCalcMethod"
        />
      </Grid>
      {onShowStartDate(storeState.rentalCalcMethod)}
    </Grid>
  );
};
