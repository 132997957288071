import { Route, Routes } from "react-router-dom";
import AffordabilityChecks from "../tabs/affordabilityChecks/affordabilityChecks";
import { AmortizationPlan } from "../tabs/amortizationPlan/AmortizationPlan";
import AprCalculation from "../tabs/aprCalculation/AprCalculation";
import { ChangePassword } from "../tabs/changePassword/ChangePassword";
import EarlyTermination from "../tabs/earlyTermination/EarlyTermination";
import ExtendRV from "../tabs/extendRv/ExtendRV";
import { FinancialCalculation } from "../tabs/FinancialCalculation/FinancialCalculation";
import { FinancialCalculationAPR } from "../tabs/FinancialCalculationApr/FinancialCalculation";
import { ManageUsers } from "../tabs/manageUsers/ManageUsers";
import { RentalCalculation } from "../tabs/rentalCalculation/RentalCalculation";
import { RepaymentPlan } from "../tabs/repaymentPlan/RepaymentPlan";
import StepRental from "../tabs/stepRental/StepRental";
import SubsidyCalculation from "../tabs/subsidyCalculation/SubsidyCalculation";
import UpSellCommission from "../tabs/upSellCommission/UpSellCommission";
import { ComingSoon } from "./ComingSoon";
import { flexPermissions } from "@ntpkunity/controls-ums/flexPermissions";
import { Validate } from "@ntpkunity/controls-ums";
import { QuickQuote } from "../tabs/QuickQuote/quote.frame";
import { QuickQuoteConfig } from "../tabs/QuickQuote/quote.config.frame";

export const APP_Routes = {
  Amortization: "/flex/amortization",
  APR: "/flex/apr",
  FinancialCalculation: "/flex",
  FinancialCalculationAPR: "/flex/financialcalculationapr",
  RentalCalculation: "/flex/rentalcalculation",
  Repaymentplan: "/flex/repaymentplan",
  Subsidy_Subvention: "/flex/subsidy",
  Commission: "/flex/commission",
  EarlyTermination: "/flex/earlytermination",
  ExtendRV: "/flex/extendrv",
  AffordabilityChecks: "/flex/affordabilitychecks",
  ChangeOfDueDate: "/flex/changeofduedate",
  FinancialModification: "/flex/financialmodification",
  EarlyPayout: "/flex/earlypayout",
  StepRental: "/flex/steprental",
  commingsoon: "/flex/commingsoon",
  manageusers: "/flex/manage-users",
  changepassword: "/flex/change-password",
  quickquote: "/flex/quickquote",
  quoteConfiguration: "/flex/quote-config",
};

const CalculationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={APP_Routes.FinancialCalculation}
        element={<FinancialCalculation />}
      />
      <Route path={APP_Routes.Repaymentplan} element={<RepaymentPlan />} />
      <Route
        path={APP_Routes.RentalCalculation}
        element={<RentalCalculation />}
      />
      <Route path={APP_Routes.Amortization} element={<AmortizationPlan />} />
      <Route path={APP_Routes.APR} element={<AprCalculation />} />
      <Route
        path={APP_Routes.Subsidy_Subvention}
        element={<SubsidyCalculation />}
      />
      <Route path={APP_Routes.Commission} element={<UpSellCommission />} />
      <Route
        path={APP_Routes.EarlyTermination}
        element={<EarlyTermination />}
      />
      <Route path={APP_Routes.ExtendRV} element={<ExtendRV />} />
      <Route
        path={APP_Routes.AffordabilityChecks}
        element={<AffordabilityChecks />}
      />
      <Route
        path={APP_Routes.FinancialCalculationAPR}
        element={<FinancialCalculationAPR />}
      />
      <Route
        path={APP_Routes.manageusers}
        element={
          <Validate
            permission={flexPermissions.ROUTING.MANAGE_USERS}
            url={APP_Routes.FinancialCalculation}
          >
            <ManageUsers />
          </Validate>
        }
      />
      <Route path={APP_Routes.quickquote} element={<QuickQuote />} />
      <Route
        path={APP_Routes.quoteConfiguration}
        element={<QuickQuoteConfig />}
      />
      <Route path={APP_Routes.StepRental} element={<StepRental />} />
      <Route path={APP_Routes.changepassword} element={<ChangePassword />} />
      <Route path={APP_Routes.ChangeOfDueDate} element={<ComingSoon />} />
      <Route path={APP_Routes.FinancialModification} element={<ComingSoon />} />
      <Route path={APP_Routes.EarlyPayout} element={<EarlyTermination />} />
      <Route path={APP_Routes.commingsoon} element={<ComingSoon />} />
    </Routes>
  );
};

export default CalculationRoutes;
