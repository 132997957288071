import { Button, Grid, unityTheme, IBoxProps, Box } from "@ntpkunity/controls";
import { Dropdown, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import {
  setAPRFinancedAmount,
  setAPRRentalMode,
  setAPRRentalFrequency,
  setAPRResidualValue,
  setAPRResponse,
} from "../../../calc-store/actions/actionsAprCalculation";
import { rentalModes, rentalFrequencies } from "../../../common/constants";
import { AprSlabs } from "./AprSlabs";
import { useState } from "react";
import { CalculateAPR } from "../../../helpers/ServiceHelper";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { Outputvalue } from "../../controls/OutputValue/Outputvalue";
import { ValidateAprCalculation } from "../../../validations/ValidateAprCalculation";
import { styled } from "@mui/material/styles";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".text-muted": {
      color: "#7F7F7F",
    },
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

interface IAprCalculation {}
const AprCalculation: React.FC<IAprCalculation> = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersAprCalculation
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });

  const calculateApr = () => {
    var validate = ValidateAprCalculation(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      setCalculateEnabled(false);
      CalculateAPR(storeState)
        .then((res) => {
          dispatch(setAPRResponse(parseFloat(res.irr.toString()).toFixed(4)));
          setCalculateEnabled(true);
        })
        .catch((ex) => {
          setCalculateEnabled(true);
          setErrorDialog({ visible: true, error: ex.response.data });
        });
    }
  };
  return (
    <div>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <LayoutWithSideNavComponent theme={unityTheme}>
        <MainContent theme={unityTheme} className="main-content">
          <CalculationHeader text={"Calculate Rate"} />
          <Box theme={unityTheme} mb={3}>
            <Grid theme={unityTheme} container spacing={3}>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={rentalModes}
                  label="Payment Mode"
                  onChange={(e) => {
                    dispatch(setAPRRentalMode(e.target.value));
                  }}
                  value={storeState.rentalMode}
                  helperText="If payment to be received at the beginning of the period or end of the period."
                  testid="cbxAprRentalMode"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={rentalFrequencies}
                  label="Payment Frequency"
                  onChange={(e) => {
                    dispatch(setAPRRentalFrequency(e.target.value));
                  }}
                  value={storeState.rentalFrequency}
                  helperText="The frequency of the installment amount to be paid."
                  testid="cbxAprRentalFrequency"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <TextBox
                  testid={"txtFinancedAmount"}
                  id="txtFinancedAmount"
                  label="Lending Amount"
                  value={storeState.financedAmount}
                  type="text"
                  helperText="Final lending amount."
                  onChange={(e) => {
                    dispatch(setAPRFinancedAmount(e));
                  }}
                  currency
                  scale={2}
                  masking
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <TextBox
                  testid="txtResidualValue"
                  id="txtResidualValue"
                  label="Residual Value"
                  value={storeState.residualValue}
                  type="text"
                  helperText="Residual Value of the asset paid at the end of the contract."
                  onChange={(e) => {
                    dispatch(setAPRResidualValue(e));
                  }}
                  currency
                  scale={2}
                  masking
                  optional
                />
              </Grid>
            </Grid>
          </Box>

          <AprSlabs />

          <Grid theme={unityTheme} container spacing={3} mb={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Button
                className="btn-w-100"
                data-testid="btnCalculate"
                theme={unityTheme}
                disabled={!calculateEnabled}
                onClick={(e) => calculateApr()}
                primary
                text="Calculate"
              />
            </Grid>
          </Grid>
          <Grid theme={unityTheme} container spacing={3} mb={4} mt={1}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              data-testid="txtAPR"
            >
              <Outputvalue
                value={storeState.APR}
                suffix="%"
                helperText={storeState.APR == "" ? "" : "Interest Rate"}
              />
            </Grid>
          </Grid>
        </MainContent>
      </LayoutWithSideNavComponent>
    </div>
  );
};
export default AprCalculation;
