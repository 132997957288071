import ActionTypes from "../actionTypes";
import IStructureAprParm from "../../interfaces/IStructureAprParm";

let structureRentalDetail: IStructureAprParm[] = [
  {
    StartTerm: 1,
    EndTerm: 1,
    Amount: 0,
    RentalId: 1,
  },
];
export interface IReducerAprCalculation {
  financedAmount: string;
  rentalMode: string;
  rentalFrequency: string;
  residualValue: string;
  rentalStructure: string;
  structureRentalDetail: IStructureAprParm[];
  APR: string;
  TermTo: number;
  subsidyType: string;
}
const initialState: IReducerAprCalculation = {
  financedAmount: "",
  rentalMode: "",
  rentalFrequency: "",
  residualValue: "",
  rentalStructure: "",
  TermTo: 6,
  structureRentalDetail: structureRentalDetail,
  APR: "",
  subsidyType: "",
};
export function reducersAprCalculation(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_APR_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_APR_RENTAL_MODE: {
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    }
    case ActionTypes.SET_APR_RENTAL_FREQUENCY: {
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    }
    case ActionTypes.SET_APR_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_APR_RENTAL_STRUCTURES: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_APR_RESPONSE: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    default:
      return currentState;
  }
}
