import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  IBoxProps,
  Typography,
  unityTheme,
} from "@ntpkunity/controls";

const PageHeader = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-header": {
    paddingTop: 32,
    paddingBottom: 32,
    backgroundColor: theme.palette.common.white,
    position: "sticky",
    zIndex: 20,
    top: 0,
    marginBottom: 8,
    ".text-muted": {
      color: theme.palette.grey[600],
    },
    [theme.breakpoints.down("md")]: {
      position: "sticky",
      width: "100%",
      top: 0,
      marginBottom: 8,
    },
  },
}));

const CalculationHeader = ({ text, caption = undefined }) => {
  const _caption =
    "Complete solution for the Credit Industry's financial calculations needs";
  return (
    <PageHeader theme={unityTheme} className="page-header">
      <Grid container theme={unityTheme}>
        <Grid theme={unityTheme} item xs={12}>
          <Typography theme={unityTheme} component="h3" variant="h3">
            {text}
          </Typography>
          <Typography
            className="text-muted"
            theme={unityTheme}
            component="span"
            variant="body2"
          >
            {caption != undefined ? caption : _caption}
          </Typography>
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default CalculationHeader;
