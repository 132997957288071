import ActionTypes from "../actionTypes";
export function setRentalCalculationMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_CALC_METHOD,
    payload: parm,
  };
}

export function setRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_MODE,
    payload: parm,
  };
}

export function setRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_FREQ,
    payload: parm,
  };
}

export function setRentalStructure(parm: string = "false") {
  return {
    type: ActionTypes.SET_RENTAL_STRUCTURE,
    payload: parm,
  };
}

export function setRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_STRUCTURES,
    payload: parm,
  };
}

export function setContractTerms(parm: number = 12) {
  return {
    type: ActionTypes.SET_CONTRACT_TERMS,
    payload: parm,
  };
}

export function setAPR(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_APR,
    payload: parm,
  };
}
export function setFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setRentalDetailResponse(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_DETAIL_RESPONSE,
    payload: parm,
  };
}
export function setAmortizationResponse(parm: any) {
  return {
    type: ActionTypes.SET_AMORTIZATION_RESPONSE,
    payload: parm,
  };
}
export function resetStructureRentals() {
  return {
    type: ActionTypes.RESET_STRUCTURE_RENTALS,
  };
}
export function disableRentalMode(parm: boolean) {
  return {
    type: ActionTypes.DISABLE_RENTAL_MODE,
    payload: parm,
  };
}
export function setStartDate(parm: Date) {
  return {
    type: ActionTypes.SET_START_DATE,
    payload: parm,
  };
}
