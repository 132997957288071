import { Grid, unityTheme, Button, Box, Icon } from "@ntpkunity/controls";
import { Dropdown } from "../../controls";
import { DatePicker } from "../../controls/DateHelper/DatePicker";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableRentalMode,
  setRentalCalculationMethod,
  setRentalDetailResponse,
  setRepaymentPlanCalculation,
  setStartDate,
} from "../../../calc-store/actions/actionsRentalPlan";
import { RootState } from "../../../calc-store/reducers";
import {
  rentalAndRepaymentCalculationMethods,
  rentalPlanCalculation,
} from "../../../common/constants";
import { BaseRateConfiguration } from "../baseRateConfiguration/BaseRateConfiguration";
import {
  RentalCalculationMethods,
  RentalPlanCalculationMethods,
} from "../../../common/enums";
import {
  DeleteBaseRateConfiguration,
  GetBaseRateConfiguration,
} from "../../../helpers/ServiceHelper";
import {
  setBaseRateConfiguration,
  setBaseRateConfigurationResponse,
} from "../../../calc-store/actions/actionsBaseRateApi";

export const RentalCalcMethod = () => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const baseRateStore = useSelector(
    (state: RootState) => state.reducerBaseRate
  );
  const dispatch = useDispatch();
  const [baseRateConfigVisible, setBaseRateConfigVisible] = useState(false);

  type BaseRateItems = {
    id: string;
    frequency_title: string;
    source_title: string;
    base_rate: string;
  };

  const addConfiguration = () => {
    GetBaseRateConfiguration()
      .then((res) => {
        var items: BaseRateItems[] = [];
        res.map((r) => {
          items.push({
            id: r.id,
            frequency_title: r.frequency,
            source_title: r.Source,
            base_rate: r.base_rate,
          });
        });
        dispatch(setBaseRateConfigurationResponse(items));
        {
          BaseRateConfigurationData(items);
        }
      })
      .catch((ex) => {
        ex.response.data;
      });
  };

  const BaseRateConfigurationData = (param: any) => {
    var base_rate_configuration = [];
    var count = 1;
    var _rows = param;
    _rows.map((r) => {
      base_rate_configuration.push({
        content: [
          count,
          r.source_title,
          r.frequency_title,
          r.base_rate,
          <Button
            theme={unityTheme}
            onClick={() => {
              DeleteBaseRateConfiguration(r.id);
              addConfiguration();
            }}
            secondary
            variant="outlined"
            iconText={<Icon name="CloseBlack" />}
          />,
        ],
      });
      count = count + 1;
    });
    dispatch(setBaseRateConfiguration(base_rate_configuration));
  };
  const getBaseRateConfiguration = () => {
    if (process.env.STAGE != "prod") {
      return (
        <Box theme={unityTheme} mb={3}>
          <Grid theme={unityTheme}>
            <Grid
              container
              xs={12}
              theme={unityTheme}
              mt={-3}
              justifyContent="flex-end"
            >
              <Button
                text="Setup"
                onClick={() => {
                  setBaseRateConfigVisible(true),
                    BaseRateConfigurationData(
                      baseRateStore.setBaseRateConfigurationResponse
                    );
                }}
              />
            </Grid>
          </Grid>
          <BaseRateConfiguration
            visible={baseRateConfigVisible}
            onClose={() => setBaseRateConfigVisible(false)}
          />
        </Box>
      );
    } else {
      return <div></div>;
    }
  };
  const onChangeRentalCalc = (e: any) => {
    dispatch(setRentalCalculationMethod(e.target.value));
    dispatch(setRentalDetailResponse([]));
    if (
      e.target.value == RentalCalculationMethods.Flat ||
      e.target.value == RentalCalculationMethods.RentalAmountFlatPLUS
    ) {
      //dispatch(disableRentalMode(true));
      //dispatch(setRentalMode(""));
    } else dispatch(disableRentalMode(false));
  };
  return (
    <Box theme={unityTheme}>
      {/* <Box theme={unityTheme}>{getBaseRateConfiguration()}</Box> */}
      <Box theme={unityTheme}>
        <Grid theme={unityTheme}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalAndRepaymentCalculationMethods}
                label="Rental Calculation"
                onChange={(e) => {
                  onChangeRentalCalc(e);
                  if (e.target.value == RentalCalculationMethods.Annuity360) {
                    dispatch(
                      setRepaymentPlanCalculation(
                        RentalPlanCalculationMethods.Annuity360
                      )
                    );
                  } else if (
                    e.target.value == RentalCalculationMethods.Annuity365
                  ) {
                    dispatch(
                      setRepaymentPlanCalculation(
                        RentalPlanCalculationMethods.Annuity365
                      )
                    );
                  } else {
                    dispatch(
                      setRepaymentPlanCalculation(
                        RentalPlanCalculationMethods.Annuity
                      )
                    );
                  }
                }}
                helperText=""
                value={storeState.rentalCalcMethod}
                testid="cbxRentalCalculationMethod"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalPlanCalculation}
                label="Repayment Plan Calculation"
                disabled
                onChange={(e) => {
                  dispatch(setRepaymentPlanCalculation(e.target.value));
                }}
                helperText=""
                value={storeState.repaymentPlanCalculation}
                testid="cbxRepaymentPlanCalculation"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <DatePicker
                label="Start Date"
                testId="txtStartDate"
                value={storeState.startDate}
                onChange={(e: Date) => dispatch(setStartDate(e))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
