import { Box, Button, Grid, unityTheme } from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIncomePostingResponse,
  setRentalDetailResponse,
  setRepaymentPlanDetail,
} from "../../../calc-store/actions/actionsRentalPlan";
import { setRepaymentApiResponse } from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { RootState } from "../../../calc-store/reducers";
import {
  RentalCalculationMethods,
  RentalPlanCalculationMethods,
} from "../../../common/enums";
import {
  CalculateRentalAmountAnnuity,
  CalculateRentalAmountEP,
  CalculateRentalAmountFlat,
  RentalAmountFlatPLUS,
  CreateRentalPlanRentalAmortization,
  CalculateRentalAmountAnnuity365,
  CreateRentalAmortization365,
  CreateRentalAmortization360,
  CalculateRentalAmountAnnuity360,
  AmortizeByAnnuity,
} from "../../../helpers/ServiceHelper";
import IAmortizeByAnnuity from "../../../interfaces/IAmortizeByAnnuity";
import { ValidateRentalCalculation } from "../../../validations/ValidateRentalCalculation";
import DateHelper from "../../controls/DateHelper/DateHelper";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";

export const CalculateRentals = () => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const CalculateIncomePosting = (res) => {
    var param: IAmortizeByAnnuity = {
      contractStartDate: new Date(storeState.startDate),
      rentalMode: storeState.rentalMode,
      rentalDetails: [],
    };
    res.repaymentPlan.map((r) => {
      param.rentalDetails.push({
        rentalNo: r.rentalNumber,
        rentalDueDate: r.rentalDueDate,
        amountToBeAmortized: r.rentalInterest,
      });
    });
    AmortizeByAnnuity(param).then((annuityResponse) => {
      let response = [];
      annuityResponse.map((r) => {
        response.push({
          content: [
            r.serialNumber,
            r.rentalNumber,
            DateHelper(r.postingDate),
            r.noOfDays,
            DateHelper(r.fromDate),
            DateHelper(r.toDate),
            <FormattedValue value={r.amortizedAmount.toString()} />,
          ],
        });
      });
      dispatch(setIncomePostingResponse([]));
      dispatch(setIncomePostingResponse(response));
    });
  };
  const calculateRepaymentPlan = (rentalDetail) => {
    if (
      storeState.repaymentPlanCalculation ==
      RentalPlanCalculationMethods.Annuity
    ) {
      CreateRentalPlanRentalAmortization(storeState, rentalDetail)
        .then((res) => {
          let response = [];
          dispatch(setRepaymentApiResponse(res));
          res.repaymentPlan.map((r) => {
            r.rentalDueDate = r.rentalDueDate.split("T")[0];
            response.push({
              content: [
                r.rentalNumber,
                DateHelper(r.rentalDueDate),
                <FormattedValue value={r.openingPrincipal.toString()} />,
                <FormattedValue value={r.rentalAmount.toString()} />,
                <FormattedValue value={r.rentalPrincipal.toString()} />,
                <FormattedValue value={r.rentalInterest.toString()} />,
                <FormattedValue value={r.periodicInterest.toString()} />,
                <FormattedValue value={r.closingPrincipal.toString()} />,
              ],
            });
          });
          dispatch(setRepaymentPlanDetail(response));
          if (storeState.incomeposting) {
            CalculateIncomePosting(res);
          }
        })
        .catch((ex) => {
          setErrorDialog({ visible: true, error: ex.response.data });
        });
      return;
    } else if (
      storeState.repaymentPlanCalculation ==
      RentalPlanCalculationMethods.Annuity365
    ) {
      CreateRentalAmortization365(storeState, rentalDetail)
        .then((res) => {
          let response = [];
          dispatch(setRepaymentApiResponse(res));
          res.repaymentPlan.map((r) => {
            r.rentalDueDate = r.rentalDueDate.split("T")[0];
            response.push({
              content: [
                r.rentalNumber,
                DateHelper(r.rentalDueDate),
                <FormattedValue value={r.openingPrincipal.toString()} />,
                <FormattedValue value={r.rentalAmount.toString()} />,
                <FormattedValue value={r.rentalPrincipal.toString()} />,
                <FormattedValue value={r.rentalInterest.toString()} />,
                <FormattedValue value={r.periodicInterest.toString()} />,
                <FormattedValue value={r.closingPrincipal.toString()} />,
              ],
            });
          });
          dispatch(setRepaymentPlanDetail(response));
          if (storeState.incomeposting) {
            CalculateIncomePosting(res);
          }
        })
        .catch((ex) => {
          setErrorDialog({ visible: true, error: ex.response.data });
        });
      return;
    } else if (
      storeState.repaymentPlanCalculation ==
      RentalPlanCalculationMethods.Annuity360
    ) {
      CreateRentalAmortization360(storeState, rentalDetail)
        .then((res) => {
          let response = [];
          dispatch(setRepaymentApiResponse(res));
          res.repaymentPlan.map((r) => {
            r.rentalDueDate = r.rentalDueDate.split("T")[0];
            response.push({
              content: [
                r.rentalNumber,
                DateHelper(r.rentalDueDate),
                <FormattedValue value={r.openingPrincipal.toString()} />,
                <FormattedValue value={r.rentalAmount.toString()} />,
                <FormattedValue value={r.rentalPrincipal.toString()} />,
                <FormattedValue value={r.rentalInterest.toString()} />,
                <FormattedValue value={r.periodicInterest.toString()} />,
                <FormattedValue value={r.closingPrincipal.toString()} />,
              ],
            });
          });
          dispatch(setRepaymentPlanDetail(response));
          if (storeState.incomeposting) {
            CalculateIncomePosting(res);
          }
        })
        .catch((ex) => {
          setErrorDialog({ visible: true, error: ex.response.data });
        });
      return;
    } else {
      return;
    }
  };
  const calculate = () => {
    var validate = ValidateRentalCalculation(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      dispatch(setRentalDetailResponse([]));
      dispatch(setRepaymentPlanDetail([]));
      switch (storeState.rentalCalcMethod) {
        case RentalCalculationMethods.Flat: {
          setCalculateEnabled(false);
          CalculateRentalAmountFlat(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.Annuity: {
          setCalculateEnabled(false);
          CalculateRentalAmountAnnuity(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });

          return;
        }
        case RentalCalculationMethods.EqualPrincipal: {
          setCalculateEnabled(false);
          CalculateRentalAmountEP(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.RentalAmountFlatPLUS: {
          setCalculateEnabled(false);
          RentalAmountFlatPLUS(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.Annuity365: {
          setCalculateEnabled(false);
          CalculateRentalAmountAnnuity365(storeState)
            .then((res: any) => {
              let rentalResponse = [];
              res.rentalSummary.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res.repaymentPlan);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.Annuity360: {
          setCalculateEnabled(false);
          CalculateRentalAmountAnnuity360(storeState)
            .then((res: any) => {
              let rentalResponse = [];
              res.rentalSummary.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              calculateRepaymentPlan(res.repaymentPlan);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
      }
    }
  };
  return (
    <Box theme={unityTheme}>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <Grid theme={unityTheme} container spacing={4} mb={4}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="btnCalculate"
            theme={unityTheme}
            disabled={!calculateEnabled}
            onClick={(e) => calculate()}
            primary
            text="Calculate"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
