import NumberFormat from "react-number-format";

interface IFormattedValue {
  scale?: number;
  prefix?: string;
  suffix?: string;
  value: string;
}
export const FormattedValue = ({
  scale = 2,
  suffix = "",
  prefix = "",
  value,
}: IFormattedValue) => {
  return (
    <NumberFormat
      thousandSeparator={true}
      decimalScale={scale}
      prefix={prefix}
      suffix={suffix}
      value={value}
      displayType={"text"}
      fixedDecimalScale
    />
  );
};
