import ActionTypes from "../actionTypes";

export interface IReducerBaseRate {
  baseRateSourceResponse: [];
  baseRateFrequencyResponse: [];
  setBaseRateValue: "";
  setBaseRateConfigurationResponse: [];
  BaseRateConfiguration: [];
}
const initialState: IReducerBaseRate = {
  baseRateSourceResponse: [],
  baseRateFrequencyResponse: [],
  setBaseRateValue: "",
  setBaseRateConfigurationResponse: [],
  BaseRateConfiguration: [],
};
export function reducerBaseRate(currentState = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.SET_BASE_RATE_SOURCE_RESPONSE: {
      return {
        ...currentState,
        baseRateSourceResponse: action.payload,
      };
    }
    case ActionTypes.SET_BASE_RATE_FREQUENCY_RESPONSE: {
      return {
        ...currentState,
        baseRateFrequencyResponse: action.payload,
      };
    }
    case ActionTypes.SET_BASE_RATE_VALUE: {
      return {
        ...currentState,
        setBaseRateValue: action.payload,
      };
    }
    case ActionTypes.SET_BASE_RATE_CONFIGURATION_RESPONSE: {
      return {
        ...currentState,
        setBaseRateConfigurationResponse: action.payload,
      };
    }
    case ActionTypes.SET_BASE_RATE_CONFIGURATION: {
      return {
        ...currentState,
        BaseRateConfiguration: action.payload,
      };
    }
    default:
      return currentState;
  }
}
