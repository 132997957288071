import { createTheme } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";

export const CustomColors = {
  primary: "#790082",
  primaryB: "#3967E9",
  gradient: "linear-gradient(90deg, #790082 0%, #790082 0.01%, #3967E9 100%)",
};

export const lightTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: "#0033FD" },
    secondary: { main: "#DDDDDD" },
  },
});

export const flexTheme = createTheme({
  palette: {
    ...unityTheme.palette,
    primary: { main: CustomColors.primary },
    secondary: { main: "#790082" },
  },
});
