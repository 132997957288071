import { Box, Button, Grid, Icon, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setAPRRentalStructures } from "../../../calc-store/actions/actionsAprCalculation";
import { RootState } from "../../../calc-store/reducers";
import IStructureAprParm from "../../../interfaces/IStructureAprParm";
import { TextBox } from "../../controls";
import { SubSectionFieldsWrap } from "./../../../shared/theme/structureRentalStyle";

const GetAprSlabs = (
  aprStructures: IStructureAprParm[] = [],
  deleteFunction,
  valuesChange
) => {
  return aprStructures.map((structure) => (
    <SubSectionFieldsWrap
      key={structure.RentalId}
      theme={unityTheme}
      className="sub-fields-wrap"
    >
      <Box theme={unityTheme} className="sub-sections-fields">
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtStartTerm"}
              id="txtStartTerm"
              label="Start Term"
              type="number"
              value={structure.StartTerm}
              onChange={(e) => valuesChange("StartTerm", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtEndTerm"}
              id="txtEndTerm"
              label="End Term"
              type="number"
              value={structure.EndTerm}
              onChange={(e) => valuesChange("EndTerm", e, structure.RentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtAmount"}
              id="txtAmount"
              label="Amount"
              type="text"
              value={structure.Amount}
              masking
              currency
              onChange={(e) => valuesChange("Amount", e, structure.RentalId)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box theme={unityTheme} className="sub-section-action">
        <Button
          data-testid="txtBtnDelete"
          theme={unityTheme}
          key={"btnDelete" + structure.RentalId}
          onClick={() => deleteFunction(structure.RentalId)}
          secondary
          iconText={<Icon name="CloseBlack" />}
        />
      </Box>
    </SubSectionFieldsWrap>
  ));
};
export const AprSlabs = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersAprCalculation
  );
  const dispatch = useDispatch();
  const addRental = () => {
    var structures = storeState.structureRentalDetail;
    var count = structures.length;
    var newRental = {
      RentalId: parseFloat(count) + 1,
      StartTerm: count > 0 ? parseFloat(structures[count - 1].EndTerm) + 1 : 1,
      EndTerm: storeState.TermTo,
      type: "",
      Amount: 0.0,
    };
    structures.push(newRental);
    dispatch(setAPRRentalStructures(structures));
  };
  const deleteRental = (rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToDel = structures.findIndex((x) => x.RentalId == rentalId);
    structures.splice(indexToDel, indexToDel > -1 ? 1 : 0);
    dispatch(setAPRRentalStructures(structures));
  };
  const valuesChange = (changeType: string, newValue: any, rentalId: any) => {
    var structures = storeState.structureRentalDetail;
    var indexToEdit = structures.findIndex((x) => x.RentalId == rentalId);
    if (indexToEdit > -1) {
      var value = structures[indexToEdit];
      if (changeType == "StartTerm") value.StartTerm = newValue;
      else if (changeType == "EndTerm") value.EndTerm = newValue;
      else if (changeType == "Amount") value.Amount = newValue;
    }
    dispatch(setAPRRentalStructures(structures));
  };
  return (
    <Box theme={unityTheme} className="sub-section" mt={4} mb={4} pt={4} pb={4}>
      {GetAprSlabs(
        storeState.structureRentalDetail,
        deleteRental,
        valuesChange
      )}
      <Grid theme={unityTheme} container mt={4}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="txtAddRentalSlab"
            theme={unityTheme}
            secondary
            text="Add Rental Slab"
            variant="outlined"
            onClick={() => addRental()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
