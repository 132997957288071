import ActionTypes from "../actionTypes";
import IStructureRentalParm from "../../interfaces/IStructureRentalParm";

let structureRentalDetail: IStructureRentalParm[] = [];

export interface IReducerRentalCalculation {
  rentalCalcMethod: string;
  rentalMode: string;
  rentalFrequency: string;
  rentalStructure: string;
  structureRentalDetail: IStructureRentalParm[];
  residualValue: string;
  APR: string;
  contractTerms: string;
  financedAmount: string;
  rentalDetailResponse: [];
  startDate: Date;
  rentalModeDisabled: boolean;
}

const initialState: IReducerRentalCalculation = {
  rentalCalcMethod: "",
  rentalMode: "",
  rentalFrequency: "",
  rentalStructure: "false",
  structureRentalDetail: [],
  residualValue: "",
  APR: "",
  contractTerms: "",
  financedAmount: "",
  rentalDetailResponse: [],
  startDate: new Date(),
  rentalModeDisabled: false,
};
export function reducerRentalCalculation(
  currentState = initialState,
  action: any
): IReducerRentalCalculation {
  switch (action.type) {
    case ActionTypes.SET_RENTAL_CALC_METHOD:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_RENTAL_MODE:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_RENTAL_FREQ:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_RENTAL_STRUCTURE:
      return {
        ...currentState,
        rentalStructure: action.payload,
      };
    case ActionTypes.SET_RENTAL_STRUCTURES: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_DETAIL_RESPONSE: {
      return {
        ...currentState,
        rentalDetailResponse: action.payload,
      };
    }
    case ActionTypes.RESET_STRUCTURE_RENTALS: {
      return {
        ...currentState,
        structureRentalDetail: [],
      };
    }
    case ActionTypes.SET_START_DATE: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.DISABLE_RENTAL_MODE:
      return {
        ...currentState,
        rentalModeDisabled: action.payload,
      };
    default:
      return currentState;
  }
}
