import { Button, Grid, unityTheme, Box, IBoxProps } from "@ntpkunity/controls";
import { DatePicker } from "../../controls/DateHelper/DatePicker";
import { Dropdown, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import {
  setCommissionRentalMode,
  setCommissionRentalFrequency,
  setCommissionStartDate,
  setCommissionDiscountRate,
  setCommissionFinanceAmount,
  setBasicCommission,
  setMinimumCommission,
  setCommissionResponse,
  setCommissionResidualValue,
} from "../../../calc-store/actions/actionUpSellCommission";
import { rentalModes, rentalFrequencies } from "../../../common/constants";
import { useState } from "react";
import { CommissionSlabs } from "./CommissionSlabs";
import { CalculateSpellCommission } from "../../../helpers/ServiceHelper";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { Outputvalue } from "../../controls/OutputValue/Outputvalue";
import { ValidateCommissionCalculation } from "../../../validations/ValidateCommissionCalculation";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

interface IUpSellCommission {}
const UpSellCommission: React.FC<IUpSellCommission> = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersUpSellCommission
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });

  const calculateApr = () => {
    var validate = ValidateCommissionCalculation(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      setCalculateEnabled(false);
      CalculateSpellCommission(storeState)
        .then((res) => {
          dispatch(
            setCommissionResponse(
              parseFloat(res.upSellCommission.toString()).toFixed(4)
            )
          );
          setCalculateEnabled(true);
        })
        .catch((ex) => {
          setCalculateEnabled(true);
          setErrorDialog({ visible: true, error: ex.response.data });
        });
    }
  };
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <Box theme={unityTheme} className="main-content">
        <MessageDialog
          visible={errorDialog.visible}
          message={errorDialog.error}
          onClose={() => {
            setErrorDialog({
              visible: false,
              error: errorDialog.error,
            });
          }}
        />
        <CalculationHeader text={"Dealer / Broker Commission"} />
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalModes}
              label="Payment Mode"
              onChange={(e) => {
                dispatch(setCommissionRentalMode(e.target.value));
              }}
              value={storeState.rentalMode}
              helperText="If payment to be received at the beginning of the period or end of the period."
              testid="cbxCommissionRentalMode"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalFrequencies}
              label="Payment Frequency"
              onChange={(e) => {
                dispatch(setCommissionRentalFrequency(e.target.value));
              }}
              value={storeState.rentalFrequency}
              helperText="The frequency of installment  amount to be paid."
              testid="cbxCommissionRentalFrequency"
            />
          </Grid>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="txtstartdate"
          >
            <DatePicker
              label="Start Date"
              testId="txtStartDate"
              value={storeState.startDate}
              onChange={(e: Date) => dispatch(setCommissionStartDate(e))}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"financedamount"}
              id="financedamount"
              label="Lending Amount"
              value={storeState.financeAmount}
              type="text"
              helperText="Final lending amount."
              onChange={(e) => {
                dispatch(setCommissionFinanceAmount(e));
              }}
              scale={2}
              currency
              masking
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"discountRate"}
              id="discountRate"
              label="Discount Rate"
              value={storeState.discountRate}
              type="text"
              helperText="Discount Rate."
              onChange={(e) => {
                dispatch(setCommissionDiscountRate(e));
              }}
              masking
              scale={2}
              currency
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"minimumCommission"}
              id="minimumCommission"
              label="Minimum Commission"
              value={storeState.minimumCommission}
              type="text"
              helperText="Minimum Commission."
              onChange={(e) => {
                dispatch(setMinimumCommission(e));
              }}
              masking
              scale={2}
              numeric
            />
          </Grid>
        </Grid>

        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"percentageOfBasicCommission"}
              id="percentageOfBasicCommission"
              label="Percentage of Basic Commission"
              value={storeState.percentageOfBasicCommission}
              type="text"
              helperText="Percentage of Basic Commission."
              onChange={(e) => {
                dispatch(setBasicCommission(e));
              }}
              masking
              scale={2}
              numeric
            />
          </Grid>

          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"rvamount"}
              id="rvamount"
              label="Residual Value"
              value={storeState.rvamount}
              type="text"
              helperText="Residual Value of the asset paid at the end of the contract."
              onChange={(e) => {
                dispatch(setCommissionResidualValue(e));
              }}
              masking
              scale={2}
              currency
              optional
            />
          </Grid>
        </Grid>
        <CommissionSlabs />
        <Grid theme={unityTheme} container spacing={3} marginBottom={1}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Button
              className="btn-w-100"
              data-testid="btnCalculate"
              theme={unityTheme}
              onClick={(e) => calculateApr()}
              primary
              disabled={!calculateEnabled}
              text="Calculate"
              variant="contained"
            />
          </Grid>
        </Grid>
        <Grid
          theme={unityTheme}
          container
          spacing={3}
          marginTop={1}
          marginBottom={1}
        >
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="commissionResponse"
          >
            <Outputvalue
              value={storeState.commissionResponse}
              prefix="$"
              helperText={
                storeState.commissionResponse == "" ? "" : "Commission"
              }
            />
          </Grid>
        </Grid>
      </Box>
    </LayoutWithSideNavComponent>
  );
};
export default UpSellCommission;
