import ActionTypes from "../actionTypes";

export function setAmortizationMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_AMORTIZATION_METHOD,
    payload: parm,
  };
}
export function setAmortizationContractTerms(parm: string = "") {
  return {
    type: ActionTypes.SET_AMORTIZATION_CONTRACT_TERMS,
    payload: parm,
  };
}
export function setAmortizationStartDate(parm: any) {
  return {
    type: ActionTypes.SET_AMORTIZATION_START_DATE,
    payload: parm,
  };
}
export function setAmortizationEndDate(parm: any) {
  return {
    type: ActionTypes.SET_AMORTIZATION_END_DATE,
    payload: parm,
  };
}
export function setAmortizationComponentName(parm: string) {
  return {
    type: ActionTypes.SET_AMORTIZATION_COMPONENT_NAME,
    payload: parm,
  };
}
export function setAmortizationTaxRate(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_AMORTIZATION_TAX_RATA,
    payload: parm,
  };
}
export function setAmountToAmortize(parm: string) {
  return {
    type: ActionTypes.SET_AMOUNT_TO_AMORTIZE,
    payload: parm,
  };
}
