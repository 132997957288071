import { IExcelColumn } from "../../components/controls/ExcelTable/ExcelTable";
import { ActionTypes } from "../actionTypes/earlyTermination.actionTypes";

export function setContractInterestRate(parm: any) {
  return {
    type: ActionTypes.SET_CONTRACT_INTEREST_RATE,
    payload: parm,
  };
}
export function setOverDueInterestRate(parm: any) {
  return {
    type: ActionTypes.SET_OVER_DUE_INTEREST_RATE,
    payload: parm,
  };
}
export function setEtQuoteGenerationDate(parm: any) {
  return {
    type: ActionTypes.SET_ET_QUOTE_GENERATION_DATE,
    payload: parm,
  };
}
export function setClientEtDate(parm: any) {
  return {
    type: ActionTypes.SET_CLIENT_ET_DATE,
    payload: parm,
  };
}
export function setETMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_ET_METHOD,
    payload: parm,
  };
}
export function setETPenaltyMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_ET_PENALTY_METHOD,
    payload: parm,
  };
}
export function setETPenaltyReferenceAmountForPercentage(parm: string = "") {
  return {
    type: ActionTypes.SET_ET_PENALTY_REFERENCE_AMOUNT_FOR_PERCENTAGE,
    payload: parm,
  };
}
export function setETPenaltyAmount(parm: string = "") {
  return {
    type: ActionTypes.SET_ET_PENALTY_AMOUNT,
    payload: parm,
  };
}
export function setETPenaltyPercentage(parm: any) {
  return {
    type: ActionTypes.SET_ET_PENALTY_PERCENTAGE,
    payload: parm,
  };
}
export function setNoofdays(parm: any) {
  return {
    type: ActionTypes.SET_NO_OF_DAYS,
    payload: parm,
  };
}

export function setPTStructureReceivables(parm: any) {
  return {
    type: ActionTypes.SET_RECEIVABLES,
    payload: parm,
  };
}
export function setPTStructurePayables(parm: any) {
  return {
    type: ActionTypes.SET_PAYABLES,
    payload: parm,
  };
}

export function setPTStructureTaxes(parm: any) {
  return {
    type: ActionTypes.SET_TAXES,
    payload: parm,
  };
}

export function setStructurePTRentalDetails(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_DETAILS,
    payload: parm,
  };
}
export function setAssetPrice(parm: any) {
  return {
    type: ActionTypes.SET_ASSET_PRICE,
    payload: parm,
  };
}
export function setTotalOverDueAmount(parm: any) {
  return {
    type: ActionTypes.SET_TOTAL_OVERDUE_AMOUNT,
    payload: parm,
  };
}
export function setSundryCharges(parm: any) {
  return {
    type: ActionTypes.SET_SUNDRY_CHARGES,
    payload: parm,
  };
}
export function setAdditionalCharges(parm: any) {
  return {
    type: ActionTypes.SET_ADDITIONAL_CHARGES,
    payload: parm,
  };
}
export function setLatePaymentCharges(parm: any) {
  return {
    type: ActionTypes.SET_LATE_PAYMENT_CHARGES,
    payload: parm,
  };
}
export function setSecurityDeposit(parm: any) {
  return {
    type: ActionTypes.SET_SECURITY_DEPOSIT,
    payload: parm,
  };
}

export function setUnallocatedAmount(parm: any) {
  return {
    type: ActionTypes.SET_UNALLOCATED_AMOUNT,
    payload: parm,
  };
}

export function setPurchaseReturn(parm: any) {
  return {
    type: ActionTypes.SET_PURCHASE_RETURN,
    payload: parm,
  };
}

export function setRepaymentPlan(parm: any) {
  return {
    type: ActionTypes.SET_REPAYMENT_PLAN,
    payload: parm,
  };
}
export function setComponentName(parm: any) {
  return {
    type: ActionTypes.SET_COMPONENT_NAME,
    payload: parm,
  };
}
export function setComponentRentalDetail(parm: any) {
  return {
    type: ActionTypes.SET_COMP_RENTAL_DETAIL,
    payload: parm,
  };
}
