export enum RentalCalculationMethods {
  Flat = "Flat",
  Annuity = "Annuity",
  EqualPrincipal = "Equal Principal",
  RentalAmountFlatPLUS = "Flat Plus",
  Annuity365 = "Annuity365",
  Annuity360 = "Annuity360",
}

export enum etCalculationMethods {
  DailyFutureInterest = "DailyFutureInterest",
  EffectiveAnnuity = "EffectiveAnnuity",
  NetReceivables = "NetReceivables",
}

export enum SubsidyTypes {
  Rate = "Rate",
  Split = "Split",
}

export enum RentalTypes {
  InterestOnly = "Interest Only",
  Structured = "Structured",
  Auto = "Auto",
  Zero = "Zero",
  ResidualValue = "Residual Value",
  Bonus = "Bonus Payment",
  VAT = "VatDeferral",
}

export enum AmortizationMethods {
  StraightLine = "Straight Line",
  ActualNoOfDays = "Straight Line with Actual Number of Days",
  StraightLineProRata = "Straight Line Pro Rata",
  AnnuityActualNoOfDays = "Annuity - Actual No of Days",
}

export enum RentalPlanCalculationMethods {
  Annuity = "Annuity",
  Annuity360 = "Annuity360",
  Annuity365 = "Annuity365",
}

export enum RentalModes {
  Advance = "Advance",
  Arrear = "Arrear",
}

export enum ETPenaltyMethod {
  None = "None",
  Percentage = "Percentage",
  Amount = "Amount",
  NoOfDaysInterest = "NoOfDaysInterest",
}

export enum ETPenaltyReferenceAmountForPercentage {
  None = "None",
  PrincipalOutstanding = "PrincipalOutstanding",
  FutureInterest = "FutureInterest",
}

export enum RentalStatus {
  Paid = "Paid",
  Overdue = "Overdue",
  Unpaid = "Unpaid",
}

export enum Affordability {
  Finance = "Finance",
  DownPayment = "DownPayment",
}

export enum StepRentals {
  StepUp = "StepUp",
  StepDown = "StepDown",
}

export enum PurchaseReturn {
  Purchase = "Purchase",
  Return = "Return",
}

export enum HttpStatus {
  OK = 200,
  Conflict = 409,
  Bad_Request = 404,
}

export enum logoutandChangePassword {
  ChangePassword = "Change Password",
  Logout = "Logout",
}

export enum vatTreatment {
  VATPaidUpfront = "VATPaidUpfront",
  VATDeferral = "VATDeferral",
  VATAmortizeOverTheTerm = "VATAmortizeOverTheTerm",
}
export enum FeeType {
  DocFee = "DocFee",
  AnnualAdminFee = "AnnualAdminFee",
  OTPFee = "OTPFee",
}
export enum Currency {
  GBP = "£",
}
export enum FinanceType {
  HirePurchase = "HirePurchase",
  FinanceLease = "FinanceLease",
}
export enum PaymentMode {
  Advance = "Advance",
  Arrear = "Arrear",
}
export enum BalloonCollection {
  OneMonthAfterLastPayment = 1,
  WithLastPayment = 0,
}
