import { Box, Button, Grid, unityTheme } from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRentalDetailResponse } from "../../../calc-store/actions/actionsRentalCalculation";
import { RootState } from "../../../calc-store/reducers";
import { RentalCalculationMethods } from "../../../common/enums";
import {
  CalculateRentalAmountAnnuity,
  CalculateRentalAmountAnnuity360,
  CalculateRentalAmountAnnuity365,
  CalculateRentalAmountEP,
  CalculateRentalAmountFlat,
  RentalAmountFlatPLUS,
} from "../../../helpers/ServiceHelper";
import { ValidateRentalCalculation } from "../../../validations/ValidateRentalCalculation";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";

export const CalculateRentals = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRentalCalculation
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });

  const calculate = () => {
    var validate = ValidateRentalCalculation(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      dispatch(setRentalDetailResponse([]));
      switch (storeState.rentalCalcMethod) {
        case RentalCalculationMethods.Flat: {
          setCalculateEnabled(false);
          CalculateRentalAmountFlat(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.Annuity: {
          setCalculateEnabled(false);
          CalculateRentalAmountAnnuity(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });

          return;
        }
        case RentalCalculationMethods.Annuity365: {
          setCalculateEnabled(false);

          CalculateRentalAmountAnnuity365(storeState)
            .then((res: any) => {
              let rentalResponse = [];
              res.rentalSummary.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.Annuity360: {
          setCalculateEnabled(false);

          CalculateRentalAmountAnnuity360(storeState)
            .then((res: any) => {
              let rentalResponse = [];
              res.rentalSummary.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.EqualPrincipal: {
          setCalculateEnabled(false);

          CalculateRentalAmountEP(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
        case RentalCalculationMethods.RentalAmountFlatPLUS: {
          setCalculateEnabled(false);

          RentalAmountFlatPLUS(storeState)
            .then((res) => {
              let rentalResponse = [];
              res.map((r) => {
                rentalResponse.push({
                  content: [
                    r.startTerm,
                    r.endTerm,
                    <FormattedValue value={r.rentalAmount.toString()} />,
                    r.rentalType,
                  ],
                });
              });
              dispatch(setRentalDetailResponse(rentalResponse));
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setErrorDialog({ visible: true, error: ex.response.data });
              setCalculateEnabled(true);
            });
          return;
        }
      }
    }
  };
  return (
    <Box theme={unityTheme}>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <Grid theme={unityTheme} container spacing={4} mb={4}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="txtCalculate"
            theme={unityTheme}
            onClick={() => calculate()}
            primary
            disabled={!calculateEnabled}
            text="Calculate"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
