import {
  Box,
  Checkbox,
  Grid,
  Typography,
  unityTheme,
} from "@ntpkunity/controls";

import { Dropdown, Radiobutton, TextBox } from "../../../controls";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../calc-store/reducers";
import {
  setAPR,
  setContractTerms,
  setFinancedAmount,
  setRentalFrequency,
  setRentalMode,
  setResidualValue,
  setAddTax,
  setTaxRate,
  setInclusiveTax,
  setRentalComponent,
  setIsRoundingRequired,
  setRoundingUpTp,
} from "../../../../calc-store/actions/actionAnnuityStraightLine";
import { Divider } from "../../../controls/Divider/Divider";
import {
  rentalComponentsNoTax,
  yesNo,
  yesNoValues,
  rentalFrequencies,
  rentalModes,
} from "../../../../common/constants";
import { StructureRental } from "./AnnuityRentalStructures";
import { AnnuityIncomeExpense } from "./AnnuityIncomeExpense";

export const AnnuityStraightLine = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerAnnuityStraightLineCalculation
  );
  var dispatch = useDispatch();
  const getTaxRate = () => {
    if (storeState.addTax == "true") {
      return (
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"taxRate"}
              id="taxRate"
              label="Tax Rate"
              value={storeState.taxRate}
              type="text"
              onChange={(e) => {
                dispatch(setTaxRate(e));
              }}
              endAdornment="%"
              masking
              scale={4}
              numeric
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={yesNo}
              label="Is Tax Inclusive"
              onChange={(e) => {
                dispatch(setInclusiveTax(e.target.value));
              }}
              value={storeState.inclusiveTax}
              testid="cbxIsTaxInclusive"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalComponentsNoTax}
              label="Rental Component"
              onChange={(e) => {
                dispatch(setRentalComponent(e.target.value));
              }}
              value={storeState.rentalComponent}
              testid="cbxRentalComponent"
            />
          </Grid>
        </Grid>
      );
    } else {
      <div />;
    }
  };
  const getRoundingRequired = () => {
    if (storeState.isRoundingRequired == true)
      return (
        <Grid theme={unityTheme} item xs={12} sm={8}>
          <Grid theme={unityTheme} container spacing={3}>
            <Grid theme={unityTheme} item xs={4} ml={12}>
              <TextBox
                label="Up To"
                type="number"
                value={storeState.roundingUpTo}
                onChange={(e) => {
                  dispatch(setRoundingUpTp(e));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    else null;
  };
  return (
    <Box theme={unityTheme} mb={3}>
      <Grid theme={unityTheme} spacing={3}>
        <Grid theme={unityTheme} container spacing={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalModes}
              label="Payment Mode"
              onChange={(e) => {
                dispatch(setRentalMode(e.target.value));
              }}
              value={storeState.rentalMode}
              helperText="If payment to be received at the beginning of the period or end of the period."
              testid="cbxAnnuityRentalMode"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalFrequencies}
              label="Payment Frequency"
              onChange={(e) => {
                dispatch(setRentalFrequency(e.target.value));
              }}
              value={storeState.rentalFrequency}
              helperText="The frequency of installment  amount to be paid."
              testid="cbxAnnuityRentalFrequency"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"txtTerms"}
              id="txtTerms"
              label="Contract Duration in Months"
              value={storeState.contractTerms}
              type="text"
              helperText="Contract Duration in Months."
              masking
              numeric
              scale={0}
              thousandSeparator={false}
              onChange={(e) => {
                dispatch(setContractTerms(e));
              }}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"txtAPR"}
              id="txtAPR"
              label="Interest Rate"
              value={storeState.APR}
              type="text"
              helperText="Annual Interest Rate."
              onChange={(e) => {
                dispatch(setAPR(e));
              }}
              endAdornment="%"
              masking
              scale={4}
              numeric
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"txtFinancedAmount"}
              id="txtFinancedAmount"
              label="Lending Amount"
              value={storeState.financedAmount}
              type="text"
              helperText="Final lending amount."
              onChange={(e) => {
                dispatch(setFinancedAmount(e));
              }}
              masking
              currency
              scale={2}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"txtResidualValue"}
              id="txtResidualValue"
              label="Residual Value"
              value={storeState.residualValue}
              type="text"
              helperText="Residual Value of the asset paid at the end of the contract.."
              onChange={(e) => {
                dispatch(setResidualValue(e));
              }}
              masking
              currency
              scale={2}
              optional
            />
          </Grid>
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Grid
            theme={unityTheme}
            container
            spacing={1}
            marginTop={2}
            marginBottom={1}
            marginLeft={1}
          >
            <div>
              <Typography component="h4" variant="h4" theme={unityTheme}>
                Would you like to add tax?
              </Typography>
            </div>
          </Grid>
          <Grid
            theme={unityTheme}
            container
            spacing={-7}
            marginBottom={1}
            marginLeft={1}
          >
            <Radiobutton
              options={yesNoValues}
              onChange={(e) => {
                dispatch(setAddTax(e));
              }}
              value={storeState.addTax}
              defaultValue={storeState.addTax}
            />
          </Grid>
          {getTaxRate()}
        </Grid>
        <hr />
        <StructureRental />
        <AnnuityIncomeExpense />
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          display={"none"}
        >
          <Grid
            theme={unityTheme}
            container
            spacing={2}
            marginTop={2}
            marginBottom={1}
            marginLeft={1}
          >
            <div>
              <Typography component="h4" variant="h4" theme={unityTheme}>
                Rounding
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid theme={unityTheme} item xs={14} sm={10} display={"none"}>
          <Grid theme={unityTheme} container spacing={3}>
            <Grid theme={unityTheme} item>
              <Checkbox
                label={"Rounding Required"}
                theme={unityTheme}
                checkBoxChecked={storeState.isRoundingRequired}
                onChange={(e: any) => {
                  dispatch(setIsRoundingRequired(e.target.checked));
                }}
              ></Checkbox>
            </Grid>
            {getRoundingRequired()}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
