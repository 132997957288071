import { useState } from "react";
import { IconButton, Divider, DividerProps } from "@mui/material";
import {
  Icon,
  ISideBarLayoutProps,
  Grid,
  Typography,
  Avatar,
  Box,
  List,
  Switch as CustomizedSwitches,
  Menu,
} from "@ntpkunity/controls";
import {
  CustomizedContainer,
  openedMixin,
  closedMixin,
  CustomizedDrawer,
  LayoutWrap,
} from "./layoutWithSideNavStyle";
import LogoMenuOpen from "../../../../shared/assets/images/flex-logo.svg";
import LogoMenuClosed from "../../../../shared/assets/images/flex-logo-initial.svg";
import NavToggleIconClosed from "../../../../shared/assets/images/ic-nav-toggle.svg";
import NavToggleIconOpen from "../../../../shared/assets/images/chevron-left.svg";
import UserIcon from "../../../../shared/assets/images/user-icon.svg";
import { getToken } from "./../../../../helpers/Localstorage";
import { useNavigate } from "react-router-dom";
import { logoutandChangePassword } from "../../../../common/enums";
import { logout } from "@ntpkunity/controls-ums";
import { APP_Routes } from "../../../../components/controls/links";

export const LayoutWithSideNavComponent: React.FC<ISideBarLayoutProps> = ({
  children,
  theme,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const isSelected = (route) => {
    return window.location.pathname == route;
  };
  const getInitials = (str) => {
    if (str != "" && str != undefined) {
      return str
        .match(/(^\S\S?|\s\S)?/g)
        .map((v) => v.trim())
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toLocaleUpperCase();
    } else {
      return "fx";
    }
  };

  const userManagmentMenu = () => {
    if (getToken()?.role?.is_admin) {
      return {
        label: "User Management",
        hideLabel: !getToken()?.role?.is_admin ?? true,
        selected: false,
        hideIcon: false,
        onClick: () => {},
        listItemButtonSx: undefined,
        listItemIconSx: undefined,
        listItemTextSx: undefined,
        subOptions: [
          {
            label: "Manage Users",
            selected: isSelected(APP_Routes.manageusers),
            onClick: () => {
              navigate(APP_Routes.manageusers);
            },
          },
        ],
      };
    } else {
      return {
        label: "",
        hideLabel: true,
        selected: false,
        hideIcon: false,
      };
    }
  };
  const _menuOptions = [
    {
      label: "Rental & Repayment Plan",
      hideLabel: false,
      selected: isSelected(APP_Routes.FinancialCalculation),
      hideIcon: false,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      onClick: () => {
        navigate(APP_Routes.FinancialCalculation);
      },
      subOptions: [],
    },
    // {
    //   label: "Rental & Repayment Plan (APR)",
    //   hideLabel: false,
    //   selected: isSelected(APP_Routes.FinancialCalculationAPR),
    //   hideIcon: false,
    //   listItemButtonSx: undefined,
    //   listItemIconSx: undefined,
    //   listItemTextSx: undefined,
    //   onClick: () => {
    //     navigate(APP_Routes.FinancialCalculationAPR);
    //   },
    //   subOptions: [],
    // },
    {
      label: "Quick Quote",
      hideLabel: false,
      hideIcon: false,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: "Generate Quote",
          selected: isSelected(APP_Routes.quickquote),
          onClick: () => {
            navigate(APP_Routes.quickquote);
          },
        },
        {
          label: "Configuration",
          selected: isSelected(APP_Routes.quoteConfiguration),
          onClick: () => {
            navigate(APP_Routes.quoteConfiguration);
          },
        },
      ],
    },
    {
      label: "Loan & Lease Initiation",
      selected: false,
      hideLabel: false,
      hideIcon: false,
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: "Affordability checks",
          selected: isSelected(APP_Routes.AffordabilityChecks),
          onClick: () => {
            navigate(APP_Routes.AffordabilityChecks);
          },
        },
        {
          label: "Rental Calculation",
          selected: isSelected(APP_Routes.RentalCalculation),
          onClick: () => {
            navigate(APP_Routes.RentalCalculation);
          },
        },
        {
          label: "Calculate Rate",
          selected: isSelected(APP_Routes.APR),
          onClick: () => {
            navigate(APP_Routes.APR);
          },
        },
        {
          label: "Step Rental",
          selected: isSelected(APP_Routes.StepRental),
          onClick: () => {
            navigate(APP_Routes.StepRental);
          },
        },
      ],
    },
    {
      label: "Contract Booking",
      hideLabel: false,
      selected: false,
      hideIcon: false,
      onClick: () => {},
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: "Repayment Plan",
          selected: isSelected(APP_Routes.Repaymentplan),
          onClick: () => {
            navigate(APP_Routes.Repaymentplan);
          },
        },
        {
          label: "Subsidy / Subvention",
          selected: isSelected(APP_Routes.Subsidy_Subvention),
          onClick: () => {
            navigate(APP_Routes.Subsidy_Subvention);
          },
        },
        {
          label: "Amortization / Accounting Postings",
          selected: isSelected(APP_Routes.Amortization),
          onClick: () => {
            navigate(APP_Routes.Amortization);
          },
        },
        {
          label: "Dealer / Broker Commission",
          selected: isSelected(APP_Routes.Commission),
          onClick: () => {
            navigate(APP_Routes.Commission);
          },
        },
        {
          label: "Depreciation",
          onClick: () => {
            navigate(APP_Routes.commingsoon);
          },
        },
      ],
    },
    {
      label: "In-Life Modifications",
      hideLabel: false,
      selected: false,
      hideIcon: false,
      onClick: () => {},
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: "Change of Due date",
          onClick: () => {
            navigate(APP_Routes.commingsoon);
          },
        },
        {
          label: "Restructuring",
          onClick: () => {
            navigate(APP_Routes.commingsoon);
          },
        },
      ],
    },
    {
      label: "End of Term",
      hideLabel: false,
      selected: false,
      hideIcon: false,
      onClick: () => {},
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: "Early Payout",
          selected: isSelected(APP_Routes.EarlyPayout),
          onClick: () => {
            navigate(APP_Routes.EarlyPayout);
          },
        },
        {
          label: "Residual Value Extension",
          selected: isSelected(APP_Routes.ExtendRV),
          onClick: () => {
            navigate(APP_Routes.ExtendRV);
          },
        },
      ],
    },
    userManagmentMenu(),
  ];

  return (
    <>
      <LayoutWrap theme={theme} component="div">
        <Box theme={theme} component="aside" className="u-aside-wrap">
          <CustomizedDrawer
            theme={theme}
            variant="permanent"
            open={open}
            className="u-side-nav"
          >
            <Box theme={theme} className="logo-wrap">
              <Box theme={theme} className="logo-area">
                <img src={LogoMenuOpen} alt="flex" className="menu-open-logo" />
                <IconButton
                  onClick={() => setOpen(!open)}
                  className="toggle-btn"
                >
                  <img
                    src={LogoMenuClosed}
                    alt="flex"
                    className="menu-closed-logo"
                  />
                </IconButton>
              </Box>
              <Box theme={theme} className="menu-action">
                {/* <CustomizedSwitches
                  onChange={(e) => {}}
                  theme={theme}
                ></CustomizedSwitches> */}
                <IconButton
                  onClick={() => openInNewTab(`${process.env.DOCS_URL}`)}
                  className="docs-btn"
                >
                  <Icon name="PaperIcon" />
                </IconButton>
                <IconButton
                  onClick={() => setOpen(!open)}
                  className="menu-toggle"
                >
                  <Box theme={theme} className="menu-icon">
                    <Box theme={theme} className="toggle-bar"></Box>
                  </Box>
                </IconButton>
              </Box>
            </Box>
            <Box theme={theme} className="divider-wrap" ml={3} mr={3}>
              <Divider />
            </Box>
            <div className="side-nav-area-wrap">
              <div className="aside-content-wrap content-top">
                <Box theme={theme} className="nav-toggle">
                  <IconButton
                    onClick={() => setOpen(!open)}
                    className="toggle-btn"
                  >
                    <img src={NavToggleIconClosed} alt="Open Navigation" />
                  </IconButton>
                </Box>
                <List theme={theme} options={_menuOptions} />
              </div>
            </div>
            <div className="aside-content-wrap action-content">
              <Box theme={theme} className="divider-wrap" ml={3} mr={3}>
                <Divider />
              </Box>
              <div className="side-nav-actions">
                <Box theme={theme} className="open-left-menu-action">
                  <Menu
                    theme={theme}
                    disablePortal={true}
                    options={[
                      { optionText: "Change Password" },
                      { optionText: "Logout" },
                    ]}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    render={(cb) => (
                      <a
                        href="#link"
                        className="user-info desktop-view"
                        onClick={cb}
                      >
                        <Typography
                          className="user-initials"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          {getInitials(getToken()?.user_name)}
                        </Typography>
                        <Typography
                          className="user-fullname"
                          theme={theme}
                          variant="body1"
                          component="p"
                        >
                          {getToken()?.user_name}
                        </Typography>
                      </a>
                    )}
                    handleOptionClick={(e) => {
                      if (
                        e.target.outerText ==
                        logoutandChangePassword.ChangePassword
                      ) {
                        navigate(APP_Routes.changepassword);
                      } else if (
                        e.target.outerText == logoutandChangePassword.Logout
                      ) {
                        logout();
                      }
                    }}
                  />
                  <Box
                    theme={theme}
                    className="user-action-mobile"
                    display={{ xs: "block", md: "none" }}
                  >
                    <a href="#link" className="user-info">
                      <Typography
                        className="user-initials"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        {getInitials(getToken()?.user_name)}
                      </Typography>
                      <Typography
                        className="user-fullname"
                        theme={theme}
                        variant="body1"
                        component="p"
                      >
                        {getToken()?.user_name}
                      </Typography>
                    </a>
                    <List
                      theme={theme}
                      options={[
                        {
                          label: "Change Password",
                          hideLabel: false,
                          selected: isSelected(APP_Routes.changepassword),
                          hideIcon: false,
                          onClick: () => {
                            navigate(APP_Routes.changepassword);
                          },
                        },
                        {
                          label: "Logout",
                          hideLabel: false,
                          hideIcon: false,
                          onClick: () => {
                            logout();
                          },
                        },
                      ]}
                    />
                  </Box>
                </Box>
                <Box theme={theme} className="user-icon">
                  <Menu
                    theme={theme}
                    options={[
                      { optionText: "Change Password" },
                      { optionText: "Logout" },
                    ]}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    render={(cb) => (
                      <IconButton onClick={cb} className="toggle-btn">
                        <img src={UserIcon} alt="user" />
                      </IconButton>
                    )}
                    handleOptionClick={(e) => {
                      if (
                        e.target.outerText ==
                        logoutandChangePassword.ChangePassword
                      ) {
                        navigate(APP_Routes.changepassword);
                      } else if (
                        e.target.outerText == logoutandChangePassword.Logout
                      ) {
                        logout();
                      }
                    }}
                  />
                </Box>
              </div>
            </div>
          </CustomizedDrawer>
        </Box>
        <CustomizedContainer theme={theme} open={open} component="main">
          <Box theme={theme} className="nav-open-toggle" display={"none"}>
            <IconButton
              className="toggle-nav-btn"
              onClick={() => setOpen(!open)}
            >
              <img src={NavToggleIconOpen} alt="Close Navigation" />
            </IconButton>
          </Box>
          <Box theme={theme} className="right-body-content">
            <Box theme={theme} component="header" sx={{ display: "none" }}>
              <Grid theme={theme} direction="row" container>
                <Grid theme={theme} item xs={6} md={6}></Grid>
                <Grid
                  theme={theme}
                  item
                  display="flex"
                  justifyContent="right"
                  alignItems={"center"}
                  xs={6}
                  md={6}
                  className="header-nav-right"
                >
                  <a href="#link" className="header-link-item item-icon-only">
                    <Icon name="SearchIcon" />
                  </a>
                  <a href="#link" className="header-link-item item-icon-only">
                    <Icon name="BellIcon" />
                  </a>
                  <a
                    href="#link"
                    className="header-link-item user-link"
                    onClick={() => {}}
                  >
                    <Avatar theme={theme}></Avatar>
                    <span className="status-icon"></span>
                  </a>
                </Grid>
                <Divider />
              </Grid>
            </Box>
            {children}
            {/* <Box component="footer">footer</Box> */}
          </Box>
        </CustomizedContainer>
      </LayoutWrap>
    </>
  );
};

export default LayoutWithSideNavComponent;
