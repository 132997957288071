import ActionTypes from "../actionTypes";

export function setSubsidyRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_SUBSIDY_RENTAL_MODE,
    payload: parm,
  };
}
export function setSubsidyRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_SUBSIDY_RENTAL_FREQ,
    payload: parm,
  };
}
export function setSubsidyRentalSlabs(parm: any) {
  return {
    type: ActionTypes.SET_SUBSIDY_RENTAL_SLABS,
    payload: parm,
  };
}
export function setSubsidyFinancedAmount(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_SUBSIDY_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setSubsidyResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_SUBSIDY_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setTotalSubsidyAmount(parm: string) {
  return {
    type: ActionTypes.SET_TOTAL_SUBSIDY_AMOUNT,
    payload: parm,
  };
}
export function setManufacturerSubsidyAmount(parm: string) {
  return {
    type: ActionTypes.SET_MANUFACTURER_SUBSIDY_AMOUNT,
    payload: parm,
  };
}
export function setDealerSubsidyAmount(parm: string) {
  return {
    type: ActionTypes.SET_DEALER_SUBSIDY_AMOUNT,
    payload: parm,
  };
}
export function setSubsidyFinancierRate(parm: string) {
  return {
    type: ActionTypes.SET_SUBSIDY_FINANCIER_RATE,
    payload: parm,
  };
}
export function setSubsidyType(parm: string) {
  return {
    type: ActionTypes.SET_SUBSIDY_TYPE,
    payload: parm,
  };
}
export function setSubsidyManufacturerRate(parm: string) {
  return {
    type: ActionTypes.SET_SUBSIDY_MANUFACTURER_RATE,
    payload: parm,
  };
}
export function setSubsidyCustomerRate(parm: string) {
  return {
    type: ActionTypes.SET_SUBSIDY_CUSTOMER_RATE,
    payload: parm,
  };
}
export function setSubsidyDealerRate(parm: string) {
  return {
    type: ActionTypes.SET_SUBSIDY_DEALER_RATE,
    payload: parm,
  };
}
