import { IReducerSubsidyCalculation } from "../calc-store/reducers/reducersSubsidyCalculation";
import { SubsidyTypes } from "../common/enums";
export const ValidateSubsidyCalculation = (
  state: IReducerSubsidyCalculation
) => {
  if (state.subsidyType == "" || state.subsidyType == undefined) {
    return {
      visible: true,
      error: "Please select Subsidy Type.",
    };
  } else if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending amount.",
    };
  } else if (state.subsidyType == SubsidyTypes.Rate) {
    if (state.financierRate == "" || state.financierRate == undefined) {
      return {
        visible: true,
        error: "Please provide Financier Rate.",
      };
    } else {
      return {
        visible: false,
        error: "",
      };
    }
  } else if (state.subsidyType == SubsidyTypes.Split) {
    if (state.customerRate == "" || state.customerRate == undefined) {
      return {
        visible: true,
        error: "Please provide Customer Rate.",
      };
    } else if (
      state.manufacturerRate == "" ||
      state.manufacturerRate == undefined
    ) {
      return {
        visible: true,
        error: "Please provide Manufacturer Rate.",
      };
    } else if (state.dealerRate == "" || state.dealerRate == undefined) {
      return {
        visible: true,
        error: "Please provide Dealer Rate.",
      };
    } else {
      return {
        visible: false,
        error: "",
      };
    }
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
