import ActionTypes from "../actionTypes";

export interface IReducerAmortizationCalculation {
  amountToAmortize: string;
  amortizationCalcMethod: string;
  contractTerms: string;
  startDate: any;
  endDate: any;
  componentName: string;
  taxRate: number;
}

const initialState: IReducerAmortizationCalculation = {
  amountToAmortize: "",
  amortizationCalcMethod: "Straight Line",
  contractTerms: "",
  startDate: new Date(),
  endDate: new Date(),
  componentName: "",
  taxRate: 0,
};
export function reducersAmortizationCalculation(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_AMORTIZATION_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_AMORTIZATION_METHOD: {
      return {
        ...currentState,
        amortizationCalcMethod: action.payload,
      };
    }
    case ActionTypes.SET_AMOUNT_TO_AMORTIZE: {
      return {
        ...currentState,
        amountToAmortize: action.payload,
      };
    }
    case ActionTypes.SET_AMORTIZATION_START_DATE: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.SET_AMORTIZATION_END_DATE: {
      return {
        ...currentState,
        endDate: action.payload,
      };
    }
    case ActionTypes.SET_AMORTIZATION_COMPONENT_NAME: {
      return {
        ...currentState,
        componentName: action.payload,
      };
    }
    case ActionTypes.SET_AMORTIZATION_TAX_RATA: {
      return {
        ...currentState,
        taxRate: action.payload,
      };
    }
    default:
      return currentState;
  }
}
