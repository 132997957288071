import { IRentalFrequency } from "../interfaces/IRentalFrequency";
export const GetRentalFrequency = (frequency: string) => {
  var rentalFrequency: IRentalFrequency = {
    FrequencyBase: "Months",
    BaseTerms: 1,
    YearlyTerms: 12,
  };
  switch (frequency) {
    case "Monthly":
      rentalFrequency.BaseTerms = 1;
      rentalFrequency.YearlyTerms = 12;
      break;
    case "SemiAnnual":
      rentalFrequency.BaseTerms = 6;
      rentalFrequency.YearlyTerms = 2;
      break;
    case "Annual":
      rentalFrequency.BaseTerms = 12;
      rentalFrequency.YearlyTerms = 1;
      break;
    case "Quarterly":
      rentalFrequency.BaseTerms = 3;
      rentalFrequency.YearlyTerms = 4;
      break;
    case "Weekly":
      rentalFrequency.BaseTerms = 7;
      rentalFrequency.YearlyTerms = 52;
      break;
    case "Fortnightly":
      rentalFrequency.BaseTerms = 15;
      rentalFrequency.YearlyTerms = 26;
      break;
    default:
      rentalFrequency.BaseTerms = 1;
      rentalFrequency.YearlyTerms = 12;
      break;
  }
  return rentalFrequency;
};
