import ActionTypes from "../actionTypes";
export type IAffordabilityProps = {
  downPayment: string;
  rentalMode: string;
  rentalFrequency: string;
  contractTerms: string;
  residualValue: string;
  rentalAmount: string;
  APR: string;
  response: string;
  financedAmount: string;
};
export interface IReducerAffordabilityChecks {
  reverseDownPayment: IAffordabilityProps;
  reverseFinancedAmount: IAffordabilityProps;
}
const initialState: IReducerAffordabilityChecks = {
  reverseDownPayment: {
    downPayment: "",
    contractTerms: "",
    rentalMode: "",
    rentalFrequency: "",
    residualValue: "",
    rentalAmount: "",
    financedAmount: "",
    APR: "",
    response: "",
  },
  reverseFinancedAmount: {
    downPayment: "",
    contractTerms: "",
    rentalMode: "",
    rentalFrequency: "",
    residualValue: "",
    rentalAmount: "",
    financedAmount: "",
    APR: "",
    response: "",
  },
};
export function reducersAffordabilityChecks(
  currentState = initialState,
  action: any
): IReducerAffordabilityChecks {
  switch (action.type) {
    case ActionTypes.SET_REVERSE_DOWN_PAYMENT: {
      return {
        ...currentState,
        reverseDownPayment: action.payload,
      };
    }
    case ActionTypes.SET_REVERSE_FINANCED_AMOUNT: {
      return {
        ...currentState,
        reverseFinancedAmount: action.payload,
      };
    }
    default:
      return currentState;
  }
}
