import { IStepRentalProps } from "../calc-store/reducers/reducersStepRentals";
export const ValidateSteprental = (state: IStepRentalProps) => {
  if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.contractTerms == "" ||
    state.contractTerms == "0" ||
    state.contractTerms == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Contract Duration in Months.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending amount.",
    };
  } else if (state.stepType == "" || state.stepType == undefined) {
    return {
      visible: true,
      error: "Please select Step Type.",
    };
  } else if (
    state.stepPercentage == "" ||
    state.stepPercentage == "0" ||
    state.stepPercentage == undefined
  ) {
    return {
      visible: true,
      error: "Please Provide Step up / down %.",
    };
  } else if (
    state.stepFrequency == "" ||
    state.stepFrequency == "0" ||
    state.stepFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please Provide Step up / down Frequency.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
