import { Box, Grid, unityTheme, ScrollableTabs } from "@ntpkunity/controls";
import { RootState } from "../../../calc-store/reducers";
import { useSelector } from "react-redux";
import { Table } from "../../controls";
import RentalDetail from "./RentalDetail";
import RepaymentPlan from "./RepaymentPlan";

export const StepRentalResponse = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersStepRentals
  );
  const columns = [] as any;
  columns.push({
    title: "Rental Detail",
    content: <RentalDetail rows={storeState.stepRentals.rentalDetail} />,
  });
  columns.push({
    title: "Repayment Plan",
    content: <RepaymentPlan rows={storeState.stepRentals.repaymentPlan} />,
  });
  const getTabs = () => {
    if (storeState.stepRentals.rentalDetail.length > 0) {
      return (
        <ScrollableTabs
          theme={unityTheme}
          items={columns}
          defaultTabIndex={0}
        />
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <Box theme={unityTheme} mb={10}>
      <Grid theme={unityTheme}>{getTabs()}</Grid>
    </Box>
  );
};
