import { styled, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ChangePasswordComponent, IResponse } from "@ntpkunity/controls-ums";
import {
  Box,
  Container,
  IBoxProps,
  unityTheme,
  Snackbar,
} from "@ntpkunity/controls";
import { HttpStatus } from "../../../common/enums";
import { LayoutWithSideNavComponent } from "../../../modules";
import { APP_Routes } from "../../controls/links";

interface IChangePasswordProps {}
const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    padding: "32px 24px 50px",
    height: "90vh",
    [theme.breakpoints.up("md")]: {
      paddingTop: 75,
      paddingBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      height: "auto",
    },
    ".custom-container": {
      height: "100%",
      [theme.breakpoints.up("md")]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    ".text-danger": {
      color: theme.palette.error.main,
    },
    ".full-page-wrap": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      maxWidth: 480,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 24,
      },
    },
  },
}));

export const ChangePassword: FC<IChangePasswordProps> = () => {
  const theme = useTheme();
  const [response, setResponse] = useState<IResponse>();
  const [errorDialog, setErrorDialog] = useState(false);
  const [Dialog, setDialog] = useState(false);
  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setDialog(true);
    } else if (response?.status === HttpStatus.Conflict) {
      setErrorDialog(true);
    }
  }, [response]);
  const getSuccessToast = () => {
    if (Dialog == true) {
      return (
        <div>
          <Snackbar
            theme={theme}
            autoHideDuration={3000}
            message={response.data}
            open={Dialog}
            onClose={() => setDialog(false)}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const getErrorToast = () => {
    if (errorDialog == true) {
      return (
        <div>
          <Snackbar
            theme={theme}
            autoHideDuration={3000}
            message={response.message}
            open={errorDialog}
            onClose={() => setErrorDialog(false)}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <div>
      <LayoutWithSideNavComponent theme={unityTheme}>
        <MainContent theme={theme} className="main-content">
          <Container theme={theme} maxWidth="lg" className="custom-container">
            <Box theme={theme} className="full-page-wrap">
              <ChangePasswordComponent
                theme={theme}
                setResponse={setResponse}
                next_screen_url={`${window.location.origin.toString()}${
                  APP_Routes.changepassword
                }`}
              />
              {getSuccessToast()}
              {getErrorToast()}
            </Box>
          </Container>
        </MainContent>
      </LayoutWithSideNavComponent>
    </div>
  );
};
