import ActionTypes from "../actionTypes";

const initialState = {
  maturity: 0,
  inception: 0,
};
export function reducersAdditionalCharges(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_INCEPTION_FEE: {
      return {
        ...currentState,
        inception: action.payload,
      };
    }
    case ActionTypes.SET_MATURITY_FEE: {
      return {
        ...currentState,
        maturity: action.payload,
      };
    }
    default:
      return currentState;
  }
}
