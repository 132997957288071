import {
  Box,
  Button,
  Grid,
  Icon,
  Typography,
  unityTheme,
  IBoxProps,
  Snackbar,
} from "@ntpkunity/controls";
import { styled } from "@mui/material/styles";
import CalculationHeader from "../../controls/CalculationHeader";
import {
  CreateUserDrawerComponent,
  UserListingComponent,
  UMSStoreContext,
} from "@ntpkunity/controls-ums";
import { useContext, useEffect, useState } from "react";
import { LayoutWithSideNavComponent } from "../../../modules";
import { Main } from "../../../shared/theme/drawerStyle";
import { getToken } from "../../../helpers/Localstorage";
import { HttpStatus } from "../../../common/enums";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
  },
}));

export const ManageUsers = () => {
  const [response, setResponse] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [Success, setSuccess] = useState(false);
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setSuccess(true);
    } else if (response?.status === HttpStatus.Conflict) {
      setError(true);
    }
  }, [response]);

  const getSuccessToast = () => {
    if (Success == true) {
      return (
        <div>
          <Snackbar
            theme={unityTheme}
            autoHideDuration={3000}
            message={response.data.message}
            open={Success}
            onClose={() => setSuccess(false)}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const getErrorToast = () => {
    if (error == true) {
      return (
        <div>
          <Snackbar
            theme={unityTheme}
            autoHideDuration={3000}
            message={response.message}
            open={error}
            onClose={() => setError(false)}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <Main open={open}>
      <LayoutWithSideNavComponent theme={unityTheme}>
        <MainContent theme={unityTheme} className="main-content">
          <CalculationHeader
            text={"User Management"}
            caption="A Complete Solution for User Management"
          />
          <Box
            theme={unityTheme}
            display={"flex"}
            alignItems={"center"}
            pt={3}
            pb={3}
          >
            <Typography
              flexGrow={1}
              className="text-muted"
              theme={unityTheme}
              component="h3"
              variant="h3"
            >
              Manage Users
            </Typography>
            <Box theme={unityTheme} flexShrink={0}>
              <Button
                primary
                theme={unityTheme}
                startIcon={<Icon name="AddIcon" />}
                text={"Add New User"}
                onClick={(e) => setOpen(true)}
              />
            </Box>
          </Box>
          <Box theme={unityTheme} mb={3}>
            <Grid theme={unityTheme}>
              <Grid
                container
                xs={12}
                theme={unityTheme}
                mt={-3}
                justifyContent="flex-end"
              ></Grid>
            </Grid>
          </Box>

          <UserListingComponent
            actionType={"add"}
            setopenAddComponent={setOpen}
            openAddComponent={open}
            productId={productId?.productId}
            theme={unityTheme}
            setResponse={setResponse}
            tenantId={getToken().tenant_id}
            value={""}
            componentBehavior={"drawer"}
            roleDisabled={true}
            nextScreenUrl={`/market-place/app-user-profile`}
            layoutText={{
              nameLabel: "First/Last Name",
              emailAddressLabel: "Email Address",
              productsLabel: "Product(s)",
              rolesLabel: "Role(s)",
              contactNumLabel: "Contact Number",
              statusLabel: "Status",
            }}
            openPopup={false}
            close={function (): void {
              throw new Error("Function not implemented.");
            }}
          ></UserListingComponent>
          {getSuccessToast()}
          {getErrorToast()}
        </MainContent>
      </LayoutWithSideNavComponent>
    </Main>
  );
};
