import React from "react";
import { unityTheme, Box } from "@ntpkunity/controls";
import { Table } from "../../controls/Table/Table";
import store from "../../../calc-store/store";
import { RootState } from "../../../calc-store/reducers";
import { useDispatch, useSelector } from "react-redux";

interface IRentalDetail {
  rows: [];
}
const RentalDetail: React.FC<IRentalDetail> = ({ rows }: IRentalDetail) => {
  const storeState = useSelector((state: RootState) => state.reducerRentalAPR);
  const columns = [] as any;
  columns.push("Term From");
  columns.push("Term To");
  columns.push("Rental Amount");
  columns.push("Rental Type");
  if (rows.length > 0) {
    return (
      <Table
        columns={columns}
        rows={rows}
        showExport
        fileName={"Rental Detail"}
      />
    );
  } else {
    <div></div>;
  }
};
export default RentalDetail;
