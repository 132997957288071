import {
  BalloonCollection,
  FeeType,
  FinanceType,
  vatTreatment,
} from "../../common/enums";
import {
  INotification,
  IRequestObj,
  IResponseObj,
  RatesVisibility,
} from "../../interfaces/IQuickQuoteInterfaces";
import ActionTypes from "../actionTypes";

export type IReducerFinancialCalculation = {
  CalculationInProgress: boolean;
  RentalCalculationInProgress: boolean;
  CalculatedNFA: number;
  CalculatedCommission: number;
  RequestObj: IRequestObj;
  ResponseObj: IResponseObj;
  Notification: INotification;
  SliderOpen: boolean;
  RatesVisibility: RatesVisibility;
  GrossProfit: number;
};
export const initialState: IReducerFinancialCalculation = {
  CalculationInProgress: false,
  RatesVisibility: {
    aprExclComm: true,
    aprInclComm: true,
    flatRateExclComm: true,
    flatRateInclComm: true,
    grossYield: true,
    netYield: true,
  },
  RentalCalculationInProgress: false,
  CalculatedCommission: 0,
  GrossProfit: 0,
  SliderOpen: false,
  CalculatedNFA: 0,
  Notification: {
    message: "",
    visible: false,
  },
  RequestObj: {
    AssetCost: 0,
    AdvancePayments: 0,
    RegularPayments: 0,
    StartDate: new Date().toISOString(),
    PaymentMode: "Arrear",
    FinanceType: FinanceType.HirePurchase,
    Frequency: "Monthly",
    Rate: 0,
    Deposit: 0.0,
    ExtensionDays: 0,
    ResidualValue: 0,
    BalloonPayment: 0,
    fundingCost: 0,
    BalloonCollection: BalloonCollection.OneMonthAfterLastPayment,
    StructurePayment: false,
    StructureRentals: [],
    PaymentRentals: [
      {
        StartTerm: 1,
        EndTerm: 1,
        Amount: "0.0",
        RentalId: 1,
        RentalType: "Structured",
        RentalFrequency: "",
      },
    ],
    VAT: {
      PaymentNumber: 3,
      Amount: 0.0,
      Treatment: vatTreatment.VATDeferral,
    },
    CommissionAmount: 0.0,
    CommissionPercentage: 0.0,
    checkCommission: false,
    Fee: [
      {
        FeeId: FeeType.DocFee,
        Amount: 0,
        FirstDueDate: new Date().toString(),
        Frequency: "OneTime",
        Name: FeeType.DocFee,
        Occurance: "OnSpecificDate",
        PaymentNumber: 0,
      },
      {
        FeeId: FeeType.AnnualAdminFee,
        Amount: 0,
        FirstDueDate: new Date().toString(),
        Frequency: "Annual",
        Name: FeeType.AnnualAdminFee,
        Occurance: "",
        PaymentNumber: 0,
      },
      {
        FeeId: FeeType.OTPFee,
        Amount: 0,
        FirstDueDate: new Date().toString(),
        Frequency: "OneTime",
        Name: FeeType.OTPFee,
        Occurance: "OnSpecificDate",
        PaymentNumber: 0,
      },
    ],
  },
  ResponseObj: {
    rentals: [],
    repaymentPlan: [],
    repaymentPlanWithFee: [],
    rates: {
      grossYield: 0.0,
      netYield: 0.0,
      aprExclCommission: 0.0,
      aprInclCommission: 0.0,
      flatRateExclCommission: 0.0,
      flatRateInclCommission: 0.0,
    },
  },
};
export function reducerFinancialCalculation(
  currentState = initialState,
  action: any
): IReducerFinancialCalculation {
  switch (action.type) {
    case ActionTypes.SET_FINANCIAL_REQUEST_DATA: {
      return {
        ...currentState,
        RequestObj: action.payload,
      };
    }
    case ActionTypes.SET_FINANCIAL_RESPONSE_DATA: {
      return {
        ...currentState,
        ResponseObj: action.payload,
      };
    }
    case ActionTypes.SET_NOTIFICATION: {
      return {
        ...currentState,
        Notification: action.payload,
      };
    }
    case ActionTypes.SET_RENTAL_CALCULATION_PROGRESS: {
      return {
        ...currentState,
        RentalCalculationInProgress: action.payload,
      };
    }
    case ActionTypes.SET_RATES_VISIBILITY: {
      return {
        ...currentState,
        RatesVisibility: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATED_COMMISSION: {
      return {
        ...currentState,
        CalculatedCommission: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATED_NFA: {
      return {
        ...currentState,
        CalculatedNFA: action.payload,
      };
    }
    case ActionTypes.SET_SLIDER_OPEN: {
      return {
        ...currentState,
        SliderOpen: action.payload,
      };
    }
    case ActionTypes.SET_CALCULATION_IN_PROGRESS: {
      return {
        ...currentState,
        CalculationInProgress: action.payload,
      };
    }
    case ActionTypes.SET_RESET_VALUES: {
      return action.payload;
    }
    case ActionTypes.SET_GROSS_PROFIT: {
      return {
        ...currentState,
        GrossProfit: action.payload,
      };
    }
    default:
      return currentState;
  }
}
