import React from "react";
import { Table } from "../../controls/Table/Table";
interface IAmortizationDetail {
  rows: any;
  columns: any;
}

const AmortizationDetail: React.FC<IAmortizationDetail> = ({
  rows,
  columns,
}: IAmortizationDetail) => {
  if (rows.length > 0) {
    return (
      <div>
        <Table columns={columns} rows={rows} />
      </div>
    );
  }
};
export default AmortizationDetail;
