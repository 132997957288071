import {
  Box,
  Button,
  Grid,
  unityTheme,
  IBoxProps,
  Table,
  ScrollableTabs,
} from "@ntpkunity/controls";
import { DatePicker } from "../../controls/DateHelper/DatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAmortizationComponentName,
  setAmortizationContractTerms,
  setAmortizationEndDate,
  setAmortizationMethod,
  setAmortizationStartDate,
  setAmortizationTaxRate,
  setAmountToAmortize,
} from "../../../calc-store/actions/actionsAmortizationCalculation";
import { RootState } from "../../../calc-store/reducers";
import { amortizationMethods } from "../../../common/constants";
import {
  CalculateActualNoOfDaysAmortization,
  CalculateStraightLineAmortization,
  CalculateStraightLineTaxRata,
  CalculateAnnuityStraightLine,
} from "../../../helpers/ServiceHelper";
import { Dropdown, TextBox } from "../../controls";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import AmortizationDetail from "./AmortizationDetail";
import { ValidateAmortizationPlan } from "../../../validations/ValidateAmortizationPlan";
import { ValidateAnnuityStraightLine } from "../../../validations/ValidateAnnuityStraightLine";
import { styled } from "@mui/material/styles";
import { AnnuityStraightLine } from "./AnnuityStraightLine/AnnuityStraightLine";
import { AmortizationMethods } from "../../../common/enums";
import CalculationHeader from "../../controls/CalculationHeader";
import { setStartDate } from "../../../calc-store/actions/actionAnnuityStraightLine";
import { LayoutWithSideNavComponent } from "../../../modules";
import DateHelper from "../../controls/DateHelper/DateHelper";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

export const AmortizationPlan = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersAmortizationCalculation
  );
  const storeStateAnnuityStraightLine = useSelector(
    (state: RootState) => state.reducerAnnuityStraightLineCalculation
  );
  const [tab, setTab] = useState([]);
  var dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const [amortizationResponse, setAmortizationResponse] = useState([]);
  const [responseColumns, setResponseColumns] = useState([]);

  useEffect(() => {
    dispatch(setStartDate(storeState.startDate));
  }, [storeState.startDate]);

  const getEndDate = () => {
    if (
      storeState.amortizationCalcMethod == AmortizationMethods.ActualNoOfDays ||
      storeState.amortizationCalcMethod ==
        AmortizationMethods.StraightLineProRata
    )
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <DatePicker
            label="End Date"
            testId="txtEndDate"
            value={storeState.endDate}
            onChange={(e: Date) => dispatch(setAmortizationEndDate(e))}
          />
        </Grid>
      );
    else null;
  };
  const getAnnuityStraightLine = () => {
    if (
      storeState.amortizationCalcMethod ==
      AmortizationMethods.AnnuityActualNoOfDays
    ) {
      return <AnnuityStraightLine />;
    } else null;
  };
  const getAnnuityStraightLineResponse = () => {
    if (
      storeState.amortizationCalcMethod ==
      AmortizationMethods.AnnuityActualNoOfDays
    )
      return (
        <Box theme={unityTheme}>
          <ScrollableTabs theme={unityTheme} items={tab} defaultTabIndex={0} />
        </Box>
      );
    else null;
  };
  const getResponse = () => {
    if (
      storeState.amortizationCalcMethod == AmortizationMethods.StraightLine ||
      storeState.amortizationCalcMethod == AmortizationMethods.ActualNoOfDays ||
      storeState.amortizationCalcMethod ==
        AmortizationMethods.StraightLineProRata
    )
      return (
        <Box theme={unityTheme}>
          <Grid theme={unityTheme} container spacing={3} mb={2}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="txtAmortizationDetail"
            >
              <AmortizationDetail
                columns={responseColumns}
                rows={amortizationResponse}
              />
            </Grid>
          </Grid>
        </Box>
      );
    else null;
  };
  const getContractTerms = () => {
    if (
      storeState.amortizationCalcMethod == AmortizationMethods.StraightLine ||
      storeState.amortizationCalcMethod ==
        AmortizationMethods.StraightLineProRata
    )
      return (
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtTerms"
        >
          <TextBox
            id="txtTerms"
            label="Contract Duration in Months"
            value={storeState.contractTerms}
            type="text"
            helperText="Contract Duration in Months."
            masking
            numeric
            scale={0}
            thousandSeparator={false}
            onChange={(e) => {
              dispatch(setAmortizationContractTerms(e));
            }}
          />
        </Grid>
      );
    else null;
  };
  const getComponentName = () => {
    if (
      storeState.amortizationCalcMethod ==
      AmortizationMethods.StraightLineProRata
    )
      return (
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtComponentName"
        >
          <TextBox
            label="Component Name"
            type="text"
            helperText="Component Name."
            onChange={(e) => {
              dispatch(setAmortizationComponentName(e));
            }}
            value={storeState.componentName}
          />
        </Grid>
      );
    else null;
  };
  const getAmountToBeAmortized = () => {
    if (
      storeState.amortizationCalcMethod == AmortizationMethods.StraightLine ||
      storeState.amortizationCalcMethod == AmortizationMethods.ActualNoOfDays ||
      storeState.amortizationCalcMethod ==
        AmortizationMethods.StraightLineProRata
    )
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid="txtAmountToAmortize"
            id="txtAmountAmortize"
            label="Amount to Amortize"
            value={storeState.amountToAmortize}
            type="text"
            helperText="Total Amount to Amortize."
            onChange={(e) => {
              dispatch(setAmountToAmortize(e));
            }}
            currency
            scale={2}
            masking
          />
        </Grid>
      );
    else null;
  };
  const getTaxRate = () => {
    if (
      storeState.amortizationCalcMethod ==
      AmortizationMethods.StraightLineProRata
    )
      return (
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtTaxRate"
        >
          <TextBox
            testid="txtTaxRate"
            label="Tax Rate"
            value={storeState.taxRate}
            type="number"
            helperText="Tax Rate."
            onChange={(e) => {
              dispatch(setAmortizationTaxRate(e));
            }}
          />
        </Grid>
      );
    else null;
  };
  const generateAmortizationPlan = () => {
    var validate = ValidateAmortizationPlan(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      switch (storeState.amortizationCalcMethod) {
        case AmortizationMethods.StraightLine: {
          setCalculateEnabled(false);
          const columns = [] as any;
          columns.push("Rental No");
          columns.push("From Date");
          columns.push("Posting Date");
          columns.push("Amount");
          setResponseColumns(columns);
          CalculateStraightLineAmortization(storeState)
            .then((res) => {
              setCalculateEnabled(false);
              var pAmortizationResponse = [];
              res.map((r) => {
                // r.postingDate = r.postingDate.split("T")[0];
                // r.fromDate = r.fromDate.split("T")[0];
                pAmortizationResponse.push({
                  content: [
                    r.rentalNumber,
                    DateHelper(r.fromDate),
                    DateHelper(r.postingDate),
                    <FormattedValue value={r.amount.toString()} />,
                  ],
                });
              });
              setAmortizationResponse(pAmortizationResponse);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
        case AmortizationMethods.ActualNoOfDays: {
          setCalculateEnabled(false);
          const columns = [] as any;
          columns.push("Sr No");
          columns.push("From Date");
          columns.push("Posting Date");
          columns.push("Amount");
          columns.push("No. Of Days");
          setResponseColumns(columns);
          CalculateActualNoOfDaysAmortization(storeState)
            .then((res) => {
              var pAmortizationResponse = [];
              res.map((r) => {
                // r.postingDate = r.postingDate.split("T")[0];
                // r.fromDate = r.fromDate.split("T")[0];
                pAmortizationResponse.push({
                  content: [
                    r.serialNumber,
                    DateHelper(r.fromDate),
                    DateHelper(r.postingDate),
                    <FormattedValue value={r.amortizedAmount.toString()} />,
                    r.noOfDays,
                  ],
                });
              });
              setAmortizationResponse(pAmortizationResponse);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
        case AmortizationMethods.StraightLineProRata: {
          const columns = [] as any;
          columns.push("Sr No");
          columns.push("From Date");
          columns.push("To Date");
          columns.push("Posting Date");
          columns.push("Amount");
          columns.push("No. Of Days");
          columns.push("Vat Amount");
          columns.push("Balance Amount");
          setResponseColumns(columns);
          CalculateStraightLineTaxRata(storeState)
            .then((res) => {
              var pAmortizationResponse = [];
              res.map((r) => {
                // r.postingDate = r.postingDate.split("T")[0];
                // r.from = r.from.split("T")[0];
                // r.to = r.to.split("T")[0];
                pAmortizationResponse.push({
                  content: [
                    r.serialNumber,
                    DateHelper(r.from),
                    DateHelper(r.to),
                    DateHelper(r.postingDate),
                    <FormattedValue value={r.amortizedAmout.toString()} />,
                    r.numberOfDays,
                    <FormattedValue value={r.vatAmount.toString()} />,
                    <FormattedValue value={r.balanceAmount.toString()} />,
                  ],
                });
              });
              setAmortizationResponse(pAmortizationResponse);
              setCalculateEnabled(true);
            })
            .catch((ex) => {
              setCalculateEnabled(true);
              setErrorDialog({ visible: true, error: ex.response.data });
            });
          return;
        }
        case AmortizationMethods.AnnuityActualNoOfDays: {
          var validate = ValidateAnnuityStraightLine(
            storeStateAnnuityStraightLine
          );
          if (validate.visible) {
            setErrorDialog(validate);
          } else {
            setCalculateEnabled(false);
            const columns = [] as any;
            columns.push("Sr No");
            columns.push("Rental Number");
            columns.push("From Date");
            columns.push("To Date");
            columns.push("Posting Date");
            columns.push("Amount");
            columns.push("numberOfDays");
            const tabs = [];
            setTab(tabs);
            CalculateAnnuityStraightLine(storeStateAnnuityStraightLine)
              .then((res) => {
                res.forEach((r) => {
                  const row = [];
                  r.amortizations.map((rrr) => {
                    // rrr.postingDate = rrr.postingDate.split("T")[0];
                    // rrr.from = rrr.from.split("T")[0];
                    // rrr.to = rrr.to.split("T")[0];
                    row.push({
                      content: [
                        rrr.serialNumber,
                        rrr.rentalNumber,
                        DateHelper(rrr.from),
                        DateHelper(rrr.to),
                        DateHelper(rrr.postingDate),
                        <FormattedValue
                          value={rrr.amortizedAmout.toString()}
                        />,
                        rrr.numberOfDays,
                      ],
                    });
                  });
                  tabs.push({
                    title: r.componentName,
                    content: (
                      <div>
                        <Table
                          theme={unityTheme}
                          columns={columns}
                          rows={row}
                        />
                      </div>
                    ),
                  });
                });
                setTab(tabs);
                setCalculateEnabled(true);

                return;
              })
              .catch((ex) => {
                setCalculateEnabled(true);
                setErrorDialog({ visible: true, error: ex.response.data });
              });
          }
        }
      }
    }
  };
  return (
    <div>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <LayoutWithSideNavComponent theme={unityTheme}>
        <MainContent theme={unityTheme} className="main-content">
          <CalculationHeader text={"Amortization / Accounting Postings"} />
          <Box theme={unityTheme} mb={4}>
            <Grid theme={unityTheme} container spacing={4}>
              <Grid theme={unityTheme} item xs={12} sm={6} md={4} lg={4}>
                <Dropdown
                  items={amortizationMethods}
                  label="Amortization Method"
                  onChange={(e) => {
                    dispatch(setAmortizationMethod(e.target.value));
                    setAmortizationResponse([]);
                  }}
                  value={storeState.amortizationCalcMethod}
                  helperText="Select the Amortization method."
                  testid="cbxAmortizationMethod"
                />
              </Grid>
            </Grid>
          </Box>
          <Box theme={unityTheme} mb={3}>
            <Grid theme={unityTheme} container spacing={3}>
              {getAmountToBeAmortized()}
              {getContractTerms()}
              <Grid
                theme={unityTheme}
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                data-testid="txtStartDate"
              >
                <DatePicker
                  label="Start Date"
                  testId="txtEndDate"
                  value={storeState.startDate}
                  onChange={(e: Date) => dispatch(setAmortizationStartDate(e))}
                />
              </Grid>
              {getComponentName()}
              {getEndDate()}
            </Grid>
          </Box>
          {getAnnuityStraightLine()}
          <Grid theme={unityTheme} container spacing={3} mb={3}>
            {getTaxRate()}
          </Grid>
          <Grid theme={unityTheme} container spacing={3} mb={2}>
            <Grid theme={unityTheme} item xs={12}>
              <Button
                className="btn-w-100"
                data-testid="txtCalculate"
                theme={unityTheme}
                onClick={(e) => generateAmortizationPlan()}
                primary
                disabled={!calculateEnabled}
                text="Calculate"
              />
            </Grid>
          </Grid>
        </MainContent>
        {getAnnuityStraightLineResponse()}
        {getResponse()}
      </LayoutWithSideNavComponent>
    </div>
  );
};
