import {
  Box,
  Checkbox,
  Grid,
  Typography,
  unityTheme,
  IBoxProps,
} from "@ntpkunity/controls";

import { Dropdown, Radiobutton, TextBox } from "../../controls";
import { useDispatch } from "react-redux";

import { StructureRental } from "./StructureRental";
import {
  rentalFrequencies,
  rentalModes,
  yesNoValues,
} from "../../../common/constants";
import {
  setAPR,
  setContractTerms,
  setFinancedAmount,
  setRentalFrequency,
  setRentalMode,
  setResidualValue,
  setIsIncomePosting,
  SetAmortizationByAnnuityResponse,
  resetRepaymentPlan,
  setAmortizationResponse,
  setRepaymentApiResponse,
  SetExtensionDays,
} from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { StartDate } from "./StartDate";
import { CalculateRepaymentPlan } from "./CalculateRepaymentPlan";
import { RepaymentPlanDetail } from "./RepaymentPlanDetail";
import { AmortizationResponse } from "./AmortizationResponse";

import { IReducerRepaymentPlanCalculation } from "../../../calc-store/reducers/reducersRepaymentPlanCalculation";
import { styled } from "@mui/material/styles";
import { RepaymentTax } from "./RepaymentTax";
import { AmortizationWithTaxResponseDetail } from "./AmortizationWithTaxResponseDetail";
import { useState } from "react";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));
interface IFinancialCriteria {
  tabType: string;
  storeState: IReducerRepaymentPlanCalculation;
}
const FinancialCriteria: React.FC<IFinancialCriteria> = ({
  storeState,
}: IFinancialCriteria) => {
  const dispatch = useDispatch();
  const [taxVisible, setTaxVisible] = useState(false);
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <MainContent theme={unityTheme} className="main-content">
        <CalculationHeader text={"Repayment Plan"} />
        <Box theme={unityTheme} mb={4}>
          <StartDate />
        </Box>
        <Box theme={unityTheme} mb={3}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalModes}
                label="Payment Mode"
                onChange={(e) => {
                  dispatch(setRentalMode(e.target.value));
                }}
                value={storeState.rentalMode}
                helperText="If payment to be received at the beginning of the period or end of the period."
                testid="cbxFinancialCriteriaRentalMode"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalFrequencies}
                label="Payment Frequency"
                onChange={(e) => {
                  dispatch(setRentalFrequency(e.target.value));
                }}
                value={storeState.rentalFrequency}
                helperText="The frequency of installment  amount to be paid.."
                testid="cbxFinancialCriteriaRentalFrequency"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtTerms"}
                id="txtTerms"
                label="Contract Duration in Months."
                value={storeState.contractTerms}
                type="text"
                helperText="Total number of contract terms (month)."
                masking
                numeric
                scale={0}
                thousandSeparator={false}
                onChange={(e) => {
                  dispatch(setContractTerms(e));
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtAPR"}
                id="txtAPR"
                label="Interest Rate"
                value={storeState.APR}
                type="text"
                helperText="Interest Rate."
                onChange={(e) => {
                  dispatch(setAPR(e));
                }}
                endAdornment="%"
                masking
                scale={4}
                numeric
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtFinancedAmount"}
                id="txtFinancedAmount"
                label="Lending Amount"
                value={storeState.financedAmount}
                type="text"
                helperText="Final lending amount."
                onChange={(e) => {
                  dispatch(setFinancedAmount(e));
                }}
                masking
                currency
                scale={2}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtResidualValue"}
                id="txtResidualValue"
                label="Residual Value"
                value={storeState.residualValue}
                type="text"
                helperText="Residual Value of the asset paid at the end of the contract."
                onChange={(e) => {
                  dispatch(setResidualValue(e));
                }}
                masking
                currency
                scale={2}
                optional
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"ExtensionDays"}
                id="ExtensionDays"
                label="Extension Days"
                value={storeState.extensionDays}
                type="number"
                onChange={(e) => {
                  dispatch(SetExtensionDays(e));
                }}
                helperText="Extension Days."
                optional
              />
            </Grid>
          </Grid>
        </Box>

        <Box theme={unityTheme} mb={4}>
          <Grid theme={unityTheme} container spacing={3}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="txtRepaymentPlanDetail"
            >
              <Box theme={unityTheme} marginBottom={1}>
                <div>
                  <Typography component="h4" variant="h4" theme={unityTheme}>
                    Would you like to add tax?
                  </Typography>
                </div>
              </Box>

              <Box theme={unityTheme} marginBottom={1}>
                <Radiobutton
                  options={yesNoValues}
                  onChange={(e) => {
                    dispatch(setAmortizationResponse([]));
                    dispatch(setRepaymentApiResponse([]));
                    if (e == "true") {
                      dispatch(resetRepaymentPlan(e));
                      setTaxVisible(true);
                    } else {
                      dispatch(resetRepaymentPlan(e));
                      setTaxVisible(false);
                    }
                  }}
                  value={storeState.repaymentStructure}
                  defaultValue={storeState.repaymentStructure}
                />
              </Box>
              <RepaymentTax visible={taxVisible} />
              <Box theme={unityTheme} marginBottom={1}>
                <div>
                  <Typography component="h4" variant="h4" theme={unityTheme}>
                    Would you like to generate Income Posting?
                  </Typography>
                </div>
              </Box>
              <Radiobutton
                options={yesNoValues}
                onChange={(e) => {
                  if (e == "true") {
                    dispatch(setIsIncomePosting(e));
                  } else {
                    dispatch(SetAmortizationByAnnuityResponse([]));
                    dispatch(setIsIncomePosting(e));
                  }
                }}
                defaultValue={String(storeState.isIncomePosting)}
                value={String(storeState.isIncomePosting)}
              />
            </Grid>
          </Grid>
        </Box>
        <StructureRental />
        <CalculateRepaymentPlan storeState={storeState} />

        <Box theme={unityTheme}>
          <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="txtRepaymentPlanDetail"
            >
              <RepaymentPlanDetail rows={storeState.repaymentPlanResponse} />
            </Grid>
          </Grid>
        </Box>
        <Box theme={unityTheme}>
          <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="AmortizationWithTaxResponseDetail"
            >
              <AmortizationWithTaxResponseDetail
                rows={storeState.setRepaymentApiResponse}
              />
            </Grid>
          </Grid>
        </Box>
        <Box theme={unityTheme}>
          <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
            <Grid
              theme={unityTheme}
              item
              xs={12}
              data-testid="txtAnnunityResponse"
            >
              <AmortizationResponse rows={storeState.repaymentAmortization} />
            </Grid>
          </Grid>
        </Box>
      </MainContent>
    </LayoutWithSideNavComponent>
  );
};

export default FinancialCriteria;
