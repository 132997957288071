import { Box, Button, Icon, Typography, unityTheme } from "@ntpkunity/controls";
import { LayoutWithSideNavComponent } from "../../modules";

export const ComingSoon = () => {
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <Box
        theme={unityTheme}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100%"}
      >
        <Typography
          theme={unityTheme}
          component="p"
          className="fw-sb"
          variant="subtitle1"
          mb={4}
        >
          Please subscribe to access this feature.
        </Typography>
        <Button theme={unityTheme} primary text="Subscribe"></Button>
      </Box>
    </LayoutWithSideNavComponent>
  );
};
