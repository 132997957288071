import ActionTypes from "../actionTypes";
import { IAffordabilityProps } from "../reducers/reducersAffordabilityChecks";
export function setReverseFinancedAmount(parm: IAffordabilityProps) {
  return {
    type: ActionTypes.SET_REVERSE_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setReverseDownPayment(parm: IAffordabilityProps) {
  return {
    type: ActionTypes.SET_REVERSE_DOWN_PAYMENT,
    payload: parm,
  };
}
