import { IETQuote } from "../interfaces/earlyTermination";
export const ValidateEarlyTermination = (state: IETQuote) => {
  if (state.ETMethod == "" || state.ETMethod == undefined) {
    return {
      visible: true,
      error: "Please select ET Calculation Method.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
