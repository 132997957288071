import ActionTypes from "../actionTypes";
export function setAPRFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_APR_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setAPRRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_APR_RENTAL_MODE,
    payload: parm,
  };
}
export function setAPRRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_APR_RENTAL_FREQUENCY,
    payload: parm,
  };
}
export function setAPRResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_APR_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setAPRRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_APR_RENTAL_STRUCTURES,
    payload: parm,
  };
}
export function setAPRResponse(parm: string) {
  return {
    type: ActionTypes.SET_APR_RESPONSE,
    payload: parm,
  };
}
