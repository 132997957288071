import {
  Box,
  Button,
  Grid,
  Icon,
  ISelect,
  unityTheme,
} from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setSubsidyRentalSlabs } from "../../../calc-store/actions/actionsSubsidyCalculation";
import { RootState } from "../../../calc-store/reducers";
import ISubsidyRentalSlabs from "../../../interfaces/ISubsidyRentalSlabs";
import { TextBox } from "../../controls";
import { Divider } from "../../controls/Divider/Divider";
import { SubSectionFieldsWrap } from "./../../../shared/theme/structureRentalStyle";

const GetSubsidySlabs = (
  subsidySlabs: ISubsidyRentalSlabs[] = [],
  deleteFunction,
  valuesChange
) => {
  return subsidySlabs.map((structure) => (
    <SubSectionFieldsWrap
      key={structure.rentalId}
      theme={unityTheme}
      className="sub-fields-wrap"
    >
      <Box theme={unityTheme} className="sub-sections-fields">
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtTermFrom"}
              id="txtTermFrom"
              key={"TermFrom" + structure.rentalId}
              label="Term From"
              type="number"
              value={structure.startTerm}
              onChange={(e) => valuesChange("TermFrom", e, structure.rentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtTermTo"}
              id="txtTermTo"
              key={"TermTo" + structure.rentalId}
              label="Term To"
              type="number"
              value={structure.endTerm}
              onChange={(e) => valuesChange("TermTo", e, structure.rentalId)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={4}>
            <TextBox
              testid={"txtAmount"}
              id="txtAmount"
              key={"Amount" + structure.rentalId}
              label="Amount"
              type="text"
              value={structure.amount}
              onChange={(e) => valuesChange("Amount", e, structure.rentalId)}
              masking
              currency
            />
          </Grid>
        </Grid>
      </Box>
      <Box theme={unityTheme} className="sub-section-action">
        <Button
          data-testid="btnDeleteRental"
          theme={unityTheme}
          key={"btnDelete" + structure.rentalId}
          onClick={() => deleteFunction(structure.rentalId)}
          secondary
          iconText={<Icon name="CloseBlack" />}
        />
      </Box>
    </SubSectionFieldsWrap>
  ));
};

export const RentalSlabs = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersSubsidyCalculation
  );
  const dispatch = useDispatch();

  const addRental = () => {
    var structures = storeState.rentalSlabs;
    var count = structures.length;
    var newRental = {
      rentalId: parseFloat(count) + 1,
      amount: 0.0,
      startTerm: count > 0 ? parseFloat(structures[count - 1].endTerm) + 1 : 1,
      endTerm: count > 0 ? parseFloat(structures[count - 1].endTerm) + 1 : 1,
    };
    structures.push(newRental);
    dispatch(setSubsidyRentalSlabs(structures));
  };

  const deleteRental = (rentalId: any) => {
    var structures = storeState.rentalSlabs;
    var indexToDel = structures.findIndex((x) => x.rentalId == rentalId);
    structures.splice(indexToDel, indexToDel > -1 ? 1 : 0);
    dispatch(setSubsidyRentalSlabs(structures));
  };

  const valuesChange = (changeType: string, newValue: any, rentalId: any) => {
    var structures = storeState.rentalSlabs;
    var indexToEdit = structures.findIndex((x) => x.rentalId == rentalId);
    if (indexToEdit > -1) {
      var value = structures[indexToEdit];
      if (changeType == "TermFrom") value.startTerm = newValue;
      else if (changeType == "TermTo") value.endTerm = newValue;
      else if (changeType == "Amount") value.amount = newValue;
    }
    dispatch(setSubsidyRentalSlabs(structures));
  };
  return (
    <Box theme={unityTheme} className="sub-section" mt={4} mb={4} pt={4} pb={4}>
      {GetSubsidySlabs(storeState.rentalSlabs, deleteRental, valuesChange)}
      <Grid theme={unityTheme} container mt={4}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="btnAddRentalSlab"
            theme={unityTheme}
            secondary
            text="Add Rental Slab"
            onClick={() => addRental()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
