import * as React from "react";
import { Select, ISelect, unityTheme, Tooltip } from "@ntpkunity/controls";
import { flexTheme } from "../../../shared";

const Dropdown = ({
  items,
  label,
  helperText = "",
  onChange,
  value = "",
  disabled = false,
  testid = "",
}) => {
  return (
    <Tooltip theme={unityTheme} title={helperText} placement={"right-end"}>
      <Select
        label={label}
        items={items}
        //helperText={helperText}
        onChange={onChange}
        value={value}
        theme={flexTheme}
        disabled={disabled}
        fullWidth
        sxProps={{ m: 0, width: "100%" }}
        testid={testid}
      />
    </Tooltip>
  );
};

export default Dropdown;
