import ActionTypes from "../actionTypes";

export function setAddTax(parm: boolean) {
  return {
    type: ActionTypes.SET_ADD_TAX,
    payload: parm,
  };
}
export function setRentalCalculationMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_RP_RENTAL_CALC_METHOD,
    payload: parm,
  };
}

export function setRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_RP_RENTAL_MODE,
    payload: parm,
  };
}

export function setRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_RP_RENTAL_FREQ,
    payload: parm,
  };
}

export function setRentalStructure(parm: string = "false") {
  return {
    type: ActionTypes.SET_RP_RENTAL_STRUCTURE,
    payload: parm,
  };
}
export function setRepaymentplan(parm: string = "false") {
  return {
    type: ActionTypes.SET_RENTAL_STRUCTURE,
    payload: parm,
  };
}

export function setRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_RP_RENTAL_STRUCTURES,
    payload: parm,
  };
}

export function setContractTerms(parm: number = 12) {
  return {
    type: ActionTypes.SET_RP_CONTRACT_TERMS,
    payload: parm,
  };
}

export function setAPR(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RP_APR,
    payload: parm,
  };
}
export function setFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_RP_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RP_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setTaxRate(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RP_TAX,
    payload: parm,
  };
}
export function setInclusiveTax(parm: boolean) {
  return {
    type: ActionTypes.SET_RP_INCLUSIVE_TAX,
    payload: parm,
  };
}
export function setRentalComponent(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RP_RENTAL_COMPONENT,
    payload: parm,
  };
}
export function setRentalDetailResponse(parm: any) {
  return {
    type: ActionTypes.SET_RP_RENTAL_DETAIL_RESPONSE,
    payload: parm,
  };
}
export function setAmortizationResponse(parm: any) {
  return {
    type: ActionTypes.SET_RP_AMORTIZATION_RESPONSE,
    payload: parm,
  };
}
export function setRepaymentApiResponse(parm: any) {
  return {
    type: ActionTypes.SET_RP_API_RESPONSE,
    payload: parm,
  };
}
export function resetRepaymentPlan(parm: any) {
  return {
    type: ActionTypes.RESET_REPAYMENT_PLAN,
    payload: parm,
  };
}
export function resetStructureRentals() {
  return {
    type: ActionTypes.RESET_RP_STRUCTURE_RENTALS,
  };
}
export function setStartDate(parm: Date) {
  return {
    type: ActionTypes.SET_RP_START_DATE,
    payload: parm,
  };
}
export function disableRentalMode(parm: boolean) {
  return {
    type: ActionTypes.DISABLE_RP_RENTAL_MODE,
    payload: parm,
  };
}
export function setIsIncomePosting(parm: boolean) {
  return {
    type: ActionTypes.SET_RP_IS_INCOME_POSTING,
    payload: parm,
  };
}

export function SetAmortizationByAnnuityResponse(parm: any) {
  return {
    type: ActionTypes.Set_Amortization_By_Annuity_Response,
    payload: parm,
  };
}

export function SetExtensionDays(parm: any) {
  return {
    type: ActionTypes.Set_Extension_Days,
    payload: parm,
  };
}
