import { Box, Grid, unityTheme } from "@ntpkunity/controls";
import { useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { SubsidyTypes } from "../../../common/enums";
import { Outputvalue } from "../../controls/OutputValue/Outputvalue";

interface ISubsidyResponse {
  subsidyType: string;
}
export const SubsidyResponse = ({ subsidyType }: ISubsidyResponse) => {
  const storeState = useSelector(
    (state: RootState) => state.reducersSubsidyCalculation
  );
  const manufacturerSubsidy = () => {
    if (subsidyType == SubsidyTypes.Split)
      return (
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtManufactureSubsidy"
        >
          <Outputvalue
            value={storeState.manufacturerSubsidyAmount}
            prefix="$"
            helperText={
              storeState.manufacturerSubsidyAmount == ""
                ? ""
                : "Manufacturer Subsidy"
            }
          />
        </Grid>
      );
    else return <div></div>;
  };
  const dealerSubsidy = () => {
    if (subsidyType == SubsidyTypes.Split)
      return (
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtDealerSubsidy"
        >
          <Outputvalue
            value={storeState.dealerSubsidyAmount}
            prefix="$"
            helperText={
              storeState.dealerSubsidyAmount == "" ? "" : "Dealer Subsidy"
            }
          />
        </Grid>
      );
    else return <div></div>;
  };
  return (
    <Box theme={unityTheme}>
      <Grid theme={unityTheme} container spacing={3}>
        {manufacturerSubsidy()}
        {dealerSubsidy()}
        <Grid
          theme={unityTheme}
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          data-testid="txtSubsidyAmount"
        >
          <Outputvalue
            value={storeState.totalSubsidyAmount}
            prefix="$"
            helperText={
              storeState.totalSubsidyAmount == "" ? "" : "Subsidy Amount"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
