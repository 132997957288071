import ActionTypes from "../actionTypes";
export function setRentalCalculationMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_CALC_METHOD,
    payload: parm,
  };
}

export function setRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_MODE,
    payload: parm,
  };
}

export function setRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_FREQ,
    payload: parm,
  };
}

export function setRentalStructure(parm: string = "false") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURE,
    payload: parm,
  };
}

export function setRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURES,
    payload: parm,
  };
}
export function setContractTerms(parm: number = 12) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_CONTRACT_TERMS,
    payload: parm,
  };
}

export function setAPR(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_APR,
    payload: parm,
  };
}
export function setFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setRentalDetailResponse(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE,
    payload: parm,
  };
}
export function resetStructureRentals() {
  return {
    type: ActionTypes.RENTAL_PLAN_RESET_STRUCTURE_RENTALS,
  };
}
export function setStartDate(parm: Date) {
  return {
    type: ActionTypes.RENTAL_PLAN_START_DATE,
    payload: parm,
  };
}
export function setRepaymentPlanDetail(parm: any) {
  return {
    type: ActionTypes.SET_REPAYMENT_PLAN_DETAIL,
    payload: parm,
  };
}
export function setRepaymentPlanCalculation(parm: any) {
  return {
    type: ActionTypes.SET_REPAYMENT_PLAN_CALCULATION,
    payload: parm,
  };
}
export function disableRentalMode(parm: any) {
  return {
    type: ActionTypes.RENTAL_PLAN_DISABLE_RENTAL_MODE,
    payload: parm,
  };
}
export function setRepaymentPlanApiResponse(parm: any) {
  return {
    type: ActionTypes.SET_RP_API_RESPONSE,
    payload: parm,
  };
}

export function setFinancialExtensionDays(parm: any) {
  return {
    type: ActionTypes.SET_FINANCIAL_EXTENSION_DAYS,
    payload: parm,
  };
}

export function setIncomePosting(parm: any) {
  return {
    type: ActionTypes.SET_INCOME_POSTING,
    payload: parm,
  };
}

export function setIncomePostingResponse(parm: any) {
  return {
    type: ActionTypes.SET_INCOME_POSTING_RESPONSE,
    payload: parm,
  };
}
