export const enum ActionTypes {
  SET_CONTRACT_INTEREST_RATE = "SET_CONTRACT_INTEREST_RATE",
  SET_OVER_DUE_INTEREST_RATE = "SET_OVER_DUE_INTEREST_RATE",
  SET_ET_QUOTE_GENERATION_DATE = "SET_ET_QUOTE_GENERATION_DATE",
  SET_CLIENT_ET_DATE = "SET_CLIENT_ET_DATE",
  SET_ET_PENALTY_METHOD = "SET_ET_PENALTY_METHOD",
  SET_ET_PENALTY_REFERENCE_AMOUNT_FOR_PERCENTAGE = "SET_ET_PENALTY_REFERENCE_AMOUNT_FOR_PERCENTAGE",
  SET_ET_PENALTY_AMOUNT = "SET_ET_PENALTY_AMOUNT",
  SET_NO_OF_DAYS = "SET_NO_OF_DAYS",
  SET_RECEIVABLES = "SET_RECEIVABLES",
  SET_PAYABLES = "SET_PAYABLES",
  SET_TAXES = "SET_TAXES",
  SET_RENTAL_DETAILS = "SET_RENTAL_DETAILS",
  SET_ET_PENALTY_PERCENTAGE = "SET_ET_PENALTY_PERCENTAGE",
  SET_ET_METHOD = "SET_ET_METHOD",
  SET_ASSET_PRICE = "SET_ASSET_PRICE",
  SET_TOTAL_OVERDUE_AMOUNT = "SET_TOTAL_OVERDUE_AMOUNT",
  SET_COMP_RENTAL_DETAIL = "SET_COMP_RENTAL_DETAIL",
  SET_SUNDRY_CHARGES = "SET_SUNDRY_CHARGES",
  SET_ADDITIONAL_CHARGES = "SET_ADDITIONAL_CHARGES",
  SET_LATE_PAYMENT_CHARGES = "SET_LATE_PAYMENT_CHARGES",
  SET_SECURITY_DEPOSIT = "SET_SECURITY_DEPOSIT",
  SET_UNALLOCATED_AMOUNT = "SET_UNALLOCATED_AMOUNT",
  SET_PURCHASE_RETURN = "SET_PURCHASE_RETURN",
  SET_REPAYMENT_PLAN = "SET_REPAYMENT_PLAN",
  SET_COMPONENT_NAME = "SET_COMPONENT_NAME",
}
