import {
  Dialog,
  Icon,
  Typography,
  unityTheme,
  List,
  Button,
  Box,
} from "@ntpkunity/controls";
interface IMessageDialog {
  visible: boolean;
  message: any;
  onClose?(): void;
}
const MessageDialog = ({ visible, message, onClose }: IMessageDialog) => {
  const errorHeader = "Request failed!";
  const getErrors = (errors: any) => {
    if (errors != undefined && errors != null) {
      var rows = [];
      Object.keys(errors).map((element) => {
        var childElement = errors[element];
        if (
          typeof childElement === "object" &&
          Object.keys(childElement).length > 0
        ) {
          Object.keys(childElement).map((e) => {
            rows.push({
              label: [childElement[e]],
            });
          });
        } else {
          rows.push({
            label: [errors[element]],
          });
        }
      });
      return (
        <div>
          <List theme={unityTheme} options={rows} />
        </div>
      );
    }
    return <div></div>;
  };
  if (message != undefined && message != null) {
    if (message.title != undefined && message.title != null) {
      return (
        <Dialog
          theme={unityTheme}
          size="sm"
          onCloseDialog={() => {
            onClose?.();
          }}
          variant="confirmation"
          open={visible}
          customFooter={
            <Button
              primary
              text={"Got It"}
              onClick={() => {
                onClose?.();
              }}
            ></Button>
          }
        >
          <Box theme={unityTheme} className="dialog-icon">
            <Icon
              name="DialogCrossIcon"
              onClick={() => {
                onClose?.();
              }}
            />
          </Box>
          <Box theme={unityTheme}>
            <Typography
              theme={unityTheme}
              mb={2}
              className="content-title"
              variant="h2"
              component="h2"
            >
              {errorHeader}
            </Typography>
            <Typography theme={unityTheme} component="h3">
              {message.title != undefined && message.title != null
                ? message.title
                : message}
            </Typography>
            {getErrors(message.errors)}
          </Box>
        </Dialog>
      );
    } else if (message.Message != undefined && message.Message != null)
      return (
        <Dialog
          theme={unityTheme}
          size="sm"
          onCloseDialog={() => {
            onClose?.();
          }}
          variant="confirmation"
          open={visible}
          customFooter={
            <Button
              primary
              text={"Got It"}
              onClick={() => {
                onClose?.();
              }}
            ></Button>
          }
        >
          <Box theme={unityTheme} className="dialog-icon">
            <Icon
              name="DialogCrossIcon"
              onClick={() => {
                onClose?.();
              }}
            />
          </Box>
          <Box theme={unityTheme}>
            <Typography
              theme={unityTheme}
              mb={2}
              className="content-title"
              variant="h2"
              component="h2"
            >
              {errorHeader}
            </Typography>
            <Typography theme={unityTheme} component="h3">
              {getErrors({ error: message.Message })}
            </Typography>
          </Box>
        </Dialog>
      );
    else
      return (
        <Dialog
          theme={unityTheme}
          size="sm"
          onCloseDialog={() => {
            onClose?.();
          }}
          variant="confirmation"
          open={visible}
          customFooter={
            <Button
              primary
              text={"Got It"}
              onClick={() => {
                onClose?.();
              }}
            ></Button>
          }
        >
          <Box theme={unityTheme} className="dialog-icon">
            <Icon
              name="DialogCrossIcon"
              onClick={() => {
                onClose?.();
              }}
            />
          </Box>
          <Box theme={unityTheme}>
            <Typography
              theme={unityTheme}
              mb={2}
              className="content-title"
              variant="h2"
              component="h2"
            >
              {errorHeader}
            </Typography>
            <Typography theme={unityTheme} component="h3">
              {getErrors({ error: message })}
            </Typography>
          </Box>
        </Dialog>
      );
  } else
    return (
      <Dialog
        theme={unityTheme}
        size="sm"
        onCloseDialog={() => {
          onClose?.();
        }}
        variant="confirmation"
        open={visible}
        customFooter={
          <Button
            primary
            text={"Got It"}
            onClick={() => {
              onClose?.();
            }}
          ></Button>
        }
      >
        <Box theme={unityTheme} className="dialog-icon">
          <Icon
            name="DialogCrossIcon"
            onClick={() => {
              onClose?.();
            }}
          />
        </Box>
        <Box theme={unityTheme}>
          <Typography
            theme={unityTheme}
            mb={2}
            className="content-title"
            variant="h2"
            component="h2"
          >
            {errorHeader}
          </Typography>
          <Typography theme={unityTheme} component="h3">
            <Icon name="IcErrorFilled" /> There's been a problem. Please contact
            your administrator.
          </Typography>
        </Box>
      </Dialog>
    );
};

export default MessageDialog;
