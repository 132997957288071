import ActionTypes from "../actionTypes";
export function setRentalCalculationMethod(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_CALC_METHOD_APR,
    payload: parm,
  };
}

export function setRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_MODE_APR,
    payload: parm,
  };
}

export function setRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_FREQ_APR,
    payload: parm,
  };
}

export function setRentalStructure(parm: string = "false") {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURE_APR,
    payload: parm,
  };
}

export function setRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_STRUCTURES_APR,
    payload: parm,
  };
}
export function setContractTerms(parm: number = 12) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_CONTRACT_TERMS_APR,
    payload: parm,
  };
}

export function setAPR(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_APR_APR,
    payload: parm,
  };
}
export function setFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_FINANCED_AMOUNT_APR,
    payload: parm,
  };
}
export function setResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RESIDUAL_VALUE_APR,
    payload: parm,
  };
}
export function setRentalDetailResponse(parm: any) {
  return {
    type: ActionTypes.SET_RENTAL_PLAN_RENTAL_DETAIL_RESPONSE_APR,
    payload: parm,
  };
}
export function resetStructureRentals() {
  return {
    type: ActionTypes.RENTAL_PLAN_RESET_STRUCTURE_RENTALS_APR,
  };
}
export function setStartDate(parm: Date) {
  return {
    type: ActionTypes.RENTAL_PLAN_START_DATE_APR,
    payload: parm,
  };
}
export function setRepaymentPlanDetail(parm: any) {
  return {
    type: ActionTypes.SET_REPAYMENT_PLAN_DETAIL_APR,
    payload: parm,
  };
}
export function setRepaymentPlanCalculation(parm: any) {
  return {
    type: ActionTypes.SET_REPAYMENT_PLAN_CALCULATION_APR,
    payload: parm,
  };
}
export function disableRentalMode(parm: any) {
  return {
    type: ActionTypes.RENTAL_PLAN_DISABLE_RENTAL_MODE_APR,
    payload: parm,
  };
}
export function setRepaymentPlanApiResponse(parm: any) {
  return {
    type: ActionTypes.SET_RP_API_RESPONSE_APR,
    payload: parm,
  };
}

export function setDeferredTax(parm: boolean) {
  return {
    type: ActionTypes.SET_DEFERRED_TAX,
    payload: parm,
  };
}

export function setVatTreatment(parm: any) {
  return {
    type: ActionTypes.SET_VAT_TREATMENT,
    payload: parm,
  };
}

export function setVatAmount(parm: any) {
  return {
    type: ActionTypes.SET_VAT_AMOUNT,
    payload: parm,
  };
}

export function setVatPercentage(parm: any) {
  return {
    type: ActionTypes.SET_VAT_PERCENTAGE,
    payload: parm,
  };
}
