import { IReducerAprCalculation } from "../calc-store/reducers/reducersAprCalculation";
export const ValidateAprCalculation = (state: IReducerAprCalculation) => {
  if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending amount.",
    };
  } else if (
    state.structureRentalDetail == undefined ||
    state.structureRentalDetail == null ||
    state.structureRentalDetail.length <= 0
  ) {
    return {
      visible: true,
      error: "Please provide the rental details.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
