import { Grid, unityTheme, DuoTab, Box, IBoxProps } from "@ntpkunity/controls";
import ReverseDownPayment from "./reverseDownPayment";
import ReverseFinancedAmount from "./financedAmount";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";
import { styled } from "@mui/material/styles";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

interface IAffordabilityChecks {}
const AffordabilityChecks: React.FC<IAffordabilityChecks> = () => {
  const items = [
    {
      content: <ReverseFinancedAmount />,
      title: "How much can I borrow ?",
    },
    {
      content: <ReverseDownPayment />,
      title: "How much I need to pay upfront",
    },
  ];
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <MainContent theme={unityTheme} className="main-content">
        <CalculationHeader text={"Affordability Checks"} />
        <DuoTab theme={unityTheme} items={items} defaultTabIndex={0} />
      </MainContent>
    </LayoutWithSideNavComponent>
  );
};
export default AffordabilityChecks;
