import {
  Box,
  Button,
  Grid,
  Icon,
  Typography,
  unityTheme,
} from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setIncomeExpenses } from "../../../../calc-store/actions/actionAnnuityStraightLine";
import { RootState } from "../../../../calc-store/reducers";
import { yesNo } from "../../../../common/constants";
import IIncomeExpense from "../../../../interfaces/IIncomeExpense";
import { Dropdown, TextBox } from "../../../controls";
import { Divider } from "../../../controls/Divider/Divider";
import { SubSectionFieldsWrap } from "./../../../../shared/theme/structureRentalStyle";

const GetIncomeExpense = (
  incomeExpenses: IIncomeExpense[],
  deleteFunction,
  valuesChange
) => {
  return incomeExpenses.map((structure) => (
    <SubSectionFieldsWrap
      key={structure.Id}
      theme={unityTheme}
      className="sub-fields-wrap"
      mt={2}
    >
      <Box theme={unityTheme} className="sub-sections-fields">
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={4} md={3}>
            <TextBox
              label="Component Name"
              type="text"
              value={structure.ComponentName}
              onChange={(e) => valuesChange("ComponentName", e, structure.Id)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={4} md={3}>
            <TextBox
              label="Amount"
              type="text"
              value={structure.Amount}
              masking
              currency
              onChange={(e) => valuesChange("Amount", e, structure.Id)}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={4} md={3}>
            <Dropdown
              label="Is Income"
              items={yesNo}
              value={structure.IsIncome}
              onChange={(e) =>
                valuesChange("IsIncome", e.target.value, structure.Id)
              }
              testid="cbxAnnuityIsIncome"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} md={3}>
            <Dropdown
              label="Is Subsidy"
              items={yesNo}
              value={structure.IsSubsidy}
              onChange={(e) =>
                valuesChange("IsSubsidy", e.target.value, structure.Id)
              }
              testid="cbxAnnuityIsSubsidy"
            />
          </Grid>
        </Grid>
      </Box>
      <Box theme={unityTheme} className="sub-section-action">
        <Button
          theme={unityTheme}
          key={"btnDelete" + structure.Id}
          onClick={() => deleteFunction(structure.Id)}
          secondary
          variant="outlined"
          iconText={<Icon name="CloseBlack" />}
        />
      </Box>
    </SubSectionFieldsWrap>
  ));
};

export const AnnuityIncomeExpense = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerAnnuityStraightLineCalculation
  );
  const dispatch = useDispatch();

  const add = () => {
    var structures = storeState.IIncomeExpenseDetail;
    var count = structures.length;
    var newIncomeExpense: IIncomeExpense = {
      Id: count + 1,
      ComponentName: "",
      Amount: "0.0",
      IsIncome: "",
      IsSubsidy: "",
    };
    structures.push(newIncomeExpense);
    dispatch(setIncomeExpenses(structures));
  };

  const deleteIncomeExpense = (Id: any) => {
    var structures = storeState.IIncomeExpenseDetail;
    var indexToDel = structures.findIndex((x) => x.Id == Id);
    structures.splice(indexToDel, indexToDel > -1 ? 1 : 0);
    dispatch(setIncomeExpenses(structures));
  };

  const valuesChange = (changeType: string, newValue: any, Id: any) => {
    var structures = storeState.IIncomeExpenseDetail;
    var indexToEdit = structures.findIndex((x) => x.Id == Id);
    if (indexToEdit > -1) {
      var value = structures[indexToEdit];
      if (changeType == "ComponentName") value.ComponentName = newValue;
      else if (changeType == "Amount") value.Amount = newValue;
      else if (changeType == "IsIncome") value.IsIncome = newValue;
      else if (changeType == "IsSubsidy") value.IsSubsidy = newValue;
    }
    dispatch(setIncomeExpenses(structures));
  };

  if (storeState.IncomeExpense == "true") {
    return (
      <div>
        <Typography
          component="h4"
          variant="h4"
          theme={unityTheme}
          marginTop={2}
        >
          Income Expense:
        </Typography>
        <Box theme={unityTheme}>
          {GetIncomeExpense(
            storeState.IIncomeExpenseDetail,
            deleteIncomeExpense,
            valuesChange
          )}
          <Grid theme={unityTheme} container marginTop={3}>
            <Grid theme={unityTheme} item xs={12}>
              <Button
                className="btn-w-100"
                theme={unityTheme}
                secondary
                text="Add More"
                onClick={() => add()}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </div>
    );
  } else {
    return <div></div>;
  }
};
