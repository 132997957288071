import { Button, Grid, unityTheme, IBoxProps, Box } from "@ntpkunity/controls";
import { Dropdown, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { setReverseDownPayment } from "../../../calc-store/actions/actionsAffordabilityChecks";
import { rentalModes, rentalFrequencies } from "../../../common/constants";
import { useState } from "react";
import { CalculateReverseDownPayment } from "../../../helpers/ServiceHelper";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { Outputvalue } from "../../controls/OutputValue/Outputvalue";
import { styled } from "@mui/material/styles";
import { ValidateAffordabilityChecks } from "../../../validations/ValidateAffordabilityChecks";
import { Affordability } from "../../../common/enums";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content-wrap": {
    ".text-muted": {
      color: "#7F7F7F",
    },
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

interface IReverseDownPayment {}
const ReverseDownPayment: React.FC<IReverseDownPayment> = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersAffordabilityChecks
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const calculate = () => {
    var validate = ValidateAffordabilityChecks(
      storeState.reverseDownPayment,
      Affordability.DownPayment
    );
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      setCalculateEnabled(false);
      CalculateReverseDownPayment(storeState.reverseDownPayment)
        .then((res: any) => {
          dispatch(
            setReverseDownPayment({
              ...storeState.reverseDownPayment,
              response: parseFloat(res.downPayment.toString()).toFixed(4),
            })
          );
          setCalculateEnabled(true);
        })
        .catch((ex) => {
          setCalculateEnabled(true);
          setErrorDialog({ visible: true, error: ex.response.data });
        });
    }
  };
  return (
    <div>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <MainContent theme={unityTheme} className="main-content-wrap">
        <Box theme={unityTheme} mb={3} mt={2}>
          <Grid theme={unityTheme} container spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalModes}
                label="Payment Mode"
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      rentalMode: e.target.value,
                    })
                  );
                }}
                value={storeState.reverseDownPayment.rentalMode}
                helperText="If payment to be received at the beginning of the period or end of the period."
                testid="cbxAffRentalMode"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Dropdown
                items={rentalFrequencies}
                label="Payment Frequency"
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      rentalFrequency: e.target.value,
                    })
                  );
                }}
                value={storeState.reverseDownPayment.rentalFrequency}
                helperText="The frequency of installment  amount to be paid."
                testid="cbxAffRentalFrequency"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtAffContractTerms"}
                id="txtAffContractTerms"
                label="Contract Duration in Months."
                type="text"
                value={storeState.reverseDownPayment.contractTerms}
                helperText="Contract Duration in Months."
                masking
                numeric
                scale={0}
                thousandSeparator={false}
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      contractTerms: e,
                    })
                  );
                }}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid={"txtAFFFAPR"}
                id="txtAFFFAPR"
                label="Interest Rate"
                value={storeState.reverseDownPayment.APR}
                type="text"
                helperText="Interest Rate."
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      APR: e,
                    })
                  );
                }}
                currency
                scale={2}
                masking
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtAffResidualValue"
                id="txtAffResidualValue"
                label="Residual Value"
                value={storeState.reverseDownPayment.residualValue}
                type="text"
                helperText="Residual Value of the asset paid at the end of the contract."
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      residualValue: e,
                    })
                  );
                }}
                currency
                scale={2}
                masking
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtFinanceAmount"
                id="txtFinanceAmount"
                label="Lending Amount"
                value={storeState.reverseDownPayment.financedAmount}
                type="text"
                helperText="Final lending Amount."
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      financedAmount: e,
                    })
                  );
                }}
                currency
                scale={2}
                masking
              />
            </Grid>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <TextBox
                testid="txtRentalAmount"
                id="txtRentalAmount"
                label="Periodic Repayment Amount"
                value={storeState.reverseDownPayment.rentalAmount}
                type="text"
                helperText="Periodic Repayment Amount."
                onChange={(e) => {
                  dispatch(
                    setReverseDownPayment({
                      ...storeState.reverseDownPayment,
                      rentalAmount: e,
                    })
                  );
                }}
                currency
                scale={2}
                masking
              />
            </Grid>
          </Grid>
        </Box>
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Button
              className="btn-w-100"
              data-testid="btnCalculate"
              theme={unityTheme}
              disabled={!calculateEnabled}
              onClick={(e) => calculate()}
              primary
              text="Calculate"
            />
          </Grid>
        </Grid>
        <Grid theme={unityTheme} container spacing={3} mb={4} mt={1}>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="txtAFF"
          >
            <Outputvalue
              value={storeState.reverseDownPayment.response}
              helperText={
                storeState.reverseDownPayment.response == ""
                  ? ""
                  : "Upfront payment"
              }
            />
          </Grid>
        </Grid>
      </MainContent>
    </div>
  );
};
export default ReverseDownPayment;
