import ActionTypes from "../actionTypes";

export function setInception(param: any) {
  return {
    type: ActionTypes.SET_INCEPTION_FEE,
    payload: param,
  };
}
export function setMaturity(param: any) {
  return {
    type: ActionTypes.SET_MATURITY_FEE,
    payload: param,
  };
}
