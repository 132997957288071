import { Box, Button, Grid, unityTheme } from "@ntpkunity/controls";
import { AxiosError } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setAmortizationResponse,
  SetAmortizationByAnnuityResponse,
  setRepaymentApiResponse,
} from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { IReducerRepaymentPlanCalculation } from "../../../calc-store/reducers/reducersRepaymentPlanCalculation";
import {
  AmortizeByAnnuity,
  CreateRentalAmortization,
  CreateRentalAmortizationWithTax,
} from "../../../helpers/ServiceHelper";
import IAmortizeByAnnuity from "../../../interfaces/IAmortizeByAnnuity";
import { ValidationRepaymentplan } from "../../../validations/ValidationRepaymentplan";
import DateHelper from "../../controls/DateHelper/DateHelper";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";

interface ICalculateRepaymentPlan {
  storeState: IReducerRepaymentPlanCalculation;
}
export const CalculateRepaymentPlan: React.FC<ICalculateRepaymentPlan> = ({
  storeState,
}: ICalculateRepaymentPlan) => {
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });

  const calculate = () => {
    var validate = ValidationRepaymentplan(storeState);
    if (storeState.repaymentStructure == "true") {
      if (validate.visible) {
        setErrorDialog(validate);
      } else {
        setCalculateEnabled(false);
        CreateRentalAmortizationWithTax(storeState)
          .then((res) => {
            let repaymentResponse = [];
            res.repaymentPlan.map((r) => {
              // r.rentalDueDate = r.rentalDueDate.split("T")[0];
              repaymentResponse.push({
                content: [
                  r.rentalNumber,
                  <FormattedValue value={r.openingPrincipal.toString()} />,
                  DateHelper(r.rentalDueDate),
                  <FormattedValue value={r.rentalAmount.toString()} />,
                  <FormattedValue value={r.taxOnRentalAmount.toString()} />,
                  <FormattedValue
                    value={r.rentalAmountExclusiveOfTax.toString()}
                  />,
                  <FormattedValue value={r.rentalPrincipal.toString()} />,
                  <FormattedValue value={r.taxOnRentalPrincipal.toString()} />,
                  <FormattedValue
                    value={r.rentalPrincipalExclusiveOfTax.toString()}
                  />,
                  <FormattedValue value={r.rentalInterest.toString()} />,
                  <FormattedValue value={r.taxOnRentalInterest.toString()} />,
                  <FormattedValue
                    value={r.rentalInterestExclusiveOfTax.toString()}
                  />,
                  <FormattedValue value={r.periodicInterest.toString()} />,
                  <FormattedValue value={r.taxOnPeriodicInterest.toString()} />,
                  <FormattedValue
                    value={r.periodicInterestExclusiveOfTax.toString()}
                  />,
                  <FormattedValue value={r.closingPrincipal.toString()} />,
                ],
              });
            });
            dispatch(setRepaymentApiResponse([]));
            dispatch(setRepaymentApiResponse(repaymentResponse));
            dispatch(setAmortizationResponse([]));

            if (storeState.isIncomePosting) {
              var param: IAmortizeByAnnuity = {
                contractStartDate: new Date(storeState.startDate),
                rentalMode: storeState.rentalMode,
                rentalDetails: [],
              };
              res.repaymentPlan.map((r) => {
                param.rentalDetails.push({
                  rentalNo: r.rentalNumber,
                  rentalDueDate: r.rentalDueDate,
                  amountToBeAmortized: r.rentalInterest,
                });
              });
              AmortizeByAnnuity(param).then((annuityResponse) => {
                let response = [];
                annuityResponse.map((r) => {
                  // r.postingDate = r.postingDate.split("T")[0];
                  // r.fromDate = r.fromDate.split("T")[0];
                  // r.toDate = r.toDate.split("T")[0];
                  response.push({
                    content: [
                      r.serialNumber,
                      r.rentalNumber,
                      DateHelper(r.postingDate),
                      r.noOfDays,
                      DateHelper(r.fromDate),
                      DateHelper(r.toDate),
                      <FormattedValue value={r.amortizedAmount.toString()} />,
                    ],
                  });
                });
                dispatch(SetAmortizationByAnnuityResponse([]));
                dispatch(SetAmortizationByAnnuityResponse(response));
              });
            }
            setCalculateEnabled(true);
          })
          .catch((ex: AxiosError) => {
            setErrorDialog({ visible: true, error: ex.response.data });
            setCalculateEnabled(true);
          });
      }
    } else {
      if (validate.visible) {
        setErrorDialog(validate);
      } else {
        setCalculateEnabled(false);
        CreateRentalAmortization(storeState)
          .then((res) => {
            let response = [];
            res.repaymentPlan.map((r) => {
              // r.rentalDueDate = r.rentalDueDate.split("T")[0];
              response.push({
                content: [
                  r.rentalNumber,
                  <FormattedValue value={r.openingPrincipal.toString()} />,
                  <FormattedValue value={r.rentalAmount.toString()} />,
                  <FormattedValue value={r.rentalPrincipal.toString()} />,
                  <FormattedValue value={r.rentalInterest.toString()} />,
                  DateHelper(r.rentalDueDate),
                  <FormattedValue value={r.periodicInterest.toString()} />,
                  <FormattedValue value={r.closingPrincipal.toString()} />,
                ],
              });
            });

            dispatch(setAmortizationResponse([]));
            dispatch(setAmortizationResponse(response));
            dispatch(setRepaymentApiResponse([]));
            if (storeState.isIncomePosting) {
              var param: IAmortizeByAnnuity = {
                contractStartDate: new Date(storeState.startDate),
                rentalMode: storeState.rentalMode,
                rentalDetails: [],
              };
              res.repaymentPlan.map((r) => {
                param.rentalDetails.push({
                  rentalNo: r.rentalNumber,
                  rentalDueDate: r.rentalDueDate,
                  amountToBeAmortized: r.rentalInterest,
                });
              });
              AmortizeByAnnuity(param).then((annuityResponse) => {
                let response = [];
                annuityResponse.map((r) => {
                  // r.postingDate = r.postingDate.split("T")[0];
                  // r.fromDate = r.fromDate.split("T")[0];
                  // r.toDate = r.toDate.split("T")[0];
                  response.push({
                    content: [
                      r.serialNumber,
                      r.rentalNumber,
                      DateHelper(r.postingDate),
                      r.noOfDays,
                      DateHelper(r.fromDate),
                      DateHelper(r.toDate),
                      <FormattedValue value={r.amortizedAmount.toString()} />,
                    ],
                  });
                });
                dispatch(SetAmortizationByAnnuityResponse([]));
                dispatch(SetAmortizationByAnnuityResponse(response));
              });
            }
            setCalculateEnabled(true);
          })
          .catch((ex: AxiosError) => {
            setErrorDialog({ visible: true, error: ex.response.data });
            setCalculateEnabled(true);
          });
      }
    }
  };
  return (
    <Box theme={unityTheme}>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <Grid theme={unityTheme} container spacing={3} mb={4}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="btnCalculate"
            theme={unityTheme}
            onClick={() => calculate()}
            primary
            disabled={!calculateEnabled}
            text="Calculate"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
