import { Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalCharges,
  setSundryCharges,
  setLatePaymentCharges,
  setAssetPrice,
  setPurchaseReturn,
  setSecurityDeposit,
  setUnallocatedAmount,
} from "../../../calc-store/actions/actionEarlyTermination";
import { RootState } from "../../../calc-store/reducers";
import { PurchaseAndReturn } from "../../../common/constants";
import { Dropdown, TextBox } from "../../controls";
import { Divider } from "../../controls/Divider/Divider";

export const TaxDepositAndRepayments = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersEarlyTermination
  );
  const dispatch = useDispatch();
  return (
    <div>
      <hr />
      <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"SecurityDeposit"}
            id="SecurityDeposit"
            label="Security Deposit"
            value={storeState.SecurityDeposit}
            type="text"
            onChange={(e) => {
              dispatch(setSecurityDeposit(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"UnallocatedAmount"}
            id="UnallocatedAmount"
            label="Unallocated Amount"
            value={storeState.UnallocatedAmount}
            type="text"
            onChange={(e) => {
              dispatch(setUnallocatedAmount(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
      </Grid>
    </div>
  );
};
