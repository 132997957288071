import { IReducerAmortizationCalculation } from "../calc-store/reducers/reducersAmortizationCalculation";
import { IReducerAprCalculation } from "../calc-store/reducers/reducersAprCalculation";
import { IReducerCommissionCalculation } from "../calc-store/reducers/reducersUpSellCommission";
import { IReducerAnnuityStraightLineCalculation } from "../calc-store/reducers/reducersAnnuityStraightLine";
import IAmortizeByAnnuity from "../interfaces/IAmortizeByAnnuity";
import { post, get, put } from "./ApiHelper";
import { RentalTypes } from "../common/enums";
import { IETQuote } from "../interfaces/earlyTermination";
import { IReducerExtendRV } from "../calc-store/reducers/reducersExtendRV";
import { IComponentPlan } from "../components/controls/ExcelTable/ExcelTable";
import { IAffordabilityProps } from "../calc-store/reducers/reducersAffordabilityChecks";
import { IStepRentalProps } from "../calc-store/reducers/reducersStepRentals";
import { GetRentalFrequency } from "./LogicHelper";

const CreateRentalAmortization = async (param: any) => {
  var response;
  let requestObject = {
    startDate: new Date(param.startDate),
    ExtensionDays:
      param.extensionDays == null ||
      param.extensionDays == undefined ||
      param.extensionDays == ""
        ? 0
        : param.extensionDays,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("CreateRentalAmortization", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateRentalPlanRentalAmortization = async (
  param: any,
  rentalDetail: any
) => {
  var response;
  let requestObject = {
    startDate: new Date(param.startDate),
    ExtensionDays:
      param.extensionDays == null ||
      param.extensionDays == undefined ||
      param.extensionDays == ""
        ? 0
        : param.extensionDays,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  rentalDetail.map((structure) => {
    if (
      structure.rentalType == RentalTypes.Auto ||
      structure.rentalType == RentalTypes.InterestOnly ||
      structure.rentalType == RentalTypes.Structured ||
      structure.rentalType == RentalTypes.Bonus
    ) {
      requestObject.requestParam.structureRental.push({
        startTerm: structure.startTerm,
        endTerm: structure.endTerm,
        rentalType: structure.rentalType,
        amount: structure.rentalAmount,
      });
    } else {
    }
  });
  await post("CreateRentalAmortization", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountAnnuity = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountAnnuity", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountAnnuityWithBonusPayment = async (
  param: any,
  params: any
) => {
  var response = [];
  let requestObject = {
    startDate: param.startDate,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
    bonusComponents: [],
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  if (params.inception > 0) {
    requestObject.bonusComponents.push({
      amount: params.inception,
      receiveInAdvance: true,
    });
  }
  if (params.maturity > 0) {
    requestObject.bonusComponents.push({
      componentName: "Maturity Fee",
      amount: params.maturity,
      receiveInAdvance: false,
    });
  }
  await post("RentalAmountAnnuityWithBonusPayment", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountEP = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountEP", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountFlat = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountFlat", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRateBasedSubsidy = async (param: any) => {
  var response;
  let requestObject = {
    rentalMode: param.rentalMode,
    rv: param.residualValue == "" ? 0 : param.residualValue,
    frequency: param.rentalFrequency,
    financeAmount: param.financedAmount == "" ? 0 : param.financedAmount,
    rentalSlabs: param.rentalSlabs,
    financierRate: param.financierRate,
  };
  await post("TargetRateBaseSubsidy", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
const CalculateSplitSubsidy = async (param: any) => {
  var response;
  let requestObject = {
    rentalMode: param.rentalMode,
    rv: param.residualValue == "" ? 0 : param.residualValue,
    frequency: param.rentalFrequency,
    financeAmount: param.financedAmount == "" ? 0 : param.financedAmount,
    rentalSlabs: param.rentalSlabs,
    customerRate: param.customerRate,
    manufacturerRate: param.manufacturerRate,
    dealerRate: param.dealerRate,
  };
  await post("SplitSubsidyBetweenDealerandManufacturer", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const GenerateAmortizationPlan = async (
  param: IReducerAmortizationCalculation
) => {
  var response;
  let requestObject = {
    amountToBeAmortized: param.amountToAmortize,
    terms: param.contractTerms,
    startDate: param.startDate,
  };
  await post("GetSplitSubsidy", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const RentalAmountFlatPLUS = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountFlatPlus", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateStraightLineAmortization = async (
  param: IReducerAmortizationCalculation
) => {
  var response = [];
  let requestObject = {
    amountToBeAmortized: param.amountToAmortize,
    terms: param.contractTerms,
    startDate: param.startDate,
  };
  await post("StraightLineAmortization", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateActualNoOfDaysAmortization = async (
  param: IReducerAmortizationCalculation
) => {
  var response = [];
  let requestObject = {
    amountToBeAmortized: param.amountToAmortize,
    endDate: param.endDate,
    startDate: param.startDate,
  };
  await post("StraightLineWithActualNumberOfDays", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateStraightLineTaxRata = async (
  param: IReducerAmortizationCalculation
) => {
  var response = [];
  let requestObject = {
    amountToBeAmortized: param.amountToAmortize,
    terms: param.contractTerms,
    startDate: param.startDate,
    componentName: param.componentName,
    endDate: param.endDate,
    taxRate: param.taxRate,
  };
  await post("StraightLineProRataAmortization", requestObject)
    .then((res: any) => {
      response = res.amortizaton;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateAPR = async (param: IReducerAprCalculation) => {
  var response;
  let requestObject = {
    rentalMode: param.rentalMode,
    residualValue: param.residualValue == "" ? 0 : param.residualValue,
    financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
    rentalSlabs: [],
    rentalFrequency: param.rentalFrequency,
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.rentalSlabs.push({
      startTerm: structure.StartTerm,
      endTerm: structure.EndTerm,
      amount: structure.Amount,
    });
  });
  await post("CalculateAPR", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const AmortizeByAnnuity = async (param: IAmortizeByAnnuity) => {
  var response;
  await post("AmortizeByAnnuity", param)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateAnnuityStraightLine = async (
  param: IReducerAnnuityStraightLineCalculation
) => {
  var response = [];
  let requestObject = {
    startDate: param.startDate,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
    taxParameters: {
      taxRate: param.taxRate,
      isTaxInclusive: param.inclusiveTax,
      rentalComponent: param.rentalComponent == "" ? 0 : param.rentalComponent,
    },
    incomeExpenseParam: {
      incomeExpenses: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  param.IIncomeExpenseDetail.map((structure) => {
    requestObject.incomeExpenseParam.incomeExpenses.push({
      componentName: structure.ComponentName,
      amount: structure.Amount,
      isIncome: structure.IsIncome,
      isSubsidy: structure.IsSubsidy,
    });
  });
  await post("CreateIncomeExpenseAmortization", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateRentalAmortizationWithTax = async (param: any) => {
  var response;
  let requestObject = {
    startDate: new Date(param.startDate),
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
    taxParameters: {
      taxRate: param.taxRate == "" ? 0 : param.taxRate,
      isTaxInclusive: param.inclusivetax,
      rentalComponent: param.rentalComponent == "" ? 0 : param.rentalComponent,
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("CreateRentalAmortizationWithTax", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateSpellCommission = async (
  param: IReducerCommissionCalculation
) => {
  var response;
  let requestObject = {
    rentalMode: param.rentalMode,
    rv: param.rvamount == "" ? 0 : param.rvamount,
    discountRate: param.discountRate == "" ? 0 : param.discountRate,
    minimumCommission:
      param.minimumCommission == "" ? 0 : param.minimumCommission,
    startDate: param.startDate,
    rentalFrequency: param.rentalFrequency,
    percentageOfBasicCommission:
      param.percentageOfBasicCommission == ""
        ? 0
        : param.percentageOfBasicCommission,
    financeAmount: param.financeAmount,
    rentalSlabs: [],
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.rentalSlabs.push({
      startTerm: structure.StartTerm,
      endTerm: structure.EndTerm,
      amount: structure.Amount,
    });
  });
  await post("CalculateUpSellCommission", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountAnnuity365 = async (param: any) => {
  var response = undefined;
  let requestObject = {
    startDate: param.startDate,
    ExtensionDays:
      param.extensionDays == null ||
      param.extensionDays == undefined ||
      param.extensionDays == ""
        ? 0
        : param.extensionDays,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountAnnuity365", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateRentalAmortization365 = async (param: any, rentalDetail: any) => {
  var response;
  let requestObject = {
    startDate: param.startDate,
    apr: param.APR == "" ? 0 : param.APR,
    contractTerms: param.contractTerms,
    rentalMode: param.rentalMode,
    residualValue: param.residualValue == "" ? 0 : param.residualValue,
    rentalFrequency: param.rentalFrequency,
    financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
    rentalStream: [],
  };
  rentalDetail.map((rental) => {
    requestObject.rentalStream.push({
      rentalNumber: rental.rentalNumber,
      dueDate: rental.rentalDueDate,
      rentalType:
        rental.rentalType == RentalTypes.InterestOnly
          ? "InterestOnly"
          : rental.rentalType == RentalTypes.ResidualValue
          ? "ResidualValue"
          : rental.rentalType,
      amount: rental.rentalAmount,
    });
  });
  await post("CreateRentalAmortization365", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateRentalAmountAnnuity360 = async (param: any) => {
  var response = undefined;
  let requestObject = {
    startDate: param.startDate,
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountActual360", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateRentalAmortization360 = async (param: any, rentalDetail: any) => {
  var response;
  let requestObject = {
    startDate: param.startDate,
    apr: param.APR == "" ? 0 : param.APR,
    contractTerms: param.contractTerms,
    rentalMode: param.rentalMode,
    residualValue: param.residualValue == "" ? 0 : param.residualValue,
    rentalFrequency: param.rentalFrequency,
    financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
    rentalStream: [],
  };
  rentalDetail.map((rental) => {
    requestObject.rentalStream.push({
      rentalNumber: rental.rentalNumber,
      dueDate: rental.rentalDueDate,
      rentalType:
        rental.rentalType == RentalTypes.InterestOnly
          ? "InterestOnly"
          : rental.rentalType == RentalTypes.ResidualValue
          ? "ResidualValue"
          : rental.rentalType,
      amount: rental.rentalAmount,
    });
  });
  await post("CreateRentalAmortization360", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateETDailyFutureInterest = async (param: IETQuote) => {
  var response;
  await post("ETDailyFutureInterest", param)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateExtendRV = async (param: any, param2: any) => {
  var response = [];
  let requestObject = {
    apr: param.APR == "" ? 0 : param.APR,
    taxParameters: {
      taxRate: param.taxRate,
      isTaxInclusive: param.isTaxInclusive,
      rentalComponent: param.rentalComponent,
    },
    rentalMode: param.rentalMode,
    rvAmount: param.rvAmount == "" ? 0 : param.rvAmount,
    terms: param.contractTerms,
    rentalFrequency: param.rentalFrequency,
    rentalCalculationMethod: param.rentalCalcMethod,
    repaymentPlan: [],
  };
  const RentalDetail: IComponentPlan = param2.ExcelMultiple.find(
    (element: IComponentPlan) => {
      return element.name === "Asset";
    }
  );
  if (RentalDetail != null || RentalDetail != undefined) {
    RentalDetail.rows.map((re: any) => {
      requestObject.repaymentPlan.push({
        rentalAmountExclusiveOfTax: re.content[17],
        rentalPrincipalExclusiveOfTax: re.content[6],
        rentalInterestExclusiveOfTax: re.content[9],
        periodicInterestExclusiveOfTax: re.content[10],
        taxOnRentalAmount: re.content[11],
        taxOnPeriodicInterest: re.content[13],
        taxOnRentalInterest: re.content[12],
        taxOnRentalPrincipal: re.content[7],
        rentalNumber: re.content[0],
        openingPrincipal: re.content[14],
        rentalAmount: re.content[1],
        rentalPrincipal: re.content[5],
        rentalInterest: re.content[4],
        rentalDueDate: re.content[3],
        periodicInterest: re.content[8],
        compoundInterest: re.content[16],
        closingPrincipal: re.content[15],
        rentalType: re.content[2],
      });
    });
  }
  await post("ExtendRV", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CreateETQuote = async (param: IETQuote) => {
  var response;
  await post("CalculateETQuoteByEffectiveAnnuity", param)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateReverseDownPayment = async (param: IAffordabilityProps) => {
  var response = [];
  var rentalFrequency = GetRentalFrequency(param.rentalFrequency);
  let requestObject = {
    requestParam: {
      interestRate: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      rvAmount: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [
        {
          startTerm: 1,
          endTerm: parseFloat(param.contractTerms) / rentalFrequency.BaseTerms,
          rentalType: "Auto",
          amount: param.rentalAmount,
        },
      ],
    },
  };
  await post("ReverseDownPayment", requestObject.requestParam)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const ETNetReceivables = async (param: any) => {
  var response = [];
  let requestObject = {
    clientEtDate: param.ClientEtDate,
    contractInterestRate: param.ContractInterestRate,
    etQuoteGenerationDate: param.EtQuoteGenerationDate,
    overDueInterestRate: param.OverDueInterestRate,
    totalOverDueAmount: param.TotalOverDueAmount,
    assetPrice: param.AssetPrice,
    etNetReceivableType: param.ETNetReceivableType,
    etPenalty: {
      etPenaltyMethod: param.ETPenalty.ETPenaltyMethod,
      etPenaltyReferenceAmountForPercentage:
        param.ETPenalty.ETPenaltyReferenceAmountForPercentage,
      etPenaltyAmount: param.ETPenalty.ETPenaltyAmount,
      etPenaltyPercentage: param.ETPenalty.ETPenaltyPercentage,
      noOfDays: param.ETPenalty.NoOfDays,
    },
    payables: [],
    receivables: [],
    taxes: [],
    rentalDetails: [],
    etCompRentalDetails: [],
  };
  param.RentalDetails.map((r) => {
    requestObject.rentalDetails.push({
      rentalNo: r.RentalNo,
      rentalDueDate: r.RentalDueDate,
      openingPrincipal: r.OpeningPrincipal,
      rentalPrincipal: r.RentalPrincipal,
      rentalInterest: r.RentalInterest,
      netRental: r.RentalAmount,
      closingPrincipal: r.ClosingPrincipal,
      inclusive: r.Inclusive,
      grossRental: r.GrossRental,
      status: r.status,
      periodicInterest: r.PeriodicInterest,
    });
  });
  param.Payables.map((r) => {
    requestObject.payables.push({
      componentName: r.ComponentName,
      amount: r.Amount,
    });
  });
  param.Receivables.map((r) => {
    requestObject.receivables.push({
      componentName: r.ComponentName,
      amount: r.Amount,
    });
  });
  param.etCompRentalDetails.map((r) => {
    var content = [];
    r.componentRentalDetails.map((rr) => {
      content.push({
        rentalNo: rr.rentalNo,
        rentalDueDate: rr.rentalDueDate,
        openingPrincipal: rr.openingPrincipal,
        rentalPrincipal: rr.rentalPrincipal,
        rentalInterest: rr.rentalInterest,
        netRental: rr.netRental,
        closingPrincipal: rr.closingPrincipal,
        inclusive: [],
        grossRental: rr.grossRental,
        status: rr.status,
        periodicInterest: rr.periodicInterest,
      });
    });
    requestObject.etCompRentalDetails.push({
      componentName: r.componentName,
      componentRentalDetails: content,
    });
  });
  await post("CalculateETNetReceivables", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateReverseFinanceAmount = async (param: IAffordabilityProps) => {
  var response = [];
  var rentalFrequency = GetRentalFrequency(param.rentalFrequency);
  let requestObject = {
    requestParam: {
      interestRate: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      rvAmount: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      DownPayment: param.downPayment == "" ? 0 : param.downPayment,
      structureRental: [
        {
          startTerm: 1,
          endTerm: parseFloat(param.contractTerms) / rentalFrequency.BaseTerms,
          rentalType: "Auto",
          amount: param.rentalAmount,
        },
      ],
    },
  };
  await post("ReverseFinanceAmount", requestObject.requestParam)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateStepUpRentalCalculation = async (param: IStepRentalProps) => {
  var response = undefined;
  let requestObject = {
    financedAmount: param.financedAmount,
    interestRate: param.APR == "" ? 0 : param.APR,
    terms: param.contractTerms,
    rentalMode: param.rentalMode,
    residualValue:
      param.residualValue == "" || param.residualValue == undefined
        ? 0
        : param.residualValue,
    rentalInterval: param.stepFrequency,
    intervalPercentage: param.stepPercentage,
    rentalFrequency: param.rentalFrequency,
  };
  await post("StepUpRentalCalculation", requestObject)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateStepDownRentalCalculation = async (param: IStepRentalProps) => {
  var response = undefined;
  let requestObject = {
    financedAmount: param.financedAmount,
    interestRate: param.APR == "" ? 0 : param.APR,
    terms: param.contractTerms,
    rentalMode: param.rentalMode,
    residualValue:
      param.residualValue == "" || param.residualValue == undefined
        ? 0
        : param.residualValue,
    rentalInterval: param.stepFrequency,
    intervalPercentage: param.stepPercentage,
    rentalFrequency: param.rentalFrequency,
  };
  await post("StepDownRentalCalculation", requestObject)
    .then((res: any) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const GetBaseRateSource = async () => {
  var response = [];
  await get("baseratesources", true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const GetBaseRateFrequency = async (param: any) => {
  var response = [];
  await get("baseratefrequency/" + param, true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const CalculateBaseRateAPI = async (param: any) => {
  var response = [];
  await get("baserate/" + param, true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const SaveConfiguration = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      base_rate: param.baseRate,
      base_rate_frequency_id: param.frequency,
    },
  };
  await post("baserate/save-configuration", requestObject.requestParam, true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};

const GetBaseRateConfiguration = async () => {
  var response = [];
  await get("baserate/get-baserate-configuration/", true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
const CalculateRentalAmountAnnuityWithVAT = async (param: any) => {
  var response = [];
  let requestObject = {
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount: param.financedAmount == "" ? 0 : param.financedAmount,
      structureRental: [],
    },
    startDate: new Date(param.startDate),
    vatTreatment: param.vatTreatment,
    vatAmount: param.vatAmount,
    vatPercentage: param.vatPercentage == "" ? 0 : param.vatPercentage,
  };
  param.structureRentalDetail.map((structure) => {
    requestObject.requestParam.structureRental.push({
      startTerm: structure.TermFrom,
      endTerm: structure.TermTo,
      rentalType: structure.RentalType,
      amount: structure.Amount,
    });
  });
  await post("RentalAmountAnnuityWithVAT", requestObject)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
const DeleteBaseRateConfiguration = async (param: any) => {
  var response = [];
  await put("baserate/del/" + param, true)
    .then((res: []) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
const CreateRentalPlanRentalAmortizationForBouns = async (
  param: any,
  rentalDetail: any
) => {
  var response;
  let requestObject = {
    startDate: new Date(param.startDate),
    requestParam: {
      apr: param.APR == "" ? 0 : param.APR,
      contractTerms: param.contractTerms,
      rentalMode: param.rentalMode,
      residualValue: param.residualValue == "" ? 0 : param.residualValue,
      rentalFrequency: param.rentalFrequency,
      financedAmount:
        rentalDetail.updatedFinancedAmount == ""
          ? 0
          : rentalDetail.updatedFinancedAmount,
      structureRental: [],
    },
  };
  rentalDetail.rentalSummary.map((structure) => {
    if (
      structure.rentalType == RentalTypes.Auto ||
      structure.rentalType == RentalTypes.InterestOnly ||
      structure.rentalType == RentalTypes.Structured ||
      structure.rentalType == RentalTypes.Bonus ||
      structure.rentalType == RentalTypes.VAT
    ) {
      requestObject.requestParam.structureRental.push({
        startTerm: structure.startTerm,
        endTerm: structure.endTerm,
        rentalType: structure.rentalType,
        amount: structure.rentalAmount,
      });
    } else {
    }
  });
  await post("CreateRentalAmortization", requestObject)
    .then((res) => {
      response = res;
    })
    .catch((ex) => {
      throw ex;
    });
  return response;
};
export {
  CalculateRentalAmountFlat,
  CalculateRentalAmountAnnuity,
  CalculateRentalAmountEP,
  CreateRentalAmortization,
  CreateRentalAmortizationWithTax,
  CalculateRateBasedSubsidy,
  CalculateSplitSubsidy,
  GenerateAmortizationPlan,
  RentalAmountFlatPLUS,
  CalculateStraightLineAmortization,
  CalculateAPR,
  CalculateActualNoOfDaysAmortization,
  AmortizeByAnnuity,
  CalculateSpellCommission,
  CalculateStraightLineTaxRata,
  CalculateAnnuityStraightLine,
  CreateRentalPlanRentalAmortization,
  CalculateRentalAmountAnnuity365,
  CreateRentalAmortization365,
  CalculateRentalAmountAnnuity360,
  CreateRentalAmortization360,
  CreateETDailyFutureInterest,
  CreateETQuote,
  CalculateReverseDownPayment,
  CalculateReverseFinanceAmount,
  CalculateExtendRV,
  GetBaseRateSource,
  GetBaseRateFrequency,
  CalculateBaseRateAPI,
  SaveConfiguration,
  GetBaseRateConfiguration,
  DeleteBaseRateConfiguration,
  ETNetReceivables,
  CalculateRentalAmountAnnuityWithBonusPayment,
  CreateRentalPlanRentalAmortizationForBouns,
  CalculateStepUpRentalCalculation,
  CalculateStepDownRentalCalculation,
  CalculateRentalAmountAnnuityWithVAT,
};
