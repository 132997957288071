import { reducerRentalCalculation } from "./reducersRentalCalculation";

import { reducersSubsidyCalculation } from "./reducersSubsidyCalculation";

import { reducersAmortizationCalculation } from "./reducersAmortizationCalculation";

import { reducersAprCalculation } from "./reducersAprCalculation";

import { reducerRepaymentPlanCalculation } from "./reducersRepaymentPlanCalculation";

import { reducersUpSellCommission } from "./reducersUpSellCommission";

import { reducersEarlyTermination } from "./reducersEarlyTermination";

import { reducerAnnuityStraightLineCalculation } from "./reducersAnnuityStraightLine";
import { reducerRentalPlan } from "./reducersRentalPlan";
import { reducersExtendRV } from "./reducersExtendRV";
import { reducersAffordabilityChecks } from "./reducersAffordabilityChecks";

import { combineReducers } from "redux";
import { reducerExcelData } from "./reducersExcelData";
import { reducerBaseRate } from "./reducersBaseRate";
import { reducersAdditionalCharges } from "./reducersAdditionalCharges";
import { reducerRentalAPR } from "./reducersRentalApr";
import { reducersStepRentals } from "./reducersStepRentals";
import { reducerFinancialCalculation } from "./financialCalculation.reducer";

const rootReducers = combineReducers({
  reducerRentalCalculation,
  reducersSubsidyCalculation,
  reducersAmortizationCalculation,
  reducersAprCalculation,
  reducerRepaymentPlanCalculation,
  reducersUpSellCommission,
  reducerAnnuityStraightLineCalculation,
  reducerRentalPlan,
  reducersEarlyTermination,
  reducersExtendRV,
  reducerExcelData,
  reducersAffordabilityChecks,
  reducerBaseRate,
  reducersAdditionalCharges,
  reducerRentalAPR,
  reducersStepRentals,
  reducerFinancialCalculation,
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
