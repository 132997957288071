import {
  Box,
  Button,
  Grid,
  Icon,
  unityTheme,
  IBoxProps,
} from "@ntpkunity/controls";
import { ValidateSubsidyCalculation } from "../../../validations/ValidateSubsidyCalculation";
import { Dropdown, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import {
  rentalFrequenciesNumeric,
  rentalModesNumeric,
  subsidyTypes,
} from "../../../common/constants";
import {
  setSubsidyFinancedAmount,
  setSubsidyRentalFrequency,
  setSubsidyRentalMode,
  setTotalSubsidyAmount,
  setSubsidyType,
  setManufacturerSubsidyAmount,
  setDealerSubsidyAmount,
} from "../../../calc-store/actions/actionsSubsidyCalculation";
import {
  CalculateSplitSubsidy,
  CalculateRateBasedSubsidy,
} from "../../../helpers/ServiceHelper";
import { RentalSlabs } from "./RentalSlabs";
import { useState } from "react";
import { SubsidyControls } from "./SubsidyControls";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { SubsidyResponse } from "./SubsidyResponse";
import { styled } from "@mui/material/styles";
import { SubsidyTypes } from "../../../common/enums";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".text-muted": {
      color: "#7F7F7F",
    },
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".custom-checkbox-wrap": {
      ".u-custom-control-label": {
        marginBottom: 0,
      },
    },
    ".sub-section": {
      borderTop: "1px solid" + theme.palette.grey[100],
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
    hr: {
      borderTopColor: theme.palette.grey[100],
      borderBottom: "none",
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));

interface ISubsidyCalculation {}

const SubsidyCalculation: React.FC<ISubsidyCalculation> = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersSubsidyCalculation
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [loading, setLoading] = useState<string>("");
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const clearResponseValues = () => {
    dispatch(setTotalSubsidyAmount(""));
    dispatch(setManufacturerSubsidyAmount(""));
    dispatch(setDealerSubsidyAmount(""));
  };

  const clearValues = () => {
    dispatch(setTotalSubsidyAmount(""));
    dispatch(setManufacturerSubsidyAmount(""));
    dispatch(setDealerSubsidyAmount(""));
  };

  const calculateSubsidy = () => {
    var validate = ValidateSubsidyCalculation(storeState);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      setCalculateEnabled(false);
      clearResponseValues();
      if (storeState.subsidyType == SubsidyTypes.Rate) {
        CalculateRateBasedSubsidy(storeState)
          .then((res) => {
            dispatch(setTotalSubsidyAmount(res.totalSubsidy.toString()));
            setCalculateEnabled(true);
          })
          .catch((ex) => {
            setCalculateEnabled(true);
            setErrorDialog({ visible: true, error: ex.response.data });
          });
      } else {
        CalculateSplitSubsidy(storeState)
          .then((res) => {
            dispatch(setTotalSubsidyAmount(res.totalSubsidy.toString()));
            dispatch(
              setManufacturerSubsidyAmount(res.manufacturerSubsidy.toString())
            );
            dispatch(setDealerSubsidyAmount(res.dealerSubsidy.toString()));
            setCalculateEnabled(true);
          })
          .catch((ex) => {
            setCalculateEnabled(true);
            setErrorDialog({ visible: true, error: ex.response.data });
          });
      }
    }
  };

  return (
    <div>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <LayoutWithSideNavComponent theme={unityTheme}>
        <MainContent theme={unityTheme} className="main-content">
          <CalculationHeader text={"Subsidy / Subvention"} />
          <Box theme={unityTheme} mb={4}>
            <Grid theme={unityTheme} container spacing={4}>
              <Grid theme={unityTheme} item xs={12} sm={6} md={4} lg={4}>
                <Dropdown
                  items={subsidyTypes}
                  label="Subsidy Type"
                  onChange={(e) => {
                    clearValues();
                    dispatch(setSubsidyType(e.target.value));
                  }}
                  value={storeState.subsidyType}
                  helperText="Select the Subsidy Type i.e. Split or Rate based."
                  testid="cbxSubsidyType"
                />
              </Grid>
            </Grid>
          </Box>
          <Box theme={unityTheme} mb={3}>
            <Grid theme={unityTheme} container spacing={3}>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={rentalModesNumeric}
                  label="Payment Mode"
                  onChange={(e) => {
                    dispatch(setSubsidyRentalMode(e.target.value));
                  }}
                  value={storeState.rentalMode}
                  helperText="If payment to be received at the beginning of the period or end of the period."
                  testid="cbxSubsidyRentalMode"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={rentalFrequenciesNumeric}
                  label="Payment Frequency"
                  onChange={(e) => {
                    dispatch(setSubsidyRentalFrequency(e.target.value));
                  }}
                  value={storeState.rentalFrequency}
                  helperText="The frequency of installment  amount to be paid."
                  testid="cbxSubsidyRentalFrequency"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <TextBox
                  testid={"txtFinancedAmount"}
                  id="txtFinancedAmount"
                  label="Lending Amount"
                  value={storeState.financedAmount}
                  type="text"
                  helperText="Final lending amount."
                  onChange={(e) => {
                    dispatch(setSubsidyFinancedAmount(e));
                  }}
                  currency
                  scale={2}
                  masking
                />
              </Grid>
            </Grid>
          </Box>
          <Box theme={unityTheme} data-testid="SubsidyControls">
            <SubsidyControls subsidyType={storeState.subsidyType} />
          </Box>

          <RentalSlabs />

          <Grid theme={unityTheme} container spacing={3} mb={4}>
            <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
              <Button
                className="btn-w-100"
                data-testid="btnCalculate"
                theme={unityTheme}
                disabled={!calculateEnabled}
                onClick={(e) => calculateSubsidy()}
                primary
                text="Calculate"
              />
            </Grid>
          </Grid>

          <Box theme={unityTheme} mt={1} mb={4}>
            <SubsidyResponse subsidyType={storeState.subsidyType} />
          </Box>
        </MainContent>
      </LayoutWithSideNavComponent>
    </div>
  );
};

export default SubsidyCalculation;
