import {
  Box,
  Grid,
  unityTheme,
  Dialog,
  Button,
  Icon,
  Typography,
} from "@ntpkunity/controls";
import {
  setBaseRateConfigurationResponse,
  setBaseRateConfiguration,
  setBaseRateFrequencyResponse,
  setBaseRateValue,
} from "../../../calc-store/actions/actionsBaseRateApi";
import { RootState } from "../../../calc-store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, TextBox, Radiobutton } from "../../controls";
import { Table } from "../../controls/Table/Table";
import {
  GetBaseRateFrequency,
  GetBaseRateConfiguration,
  CalculateBaseRateAPI,
  SaveConfiguration,
  DeleteBaseRateConfiguration,
} from "../../../helpers/ServiceHelper";
import { useEffect, useState } from "react";
import { IBaseRateConfig } from "../../../local-store/base-rate-config.store";

interface IBaseRateConfiguration {
  visible: boolean;
  onClose?(): void;
}

export const BaseRateConfiguration = (props: IBaseRateConfiguration) => {
  const storeState = useSelector((state: RootState) => state.reducerBaseRate);
  const dispatch = useDispatch();
  const [source, setSource] = useState("");
  const [frequency, setFrequency] = useState("");
  const [baseRate, setBaseRate] = useState();

  const columns = [] as any;
  columns.push("Sr #");
  columns.push("Source");
  columns.push("Frequency");
  columns.push("Base Rate");
  columns.push("Delete");

  type ComboItems = {
    value: string;
    text: string;
  };

  const GetFrequency = (param) => {
    GetBaseRateFrequency(param)
      .then((res) => {
        var items: ComboItems[] = [];
        res.map((r) => {
          items.push({
            value: r.id,
            text: r.title,
          });
        });
        dispatch(setBaseRateFrequencyResponse(items));
      })
      .catch((ex) => {
        ex.response.data;
      });
  };
  const GetBaseRate = (param) => {
    CalculateBaseRateAPI(param)
      .then((res: any) => {
        res.map((r) => {
          setBaseRate(r.base_rate.toString()),
            dispatch(
              setBaseRateValue(parseFloat(r.base_rate.toString()).toFixed(4))
            );
        });
      })
      .catch((ex) => {
        ex.response.data;
      });
  };

  type BaseRateItems = {
    id: string;
    frequency_title: string;
    source_title: string;
    base_rate: string;
  };

  const addConfiguration = () => {
    var newConfiguration: IBaseRateConfig = {
      serialNumber: 0,
      source: source,
      frequency: frequency,
      baseRate: baseRate,
    };
    SaveConfiguration(newConfiguration);
    GetBaseRateConfiguration()
      .then((res) => {
        var items: BaseRateItems[] = [];
        res.map((r) => {
          items.push({
            id: r.id,
            frequency_title: r.frequency,
            source_title: r.Source,
            base_rate: r.base_rate,
          });
        });
        dispatch(setBaseRateConfigurationResponse(items));
        {
          BaseRateConfigurationData(items);
        }
      })
      .catch((ex) => {
        ex.response.data;
      });
  };
  const BaseRateConfigurationData = (param: any) => {
    var base_rate_configuration = [];
    var count = 1;
    var _rows = param;
    _rows.map((r) => {
      base_rate_configuration.push({
        content: [
          count,
          r.source_title,
          r.frequency_title,
          r.base_rate,
          <Button
            theme={unityTheme}
            onClick={() => {
              DeleteBaseRateConfiguration(r.id);
              addConfiguration();
            }}
            secondary
            variant="outlined"
            iconText={<Icon name="CloseBlack" />}
          />,
        ],
      });
      count = count + 1;
    });
    dispatch(setBaseRateConfiguration(base_rate_configuration));
  };
  return (
    <Dialog
      theme={unityTheme}
      size="lg"
      title="Base Rate Configuration"
      noFooter={true}
      open={props.visible}
      onCloseDialog={() => {
        props.onClose?.();
      }}
    >
      {/* <Grid
        container
        xs={8}
        theme={unityTheme}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography theme={unityTheme} mb={5} component={"h3"} variant={"h3"}>
          Base Rate Configuration
        </Typography>
      </Grid> */}
      <Grid container theme={unityTheme}>
        <Typography theme={unityTheme} mb={2} component={"h4"} variant={"h4"}>
          Input:
        </Typography>
      </Grid>
      <Box theme={unityTheme}>
        <Grid theme={unityTheme} item xs={12} sm={16}>
          <Grid theme={unityTheme} container spacing={4}>
            <Grid theme={unityTheme} item xs={3} mb={2}>
              <Dropdown
                items={storeState.baseRateSourceResponse}
                label="Source"
                onChange={(e) => {
                  {
                    GetFrequency(e.target.value);
                  }
                  setSource(e.target.value);
                }}
                value={source}
                testid="cbxBaseConfigurationsSource"
              />
            </Grid>
            <Grid theme={unityTheme} item xs={3}>
              <Dropdown
                items={storeState.baseRateFrequencyResponse}
                label="Frequency"
                onChange={(e) => {
                  {
                    GetBaseRate(e.target.value);
                  }
                  setFrequency(e.target.value);
                }}
                value={frequency}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={3}>
              <TextBox
                id="txtTerms"
                label="Base Rate"
                value={baseRate}
                type="text"
                onChange={(e) => {
                  setBaseRate(e);
                }}
                endAdornment={"%"}
                masking
                currency
                scale={4}
              />
            </Grid>
            <Grid theme={unityTheme} item xs={3}>
              <Button
                theme={unityTheme}
                secondary
                text="Add"
                onClick={() => addConfiguration()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container theme={unityTheme}>
        <Typography theme={unityTheme} mb={2} component={"h4"} variant={"h4"}>
          Output:
        </Typography>
      </Grid>
      <Table columns={columns} rows={storeState.BaseRateConfiguration} />
    </Dialog>
  );
};
