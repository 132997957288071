import { Grid, unityTheme, Box } from "@ntpkunity/controls";
import { DatePicker } from "../../controls/DateHelper/DatePicker";
import { Dropdown, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import {
  setContractInterestRate,
  setOverDueInterestRate,
  setEtQuoteGenerationDate,
  setClientEtDate,
  setETMethod,
  setTotalOverDueAmount,
  setETPenaltyReferenceAmountForPercentage,
  setETPenaltyPercentage,
  setETPenaltyMethod,
  setETPenaltyAmount,
  setNoofdays,
  setPurchaseReturn,
  setAssetPrice,
} from "../../../calc-store/actions/actionEarlyTermination";
import { CalculateEarlyTermination } from "./calculate.button";
import {
  ETCalculationMethods,
  ETPenaltyReference,
  PurchaseAndReturn,
  rentalPenalty,
} from "../../../common/constants";
import { useState } from "react";
import { etCalculationMethods, ETPenaltyMethod } from "../../../common/enums";
import { Receivables } from "./otherReceivables";
import { TaxDepositAndRepayments } from "./taxDepositsAndPrepayment";
import { RepaymentPlan } from "./RepaymentPlan";
import { setPlanMultipleData } from "../../../calc-store/actions/actionExcelData";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";

interface IEarlyTermination {}
const EarlyTermination: React.FC<IEarlyTermination> = () => {
  const [calRes, setCalRes] = useState(false);
  const storeState = useSelector(
    (state: RootState) => state.reducersEarlyTermination
  );
  const dispatch = useDispatch();
  const getEtReference = () => {
    if (storeState.ETPenalty.ETPenaltyMethod == ETPenaltyMethod.Percentage) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Dropdown
            items={ETPenaltyReference}
            label="ET Penalty Reference Amount For Percentage"
            onChange={(e) => {
              dispatch(
                setETPenaltyReferenceAmountForPercentage(e.target.value)
              );
            }}
            value={storeState.ETPenalty.ETPenaltyReferenceAmountForPercentage}
            helperText="ET Penalty Reference Amount For Percentage."
            testid="cbxEtPenaltyReferenceAmount"
          />
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };
  const getEtPeneltyPercentage = () => {
    if (storeState.ETPenalty.ETPenaltyMethod == ETPenaltyMethod.Percentage) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"ETPenaltyPercentage"}
            id="ETPenaltyPercentage"
            label="ET Penalty Percentage"
            value={storeState.ETPenalty.ETPenaltyPercentage}
            type="text"
            helperText="ET Penalty Percentage."
            onChange={(e) => {
              dispatch(setETPenaltyPercentage(e));
            }}
            endAdornment="%"
            scale={2}
            numeric
            masking
          />
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };
  const getETPenaltyMethod = () => {
    if (storeState.ETPenalty.ETPenaltyMethod == ETPenaltyMethod.Amount) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"etpenaltyamount"}
            id="etpenaltyamount"
            label="ET Penalty Amount"
            value={storeState.ETPenalty.ETPenaltyAmount}
            type="text"
            helperText="ET Penalty Amount."
            onChange={(e) => {
              dispatch(setETPenaltyAmount(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
      );
    } else if (
      storeState.ETPenalty.ETPenaltyMethod == ETPenaltyMethod.NoOfDaysInterest
    ) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"NoOfDays"}
            id="NoOfDays"
            label="No Of Days"
            value={storeState.ETPenalty.NoOfDays}
            type="number"
            helperText="No Of Days."
            onChange={(e) => {
              dispatch(setNoofdays(e));
            }}
          />
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <Box theme={unityTheme} className="main-content">
        <CalculationHeader text={"Early Payout"} />
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} item xs={12} sm={6} md={6} lg={6}>
            <Dropdown
              items={ETCalculationMethods}
              label="ET Calculation Methods"
              onChange={(e) => {
                setCalRes(false);
                dispatch(setETMethod(e.target.value));
                dispatch(setPlanMultipleData([]));
              }}
              value={storeState.ETMethod}
              helperText="Early Termination Method."
              testid="cbxEtCalculationMethods"
            />
          </Grid>
        </Grid>
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="contractInterestRate"
          >
            <TextBox
              testid={"contractInterestRate"}
              id="contractInterestRate"
              label="Interest Rate"
              value={storeState.ContractInterestRate}
              type="text"
              helperText="Interest Rate."
              onChange={(e) => {
                dispatch(setContractInterestRate(e));
              }}
              endAdornment="%"
              scale={2}
              numeric
              masking
            />
          </Grid>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="OverDueInterestRate"
          >
            <TextBox
              testid={"OverDueInterestRate"}
              id="OverDueInterestRate"
              label="Overdue Interest Rate"
              value={storeState.OverDueInterestRate}
              type="text"
              helperText="Overdue Interest Rate"
              onChange={(e) => {
                dispatch(setOverDueInterestRate(e));
              }}
              endAdornment="%"
              scale={2}
              numeric
              masking
            />
          </Grid>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="OverDueInterestRate"
          >
            <TextBox
              testid={"TotalOverDueAmount"}
              id="TotalOverDueAmount"
              label="Total Overdue Amount"
              value={storeState.TotalOverDueAmount}
              type="text"
              helperText="Total Overdue Amount"
              onChange={(e) => {
                dispatch(setTotalOverDueAmount(e));
              }}
              scale={2}
              currency
              masking
            />
          </Grid>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            data-testid="etQuoteGenerationDate"
          >
            <DatePicker
              label="Et Quote Generation Date"
              testId="etQuoteGenerationDate"
              value={storeState.EtQuoteGenerationDate}
              onChange={(e: Date) => dispatch(setEtQuoteGenerationDate(e))}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <DatePicker
              label="Client Date"
              testId="txtStartDate"
              value={storeState.ClientEtDate}
              onChange={(e: Date) => dispatch(setClientEtDate(e))}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={PurchaseAndReturn}
              label="Purchase and Return Decision"
              onChange={(e) => {
                dispatch(setPurchaseReturn(e.target.value));
              }}
              value={storeState.ETNetReceivableType}
              testid="PurchaseandreturnDecision"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"AssetPrice"}
              id="AssetValue"
              label="Asset Price"
              value={storeState.AssetPrice}
              type="text"
              onChange={(e) => {
                dispatch(setAssetPrice(e));
              }}
              scale={2}
              currency
              masking
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalPenalty}
              label="ET Penalty Method"
              onChange={(e) => {
                dispatch(setETPenaltyMethod(e.target.value));
              }}
              value={storeState.ETPenalty.ETPenaltyMethod}
              helperText="The Early Termination Penalty Method."
              testid="cbxEtPenaltyMethod"
            />
          </Grid>
          {getEtReference()}
          {getEtPeneltyPercentage()}
          {getETPenaltyMethod()}
        </Grid>
        <Receivables />
        <TaxDepositAndRepayments />
        <RepaymentPlan />
        <CalculateEarlyTermination
          cResponse={calRes}
          setcResponse={setCalRes}
        />
      </Box>
    </LayoutWithSideNavComponent>
  );
};
export default EarlyTermination;
