import { Box, Grid, unityTheme, ScrollableTabs } from "@ntpkunity/controls";
import FinancialCriteria from "./FinancialCriteria";
import { RootState } from "../../../calc-store/reducers";
import { useSelector } from "react-redux";
import { RepaymentPlanResponse } from "./RepaymentPlanResponse";
import RentalDetail from "./RentalDetail";
import { LayoutWithSideNavComponent } from "../../../modules";
import { IncomePostingResponse } from "./IncomePosting";

export const FinancialCalculation = () => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const columns = [] as any;
  columns.push({
    title: "Rental Detail",
    content: <RentalDetail rows={storeState.rentalDetailResponse} />,
  });
  columns.push({
    title: "Repayment Plan",
    content: <RepaymentPlanResponse rows={storeState.repaymentPlanDetail} />,
  });
  if (
    storeState.incomeposting == true &&
    storeState.incomePostingResponse.length > 0
  ) {
    columns.push({
      title: "Income Posting",
      content: (
        <IncomePostingResponse rows={storeState.incomePostingResponse} />
      ),
    });
  }
  const getTabs = () => {
    if (storeState.rentalDetailResponse.length > 0) {
      return (
        <ScrollableTabs
          theme={unityTheme}
          items={columns}
          defaultTabIndex={0}
        />
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <Box theme={unityTheme}>
        <Box theme={unityTheme}>
          <Grid theme={unityTheme}>
            <FinancialCriteria tabType="Rental" />
          </Grid>
        </Box>
        <Box theme={unityTheme} mb={10}>
          <Grid theme={unityTheme}>{getTabs()}</Grid>
        </Box>
      </Box>
    </LayoutWithSideNavComponent>
  );
};
