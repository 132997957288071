import { Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import { setStartDate } from "../../../calc-store/actions/actionsRepaymentPlanCalculation";
import { RootState } from "../../../calc-store/reducers";
import { DatePicker } from "../../controls/DateHelper/DatePicker";

export const StartDate = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducerRepaymentPlanCalculation
  );
  const dispatch = useDispatch();

  return (
    <Grid theme={unityTheme} container item spacing={3}>
      <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
        <DatePicker
          label="Start Date"
          testId="txtStartDate"
          value={storeState.startDate}
          onChange={(e: Date) => dispatch(setStartDate(e))}
          inputFormat="DD-MMM-YYYY"
        />
      </Grid>
    </Grid>
  );
};
