import { IReducerRepaymentPlanCalculation } from "../calc-store/reducers/reducersRepaymentPlanCalculation";
export const ValidationRepaymentplan = (
  state: IReducerRepaymentPlanCalculation
) => {
  if (state.startDate == undefined) {
    return {
      visible: true,
      error: "Please select Repayment Plan Date.",
    };
  } else if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.contractTerms == "" ||
    state.contractTerms == "0" ||
    state.contractTerms == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Contract Duration in Months.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending amount.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
