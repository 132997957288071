import { unityTheme, Typography } from "@ntpkunity/controls";
import { FormattedValue } from "../FormattedValue/FormattedValue";

interface IOutputvalue {
  value: string;
  prefix?: string;
  suffix?: string;
  formatValue?: boolean;
  helperText?: string;
}

export const Outputvalue = ({
  value,
  suffix = "",
  prefix = "",
  helperText = "",
  formatValue = true,
}: IOutputvalue) => {
  const getHelperText = () => {
    if (helperText.length > 0) {
      return (
        <Typography
          className="text-muted"
          theme={unityTheme}
          component="p"
          variant="body1"
        >
          {helperText}:
        </Typography>
      );
    } else {
      return <span />;
    }
  };
  return (
    <div>
      {getHelperText()}
      <Typography theme={unityTheme} component="h4" variant="h4">
        {formatValue ? (
          <FormattedValue value={value} prefix={prefix} suffix={suffix} />
        ) : (
          <span>{value}</span>
        )}
      </Typography>
    </div>
  );
};
