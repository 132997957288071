import {
  Grid,
  unityTheme,
  DatePicker,
  Box,
  Button,
  Typography,
} from "@ntpkunity/controls";
import { Dropdown, Radiobutton, TextBox } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import {
  rentalCalculationMethodsForRVExtension,
  rentalComponentsNoTax,
  rentalFrequencies,
  rentalModes,
  yesNo,
  yesNoValues,
} from "../../../common/constants";
import { useState } from "react";
import {
  setExtApr,
  setExtContractTerms,
  setExtIsTaxInclusive,
  setExtRentalCalcMethod,
  setExtRentalComponent,
  setExtRentalFreq,
  setExtRentalMode,
  setExtRvAmount,
  setExtTaxRate,
  setAddTax,
  SetRepaymentResponse,
} from "../../../calc-store/actions/actionExtendRV";
import { CalculateExtendRV } from "../../../helpers/ServiceHelper";
import { RepaymentPlanDetail } from "./ExtendRVPlanDetail";
import {
  PlanUploadDialog,
  IExcelColumn,
  IComponentPlan,
} from "../../controls/ExcelTable/ExcelTable";
import { setPlanMultipleData } from "../../../calc-store/actions/actionExcelData";
import data from "./Extend.data.json";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import CalculationHeader from "../../controls/CalculationHeader";
import { LayoutWithSideNavComponent } from "../../../modules";
import DateHelper from "../../controls/DateHelper/DateHelper";
import sample from "./Sample.data.json";

interface IExtendRV {}
const ExtendRV: React.FC<IExtendRV> = () => {
  const [calRes, setCalRes] = useState(false);
  const storeState = useSelector((state: RootState) => state.reducersExtendRV);
  const storeState2 = useSelector((state: RootState) => state.reducerExcelData);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const [planVisible, setPlanVisible] = useState(false);
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const dispatch = useDispatch();

  const getTaxRate = () => {
    if (storeState.addTax == "true") {
      return (
        <Grid theme={unityTheme} container spacing={5} marginBottom={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid={"taxRate"}
              id="taxRate"
              label="Tax Rate"
              value={storeState.taxRate}
              type="text"
              onChange={(e) => {
                dispatch(setExtTaxRate(e));
              }}
              endAdornment="%"
              masking
              scale={4}
              numeric
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={yesNo}
              label="Is Tax Inclusive"
              onChange={(e) => {
                dispatch(setExtIsTaxInclusive(e.target.value));
              }}
              value={storeState.IsTaxInclusive}
              testid="cbxExtIsTaxInclusive"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalComponentsNoTax}
              label="Rental Component"
              onChange={(e) => {
                dispatch(setExtRentalComponent(e.target.value));
              }}
              value={storeState.rentalComponent}
              testid="cbxExtRentalComponent"
            />
          </Grid>
        </Grid>
      );
    } else {
      <div />;
    }
  };

  const calculateExtendRV = () => {
    setCalculateEnabled(false);
    CalculateExtendRV(storeState, storeState2)
      .then((res) => {
        var Response = [];
        res.map((re) => {
          // re.rentalDueDate = re.rentalDueDate.split("T")[0];
          Response.push({
            content: [
              re.rentalNumber,
              <FormattedValue value={re.rentalAmount.toString()} />,
              re.rentalType,
              DateHelper(re.rentalDueDate),
              <FormattedValue value={re.rentalInterest.toString()} />,
              <FormattedValue value={re.rentalPrincipal.toString()} />,
              <FormattedValue
                value={re.rentalPrincipalExclusiveOfTax.toString()}
              />,
              <FormattedValue value={re.taxOnRentalPrincipal.toString()} />,
              <FormattedValue value={re.periodicInterest.toString()} />,
              <FormattedValue
                value={re.rentalInterestExclusiveOfTax.toString()}
              />,
              <FormattedValue
                value={re.periodicInterestExclusiveOfTax.toString()}
              />,
              <FormattedValue value={re.taxOnRentalAmount.toString()} />,
              <FormattedValue value={re.taxOnRentalInterest.toString()} />,
              <FormattedValue value={re.taxOnPeriodicInterest.toString()} />,
              <FormattedValue value={re.openingPrincipal.toString()} />,
              <FormattedValue value={re.closingPrincipal.toString()} />,
              <FormattedValue value={re.compoundInterest.toString()} />,
              <FormattedValue
                value={re.rentalAmountExclusiveOfTax.toString()}
              />,
            ],
          });
        });
        dispatch(SetRepaymentResponse(Response));
        setPlanMultipleData([]);
        setCalculateEnabled(true);
      })
      .catch((ex) => {
        setErrorDialog({ visible: true, error: ex.response.data });
      });
  };

  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <Box theme={unityTheme} className="main-content">
        <CalculationHeader text={"Extend RV"} />
        <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
          <Grid theme={unityTheme} container item spacing={3}>
            <Grid theme={unityTheme} item xs={12} sm={6} md={6} lg={6}>
              <Dropdown
                items={rentalCalculationMethodsForRVExtension}
                label="Rental Calculation"
                onChange={(e) => {
                  dispatch(setExtRentalCalcMethod(e.target.value));
                }}
                helperText=""
                value={storeState.rentalCalcMethod}
                testid="cbxExtRentalCalcMethod"
              />
            </Grid>
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalModes}
              label="Payment Mode"
              onChange={(e) => {
                dispatch(setExtRentalMode(e.target.value));
              }}
              //   disabled={storeState.rentalModeDisabled}
              value={storeState.rentalMode}
              helperText="If payment to be received at the beginning of the period or end of the period."
              testid="cbxExtRentalCalcRentalMode"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalFrequencies}
              label="Payment Frequency"
              onChange={(e) => {
                dispatch(setExtRentalFreq(e.target.value));
              }}
              value={storeState.rentalFrequency}
              helperText="The frequency of installment  amount to be paid."
              testid="cbxExtRentalCalcRentalFrequency"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtRvAmount"
              id="txtExtRvAmount"
              label="RV Amount"
              value={storeState.rvAmount}
              type="text"
              helperText="Total RV Amount."
              onChange={(e) => {
                dispatch(setExtRvAmount(e));
              }}
              masking
              scale={2}
              currency
              optional
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtTerms"
              id="txtExtTerms"
              label="Contract Duration in Months"
              value={storeState.contractTerms}
              type="text"
              helperText="Contract Duration in Months."
              masking
              numeric
              scale={0}
              thousandSeparator={false}
              onChange={(e) => {
                dispatch(setExtContractTerms(e));
              }}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtAPR"
              id="txtExtAPR"
              label="Interest Rate"
              value={storeState.APR}
              type="text"
              helperText="Interest Rate."
              onChange={(e) => {
                dispatch(setExtApr(e));
              }}
              endAdornment={"%"}
              numeric={true}
              masking={true}
              scale={4}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={12} md={12} lg={12}>
            <Box theme={unityTheme} marginTop={2} marginBottom={1}>
              <div>
                <Typography component="h4" variant="h4" theme={unityTheme}>
                  Would you like to add tax?
                </Typography>
              </div>
            </Box>
            <Box theme={unityTheme} marginBottom={1}>
              <Radiobutton
                options={yesNoValues}
                onChange={(e) => {
                  dispatch(setAddTax(e));
                }}
                value={storeState.addTax}
                defaultValue={storeState.addTax}
              />
            </Box>
            {getTaxRate()}
          </Grid>
        </Grid>

        <Grid theme={unityTheme}>
          {/* <Divider caption={"OTHER RECEIVABLES"}/>   */}
          <hr />
          <Grid
            theme={unityTheme}
            container
            spacing={4}
            direction="row"
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid theme={unityTheme} item xs={12} textAlign={"center"}>
              <Button
                className="btn-w-100"
                data-testid="UploadExcelFile"
                theme={unityTheme}
                onClick={() => setPlanVisible(true)}
                secondary
                text="Upload Excel File"
              />
            </Grid>
          </Grid>
          <PlanUploadDialog
            visible={planVisible}
            onClose={() => setPlanVisible(false)}
            components={data as IComponentPlan[]}
            sample={sample}
            dateField={4}
          />
          <hr />
        </Grid>
        <MessageDialog
          visible={errorDialog.visible}
          message={errorDialog.error}
          onClose={() => {
            setErrorDialog({
              visible: false,
              error: errorDialog.error,
            });
          }}
        />
        <Grid theme={unityTheme} container spacing={3} mb={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Button
              className="btn-w-100"
              data-testid="btnCalculate"
              theme={unityTheme}
              disabled={!calculateEnabled}
              onClick={calculateExtendRV}
              primary
              text="Calculate"
            />
          </Grid>
        </Grid>
        <RepaymentPlanDetail rows={storeState.RepaymentResponse} />
      </Box>
    </LayoutWithSideNavComponent>
  );
};
export default ExtendRV;
