import ActionTypes from "../actionTypes";
import IStructureRentalParm from "../../interfaces/IStructureRentalParm";

let structureRentalDetail: IStructureRentalParm[] = [];

export interface IReducerRepaymentPlanCalculation {
  repaymentCalcMethod: any;
  rentalCalcMethod: string;
  rentalMode: string;
  rentalFrequency: string;
  rentalStructure: string;
  repaymentStructure: string;
  structureRentalDetail: IStructureRentalParm[];
  residualValue: string;
  APR: string;
  contractTerms: string;
  financedAmount: string;
  taxRate: string;
  inclusivetax: boolean;
  rentalComponent: string;
  rentalDetailResponse: [];
  repaymentPlanResponse: [];
  repaymentAmortization: [];
  setRepaymentApiResponse: [];
  startDate: Date;
  rentalModeDisabled: boolean;
  isIncomePosting: boolean;
  addTax: false;
  extensionDays: number;
}

const initialState: IReducerRepaymentPlanCalculation = {
  rentalCalcMethod: "",
  rentalMode: "Arrear",
  repaymentStructure: "false",
  rentalFrequency: "Monthly",
  rentalStructure: "true",
  repaymentCalcMethod: "",
  structureRentalDetail: [
    {
      RentalId: 1,
      RentalType: "Structured",
      Amount: "0",
      TermFrom: 1,
      TermTo: 1,
    },
  ],
  residualValue: "",
  APR: "",
  contractTerms: "",
  financedAmount: "",
  taxRate: "",
  inclusivetax: true,
  rentalComponent: "",
  rentalDetailResponse: [],
  repaymentPlanResponse: [],
  setRepaymentApiResponse: [],
  repaymentAmortization: [],
  startDate: new Date(),
  rentalModeDisabled: false,
  isIncomePosting: false,
  addTax: false,
  extensionDays: undefined,
};
export function reducerRepaymentPlanCalculation(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_ADD_TAX:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_RP_RENTAL_CALC_METHOD:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_RP_RENTAL_MODE:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_RP_RENTAL_FREQ:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_RP_RENTAL_STRUCTURE:
      return {
        ...currentState,
        rentalStructure: action.payload,
      };
    case ActionTypes.SET_RP_RENTAL_STRUCTURES: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_RP_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_RP_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_RP_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_RP_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_RP_TAX: {
      return {
        ...currentState,
        taxRate: action.payload,
      };
    }
    case ActionTypes.SET_RP_INCLUSIVE_TAX: {
      return {
        ...currentState,
        inclusivetax: action.payload,
      };
    }
    case ActionTypes.SET_RP_RENTAL_COMPONENT: {
      return {
        ...currentState,
        rentalComponent: action.payload,
      };
    }

    case ActionTypes.SET_RP_RENTAL_DETAIL_RESPONSE: {
      return {
        ...currentState,
        rentalDetailResponse: action.payload,
      };
    }

    case ActionTypes.RESET_RP_STRUCTURE_RENTALS: {
      return {
        ...currentState,
        structureRentalDetail: [],
      };
    }
    case ActionTypes.RESET_REPAYMENT_PLAN: {
      return {
        ...currentState,
        repaymentStructure: action.payload,
      };
    }
    case ActionTypes.SET_RP_START_DATE: {
      return {
        ...currentState,
        startDate: action.payload,
      };
    }
    case ActionTypes.SET_RP_AMORTIZATION_RESPONSE: {
      return {
        ...currentState,
        repaymentPlanResponse: action.payload,
      };
    }
    case ActionTypes.DISABLE_RP_RENTAL_MODE:
      return {
        ...currentState,
        rentalModeDisabled: action.payload,
      };
    case ActionTypes.SET_RP_IS_INCOME_POSTING:
      return {
        ...currentState,
        isIncomePosting: action.payload,
      };
    case ActionTypes.Set_Amortization_By_Annuity_Response: {
      return {
        ...currentState,
        repaymentAmortization: action.payload,
      };
    }
    case ActionTypes.SET_RP_API_RESPONSE: {
      return {
        ...currentState,
        setRepaymentApiResponse: action.payload,
      };
    }
    case ActionTypes.Set_Extension_Days: {
      return {
        ...currentState,
        extensionDays: action.payload,
      };
    }
    default:
      return currentState;
  }
}
