import { Box, Button, Grid, unityTheme } from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStepRentals } from "../../../calc-store/actions/actionStepRentals";
import { RootState } from "../../../calc-store/reducers";
import { StepRentals } from "../../../common/enums";
import {
  CalculateStepUpRentalCalculation,
  CalculateStepDownRentalCalculation,
} from "../../../helpers/ServiceHelper";
import { ValidateSteprental } from "../../../validations/ValidateStepRental";
import { FormattedValue } from "../../controls/FormattedValue/FormattedValue";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";

export const CalculateRentals = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersStepRentals
  );
  const dispatch = useDispatch();
  const [calculateEnabled, setCalculateEnabled] = useState(true);
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const calculate = () => {
    var validate = ValidateSteprental(storeState.stepRentals);
    if (validate.visible) {
      setErrorDialog(validate);
    } else {
      setCalculateEnabled(false);
      if (storeState.stepRentals.stepType == StepRentals.StepUp) {
        CalculateStepUpRentalCalculation(storeState.stepRentals)
          .then((res) => {
            const rentalDetail = [] as any;
            const repaymentPlan = [] as any;
            res.rentals.map((r) => {
              rentalDetail.push({
                content: [
                  r.from,
                  r.to,
                  r.terms,
                  <FormattedValue value={r.changeRate.toString()} />,
                  <FormattedValue value={r.monthlyRental.toString()} />,
                ],
              });
            });
            res.repaymentPlan.map((r) => {
              repaymentPlan.push({
                content: [
                  r.rentalNumber,
                  <FormattedValue value={r.openingPrincipal.toString()} />,
                  <FormattedValue value={r.rentalAmount.toString()} />,
                  <FormattedValue value={r.rentalPrincipal.toString()} />,
                  <FormattedValue value={r.rentalInterest.toString()} />,
                  <FormattedValue value={r.periodicInterest.toString()} />,
                  <FormattedValue value={r.closingPrincipal.toString()} />,
                ],
              });
            });
            dispatch(
              setStepRentals({
                ...storeState.stepRentals,
                rentalDetail: rentalDetail,
                repaymentPlan: repaymentPlan,
              })
            );
            setCalculateEnabled(true);
          })
          .catch((ex) => {
            setErrorDialog({ visible: true, error: ex.response.data });
          });
      } else if (storeState.stepRentals.stepType == StepRentals.StepDown) {
        CalculateStepDownRentalCalculation(storeState.stepRentals)
          .then((res) => {
            const rentalDetail = [] as any;
            const repaymentPlan = [] as any;
            res.rentals.map((r) => {
              rentalDetail.push({
                content: [
                  r.from,
                  r.to,
                  r.terms,
                  r.changeRate,
                  <FormattedValue value={r.monthlyRental.toString()} />,
                ],
              });
            });
            res.repaymentPlan.map((r) => {
              repaymentPlan.push({
                content: [
                  r.rentalNumber,
                  <FormattedValue value={r.openingPrincipal.toString()} />,
                  <FormattedValue value={r.rentalAmount.toString()} />,
                  <FormattedValue value={r.rentalPrincipal.toString()} />,
                  <FormattedValue value={r.rentalInterest.toString()} />,
                  <FormattedValue value={r.periodicInterest.toString()} />,
                  <FormattedValue value={r.closingPrincipal.toString()} />,
                ],
              });
            });
            dispatch(
              setStepRentals({
                ...storeState.stepRentals,
                rentalDetail: rentalDetail,
                repaymentPlan: repaymentPlan,
              })
            );
            setCalculateEnabled(true);
          })
          .catch((ex) => {
            setErrorDialog({ visible: true, error: ex.response.data });
          });
      }
    }
  };
  return (
    <Box theme={unityTheme}>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() => {
          setErrorDialog({
            visible: false,
            error: errorDialog.error,
          });
        }}
      />
      <Grid theme={unityTheme} container spacing={4} mb={4} mt={2}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <Button
            className="btn-w-100"
            data-testid="btnCalculate"
            theme={unityTheme}
            disabled={!calculateEnabled}
            onClick={(e) => calculate()}
            primary
            text="Calculate"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
