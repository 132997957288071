import { Typography } from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Icon,
  unityTheme,
} from "@ntpkunity/controls";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { etCalculationMethods } from "../../../common/enums";
import {
  PlanUploadDialog,
  IComponentPlan,
} from "../../controls/ExcelTable/ExcelTable";
import NetReceivableComponent from "./data/netReceivable.data.json";
import DailyFutureInterest from "./data/dailyFutureInterest.data.json";
import sampleNetReceivable from "./data/sampleNetReceivables.data.json";
import etSample from "./data/etSample.data.json";
import MessageDialog from "../../controls/MessageDialog/MessageDialog";
import { ValidateEarlyTermination } from "../../../validations/ValidateEarlyTermination";
export const RepaymentPlan = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersEarlyTermination
  );
  const dispatch = useDispatch();
  const [errorDialog, setErrorDialog] = useState({ visible: false, error: "" });
  const [planVisible, setPlanVisible] = useState(false);
  var Repaymet: IComponentPlan[] = [
    {
      name: "Asset",
      rows: [],
      cols: [
        {
          name: "RentalNo.",
          title: "Rental No",
        },
        {
          name: "RentalDueDate",
          title: "Rental Due Date",
        },
        {
          name: "OpeningPrincipal",
          title: "Opening Principal",
        },
        {
          name: "NetRental",
          title: "Net Rental",
        },
        {
          name: "Status",
          title: "Status",
        },
        {
          name: "RentalInterest",
          title: "Rental Interest",
        },
        {
          name: "ClosingPrincipal",
          title: "Closing Principal",
        },
        {
          name: "InsuranceComponent",
          title: "Insurance Component",
        },
        {
          name: "RegistrationComponent",
          title: "Registration Component",
        },
      ],
    },
  ];

  const getdialog = () => {
    if (storeState.ETMethod == etCalculationMethods.NetReceivables) {
      return (
        <PlanUploadDialog
          visible={planVisible}
          onClose={() => setPlanVisible(false)}
          components={NetReceivableComponent as IComponentPlan[]}
          dateField={2}
          sample={sampleNetReceivable}
        />
      );
    } else if (
      storeState.ETMethod == etCalculationMethods.EffectiveAnnuity ||
      storeState.ETMethod == etCalculationMethods.DailyFutureInterest
    ) {
      return (
        <PlanUploadDialog
          visible={planVisible}
          onClose={() => setPlanVisible(false)}
          components={Repaymet}
          dateField={2}
          sample={etSample}
        />
      );
    }
  };
  return (
    <Grid theme={unityTheme}>
      <MessageDialog
        visible={errorDialog.visible}
        message={errorDialog.error}
        onClose={() =>
          setErrorDialog({ visible: false, error: errorDialog.error })
        }
      />
      <hr />
      <Grid
        theme={unityTheme}
        container
        spacing={4}
        direction="row"
        justifyContent={"center"}
        alignItems="center"
      >
        <Grid theme={unityTheme} item xs={12} textAlign={"center"}>
          <Button
            className="btn-w-100"
            data-testid="UploadRepaymentPlan"
            theme={unityTheme}
            onClick={() => {
              const validate = ValidateEarlyTermination(storeState);
              if (validate.visible) {
                setErrorDialog(validate);
              } else {
                setPlanVisible(true);
              }
            }}
            secondary
            text="Upload Repayment Plan"
          />
        </Grid>
      </Grid>
      {getdialog()}
      <hr />
    </Grid>
  );
};
