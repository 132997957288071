function getMonthName(monthNumber) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthNames[monthNumber];
}

export const DateHelper = (pDate: string | number | Date) => {
  let dateString = pDate.toString().substring(0, 10).split("-");
  return `${dateString[2]}-${getMonthName(parseInt(dateString[1]) - 1)}-${
    dateString[0]
  }`;
};

export default DateHelper;
