import {
  Box,
  Grid,
  Typography,
  unityTheme,
  IBoxProps,
} from "@ntpkunity/controls";
import { Dropdown, TextBox, Radiobutton } from "../../controls";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../calc-store/reducers";
import { StructureRental } from "./StructureRental";
import {
  GetBaseRateFrequency,
  CalculateBaseRateAPI,
  GetBaseRateSource,
  GetBaseRateConfiguration,
} from "../../../helpers/ServiceHelper";
import {
  incomePosting,
  rentalFrequencies,
  rentalModes,
  yesNoValues,
} from "../../../common/constants";
import {
  resetStructureRentals,
  setAPR,
  setContractTerms,
  setFinancedAmount,
  setRentalFrequency,
  setRentalMode,
  setRentalStructure,
  setRentalStructures,
  setResidualValue,
  setRepaymentPlanDetail,
  setRentalDetailResponse,
  setFinancialExtensionDays,
  setIncomePosting,
} from "../../../calc-store/actions/actionsRentalPlan";
import {
  IBaseRateConfig,
  getConfig,
} from "../../../local-store/base-rate-config.store";
import { CalculateRentals } from "./CalculateRentals";
import { RentalCalcMethod } from "./RentalCalcMethod";
import IStructureFinancialParam from "../../../interfaces/IStructureFinancialParam";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import {
  setBaseRateConfigurationResponse,
  setBaseRateFrequencyResponse,
  setBaseRateSourceResponse,
  setBaseRateValue,
} from "../../../calc-store/actions/actionsBaseRateApi";
import CalculationHeader from "../../controls/CalculationHeader";
import { RentalCalculationMethods } from "../../../common/enums";
import { LayoutWithSideNavComponent } from "../../../modules";

const MainContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.main-content": {
    ".custom-radio-wrap": {
      ".MuiFormGroup-row": {
        ".u-custom-control-label": {
          "&:not(:last-child)": {
            marginRight: 24,
          },
        },
      },
    },
    ".sub-section": {
      borderBottom: "1px solid" + theme.palette.grey[100],
    },
  },
}));

interface IFinancialCriteria {
  tabType: string;
}
type ComboItems = {
  value: string;
  text: string;
};

type BaseRateItems = {
  id: string;
  frequency_title: string;
  source_title: string;
  base_rate: string;
};

const FinancialCriteria: React.FC<IFinancialCriteria> = ({
  tabType,
}: IFinancialCriteria) => {
  const storeState = useSelector((state: RootState) => state.reducerRentalPlan);
  const baseRateStore = useSelector(
    (state: RootState) => state.reducerBaseRate
  );
  const dispatch = useDispatch();
  const [source, setSource] = useState("");
  const [frequency, setFrequency] = useState("");
  const [baseRate, setBaseRate] = useState(0);
  const [interest, setInterest] = useState("false");
  const [margin, setMargin] = useState(0);
  const calculateAPR = (baseRate, margin) => {
    return parseFloat(baseRate) + parseFloat(margin);
  };
  useEffect(() => {
    GetBaseRateSource()
      .then((res: any) => {
        var items: ComboItems[] = [];
        res.map((r) => {
          items.push({
            value: r.id,
            text: r.title,
          });
        });
        dispatch(setBaseRateSourceResponse(items));
      })
      .catch((ex) => {
        ex.response;
      });

    GetBaseRateConfiguration()
      .then((res: any) => {
        var items: BaseRateItems[] = [];
        res.map((r) => {
          items.push({
            id: r.id,
            frequency_title: r.frequency,
            source_title: r.Source,
            base_rate: r.base_rate,
          });
        });
        dispatch(setBaseRateConfigurationResponse(items));
      })
      .catch((ex) => {
        ex.response;
      });
  }, []);

  const set_APR = (baseRateConfig: IBaseRateConfig) => {
    var _baseRate = getConfig(baseRateConfig);
    setBaseRate(_baseRate);
    dispatch(setAPR(calculateAPR(_baseRate, margin)));
  };
  const addDefaultRental = () => {
    var structures = storeState.structureRentalDetail;
    var count = structures.length;
    var newRental: IStructureFinancialParam = {
      RentalId: count + 1,
      RentalType: "Structured",
      Amount: "0.0",
      TermFrom: 1,
      TermTo: 1,
    };
    structures.push(newRental);
    dispatch(setRentalStructures(structures));
  };

  const GetFrequency = (param) => {
    GetBaseRateFrequency(param)
      .then((res) => {
        var items: ComboItems[] = [];
        res.map((r) => {
          items.push({
            value: r.id,
            text: r.title,
          });
        });
        dispatch(setBaseRateFrequencyResponse(items));
      })
      .catch((ex) => {
        ex.response.data;
      });
  };
  const getbenchmark = () => {
    if (interest == "true") {
      return (
        <div>
          <hr />
          <Box theme={unityTheme}>
            <Grid theme={unityTheme} container item spacing={3} mb={3}>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={baseRateStore.baseRateSourceResponse}
                  label="Source"
                  onChange={(e) => {
                    {
                      GetFrequency(e.target.value);
                    }
                    setSource(e.target.value);
                  }}
                  value={source}
                  testid="cbxRentalSource"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <Dropdown
                  items={baseRateStore.baseRateFrequencyResponse}
                  label="Frequency"
                  onChange={(e) => {
                    {
                      GetBaseRate(e.target.value);
                    }
                    setFrequency(e.target.value);
                  }}
                  value={frequency}
                  testid="cbxBaseRateFrequency"
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <TextBox
                  label="Base Rate"
                  value={baseRate}
                  type="text"
                  onChange={(e) => {
                    setBaseRate(e);
                    dispatch(setAPR(calculateAPR(e, margin)));
                  }}
                  endAdornment={"%"}
                  scale={4}
                  numeric
                  masking
                />
              </Grid>
              <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
                <TextBox
                  label="Margin"
                  value={margin}
                  type="text"
                  onChange={(e) => {
                    setMargin(e);
                    dispatch(setAPR(calculateAPR(baseRate, e)));
                  }}
                  endAdornment={"%"}
                  scale={4}
                  numeric
                  masking
                />
              </Grid>
            </Grid>
          </Box>
          <hr />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const GetBaseRate = (param) => {
    CalculateBaseRateAPI(param)
      .then((res: any) => {
        res.map((r) => {
          setBaseRate(r.base_rate.toString()),
            dispatch(
              setBaseRateValue(parseFloat(r.base_rate.toString()).toFixed(4))
            );
        });
      })
      .catch((ex) => {
        ex.response.data;
      });
  };
  const GetExtensionDays = () => {
    if (
      storeState.rentalCalcMethod == RentalCalculationMethods.Annuity ||
      storeState.rentalCalcMethod == RentalCalculationMethods.Annuity365
    ) {
      return (
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"ExtensionDays"}
            id="ExtensionDays"
            label="Extension Days"
            value={storeState.extensionDays}
            type="number"
            onChange={(e) => {
              dispatch(setFinancialExtensionDays(e));
            }}
            helperText="Extension Days."
            optional
          />
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };
  return (
    <MainContent theme={unityTheme} className="main-content">
      <CalculationHeader text={"Rental & Repayment Plan"} />
      <Box theme={unityTheme} mb={4} mt={2}>
        <RentalCalcMethod />
      </Box>
      <Box theme={unityTheme} mb={2}>
        <Grid theme={unityTheme} container item spacing={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalModes}
              label="Payment Mode"
              onChange={(e) => {
                dispatch(setRentalMode(e.target.value));
              }}
              disabled={storeState.rentalModeDisabled}
              value={storeState.rentalMode}
              helperText="If payment to be received at the beginning of the period or end of the period."
              testid="cbxRentalMode"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={rentalFrequencies}
              label="Payment Frequency"
              onChange={(e) => {
                dispatch(setRentalFrequency(e.target.value));
              }}
              value={storeState.rentalFrequency}
              helperText="The frequency of the installment amount to be paid."
              testid="cbxRentalFrequency"
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtTerms"
              id="txtTerms"
              label="Contract Duration in Months"
              value={storeState.contractTerms}
              type="text"
              helperText="Contract Duration in Months."
              masking
              numeric
              scale={0}
              thousandSeparator={false}
              onChange={(e) => {
                dispatch(setContractTerms(e));
              }}
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtFinancedAmount"
              id="txtFinancedAmount"
              label="Lending Amount"
              value={storeState.financedAmount}
              type="text"
              helperText="Final lending amount."
              onChange={(e) => {
                dispatch(setFinancedAmount(e));
              }}
              masking
              scale={2}
              currency
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtResidualValue"
              id="txtResidualValue"
              label="Residual Value"
              value={storeState.residualValue}
              type="text"
              helperText="Residual Value of the asset paid at the end of the contract."
              onChange={(e) => {
                dispatch(setResidualValue(e));
              }}
              masking
              scale={2}
              currency
              optional
            />
          </Grid>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <TextBox
              testid="txtAPR"
              id="txtAPR"
              label="Interest Rate"
              value={storeState.APR}
              type="text"
              helperText="Interest Rate."
              onChange={(e) => {
                dispatch(setAPR(e));
              }}
              endAdornment={"%"}
              numeric={true}
              masking={true}
              scale={4}
            />
          </Grid>
          {GetExtensionDays()}
        </Grid>
      </Box>
      {/* <Grid container theme={unityTheme}>
        <Typography
          theme={unityTheme}
          mb={2}
          component="span"
          variant="subtitle2"
        >
          Benchmark Rates:
        </Typography>
      </Grid>
      <Box theme={unityTheme} mt={1}>
        <Radiobutton
          options={yesNoValues}
          onChange={(e) => {
            setInterest(e);
          }}
          defaultValue={false}
        />
      </Box>
      {getbenchmark()} */}
      <Box theme={unityTheme} mb={4}>
        <Grid theme={unityTheme} container spacing={3}>
          <Grid
            theme={unityTheme}
            item
            xs={12}
            data-testid="txtStructureRentals"
          >
            <Typography component="span" variant="subtitle2" theme={unityTheme}>
              Irregular Payments:
            </Typography>
            <Box theme={unityTheme} mt={2}>
              <Radiobutton
                options={yesNoValues}
                onChange={(e) => {
                  dispatch(resetStructureRentals());
                  if (e == "true") {
                    addDefaultRental();
                    dispatch(setRentalDetailResponse([]));
                    dispatch(setRepaymentPlanDetail([]));
                  }
                  dispatch(setRentalStructure(e));
                }}
                value={storeState.rentalStructure}
                defaultValue={storeState.rentalStructure}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <StructureRental />
      <Box theme={unityTheme} mb={4}>
        <Grid theme={unityTheme} container spacing={3}>
          <Grid theme={unityTheme} item xs={12} data-testid="txtIncomePosting">
            <Typography component="span" variant="subtitle2" theme={unityTheme}>
              Income Posting (Annuity Basis):
            </Typography>
            <Box theme={unityTheme} mt={2}>
              <Radiobutton
                options={incomePosting}
                onChange={(e) => {
                  dispatch(setIncomePosting(e == "true" ? true : false));
                  dispatch(setRentalDetailResponse([]));
                  dispatch(setRepaymentPlanDetail([]));
                }}
                defaultValue={String(storeState.incomeposting)}
                value={String(storeState.incomeposting)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CalculateRentals />
    </MainContent>
  );
};
export default FinancialCriteria;
