import ActionTypes from "../actionTypes";
import IStructureRentalParm from "../../interfaces/IStructureRentalParm";
import IIncomeExpense from "../../interfaces/IIncomeExpense";

let structureRentalDetail: IStructureRentalParm[] = [];
let IIncomeExpenseDetail: IIncomeExpense[] = [];

export interface IReducerAnnuityStraightLineCalculation {
  rentalMode: string;
  rentalFrequency: string;
  rentalStructure: string;
  IncomeExpense: string;
  repaymentStructure: string;
  structureRentalDetail: IStructureRentalParm[];
  IIncomeExpenseDetail: IIncomeExpense[];
  residualValue: string;
  APR: string;
  contractTerms: string;
  financedAmount: string;
  taxRate: number;
  inclusiveTax: boolean;
  rentalComponent: string;
  rentalDetailResponse: [];
  startDate: Date;
  rentalModeDisabled: boolean;
  addTax: boolean;
  isRoundingRequired: Boolean;
  roundingUpTo: number;
}

const initialState: IReducerAnnuityStraightLineCalculation = {
  rentalMode: "",
  repaymentStructure: "",
  rentalFrequency: "",
  rentalStructure: "true",
  IncomeExpense: "true",
  structureRentalDetail: [
    {
      RentalId: 1,
      RentalType: "Structured",
      Amount: "",
      TermFrom: 1,
      TermTo: 1,
    },
  ],
  IIncomeExpenseDetail: [
    {
      Id: 1,
      ComponentName: "",
      Amount: "",
      IsIncome: "",
      IsSubsidy: "",
    },
  ],
  residualValue: "",
  APR: "",
  contractTerms: "",
  financedAmount: "",
  taxRate: 0,
  inclusiveTax: false,
  rentalComponent: "",
  rentalDetailResponse: [],
  startDate: new Date(),
  rentalModeDisabled: false,
  addTax: false,
  isRoundingRequired: false,
  roundingUpTo: 0,
};
export function reducerAnnuityStraightLineCalculation(
  currentState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.SET_ANNUITY_RENTAL_MODE:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_ANNUITY_RENTAL_FREQUENCY:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_ANNUITY_RENTAL_STRUCTURE:
      return {
        ...currentState,
        rentalStructure: action.payload,
      };
    case ActionTypes.SET_ANNUITY_RENTAL_STRICTURES: {
      return {
        ...currentState,
        structureRentalDetail: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_INCOME_EXPENSE:
      return {
        ...currentState,
        IncomeExpense: action.payload,
      };
    case ActionTypes.SET_ANNUITY_INCOME_EXPENSES: {
      return {
        ...currentState,
        IncomeExpenseDetail: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_FINANCED_AMOUNT: {
      return {
        ...currentState,
        financedAmount: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_RESIDUAL_VALUE: {
      return {
        ...currentState,
        residualValue: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_TAX_RATE: {
      return {
        ...currentState,
        taxRate: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_IS_INCLUSIVE_TAX: {
      return {
        ...currentState,
        inclusiveTax: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_COMPONENT_NAME: {
      return {
        ...currentState,
        rentalComponent: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_ADD_TAX: {
      return {
        ...currentState,
        addTax: action.payload,
      };
    }
    case ActionTypes.SET_ANNUITY_IS_ROUNDING_REQUIRED:
      return {
        ...currentState,
        isRoundingRequired: action.payload,
      };
    case ActionTypes.SET_ANNUITY_ROUNDING_UP_TO:
      return {
        ...currentState,
        roundingUpTo: action.payload,
      };
    case ActionTypes.SET_ANNUITY_START_DATE:
      return {
        ...currentState,
        startDate: action.payload,
      };
    default:
      return currentState;
  }
}
