import {
  Dialog,
  Grid,
  Button,
  Table,
  unityTheme,
  FileDragDrop,
  Box,
  Icon,
} from "@ntpkunity/controls";
import { useEffect, useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { useDispatch, useSelector } from "react-redux";
import {
  setExcelData,
  setPlanMultipleData,
} from "../../../calc-store/actions/actionExcelData";
import { RootState } from "../../../calc-store/reducers";
import Dropdown from "../Dropdown/DropDown";
import generateExcel from "zipcelx";
import EtComp from "../../tabs/earlyTermination/data/sampleEtComp.data.json";

export type IExcelColumn = {
  title: string;
  name: string;
};

export type IComponentPlan = {
  name: string;
  rows: [];
  cols: IExcelColumn[];
};

type IPlanUploadDialog = {
  visible: boolean;
  onClose?(): void;
  hasHeader?: boolean;
  components?: IComponentPlan[];
  dateField?: number;
  sample?: any;
};

export const PlanUploadDialog = ({
  visible = false,
  hasHeader = true,
  components,
  dateField = undefined,
  ...props
}: IPlanUploadDialog) => {
  var [rows, setRows] = useState([]);
  const [dropdownValue, setDropDownValue] = useState("");
  const [cols, setcols] = useState<string[]>([]);
  const [multipleData, setMultipleData] = useState([]);
  const [excelfile, getExcelfile] = useState(true);
  const dispatch = useDispatch();
  const storeState = useSelector((state: RootState) => state.reducerExcelData);

  useEffect(() => {
    setcols([]);
    setMultipleData(storeState.ExcelMultiple);
  }, [visible]);

  const getItem = () => {
    var content = [];
    components.map((r) => {
      content.push({
        key: "",
        value: r.name,
        text: r.name,
      });
    });
    return content;
  };
  const importFile = (event) => {
    ExcelRenderer(event, (err, resp) => {
      if (err) {
        throw err;
      } else {
        var structures = [];
        if (hasHeader) {
          resp.rows.shift();
        }
        resp.rows.map((row) => {
          var content = [];
          for (let i = 0; i < row.length; i++) {
            content.push(row[i]);
          }
          if (dateField != undefined && !isNaN(content[dateField - 1])) {
            content[dateField - 1] = ExcelDateToJSDate(content[dateField - 1]);
          }
          structures.push({
            content: content,
          });
        });
        const content = multipleData;
        content.push({
          name: dropdownValue,
          rows: structures,
          cols: cols,
        });
        setRows(structures);
        setMultipleData(content);
        getExcelfile(true);
      }
    });
  };
  const file = () => {
    if (excelfile == true) {
      const OnFilesDrag = (e) => {
        if (e?.dataTransfer?.files) {
          importFile(e.dataTransfer.files[0]);
        }
      };
      const OnFilesSelect = (event) => {
        if (event && event?.target && event?.target?.files) {
          importFile(event.target.files[0]);
        }
      };
      return (
        <FileDragDrop
          theme={unityTheme}
          onChange={OnFilesSelect}
          onDrop={OnFilesDrag}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const ExcelDateToJSDate = (date) => {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    let converted_dates = String(converted_date).slice(4, 15);
    date = converted_dates.split(" ");
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
    if (month.toString().length <= 1) month = "0" + month;
    let year = date[2];
    return String(year + "-" + month + "-" + day);
  };

  const getComponentName = () => {
    return (
      <Box theme={unityTheme}>
        <Grid theme={unityTheme} container item spacing={3}>
          <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
            <Dropdown
              items={getItem()}
              label="Component"
              onChange={(e) => {
                setRows([]);
                setDropDownValue(e.target.value);
                const check = components.find((element) => {
                  return element.name === e.target.value;
                });
                const co = [];
                check.cols.map((r) => {
                  co.push(r.title);
                });
                setcols(co);
                rows = [];
                multipleData.find((element) => {
                  if (e.target.value == element.name) {
                    setRows(element.rows);
                  }
                });
              }}
              value={dropdownValue}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getSample = () => {
    if (dropdownValue != "") {
      return (
        <Button
          theme={unityTheme}
          text="Sample Format"
          onClick={() => {
            const config = {
              filename: "Sample",
              sheet: {
                data: [],
              },
            };
            const columnn = [];
            const rowss = [];
            cols.map((r) => {
              columnn.push({
                value: r,
                type: "string",
              });
            });
            config.sheet.data.push(columnn);
            config.sheet.data.push(
              dropdownValue == "Asset" ? props.sample : EtComp
            );
            generateExcel(config);
          }}
        />
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <Dialog
      theme={unityTheme}
      size="lg"
      // noFooter={true}
      customFooter={
        <>
          <Button
            className="btn-w-100"
            theme={unityTheme}
            text="Save Changes"
            primary
            onClick={() => {
              dispatch(setPlanMultipleData(multipleData));
              setRows([]);
              setMultipleData([]);
              setDropDownValue("");
              props.onClose();
            }}
          />
        </>
      }
      open={visible}
      onCloseDialog={() => {
        setRows([]);
        getExcelfile(true);
        setDropDownValue("");
        props.onClose?.();
      }}
    >
      {getComponentName()}
      {getSample()}
      <Grid
        container
        xs={12}
        theme={unityTheme}
        mt={{ xs: 0, md: -3 }}
        justifyContent="flex-end"
      >
        <Button
          theme={unityTheme}
          defaultBtn
          onClick={() => {
            setMultipleData(
              multipleData.filter((current) => {
                return current.name !== dropdownValue;
              })
            );
            setRows([]);
          }}
          iconText={<Icon name="DeleteIcon" />}
        />
      </Grid>
      <Table columns={cols} rows={rows} theme={unityTheme} />
      {file()}
    </Dialog>
  );
};
