import { Input, Box, unityTheme, Tooltip } from "@ntpkunity/controls";
import { ReactNode, useEffect, useState } from "react";
import { flexTheme } from "../../../shared";

interface Props {
  label: string;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  type: string;
  value?: any;
  onChange?(e): void;
  startAdornment?: ReactNode | string;
  endAdornment?: ReactNode | string;
  onBlur?(e): void;
  currency?: boolean;
  numeric?: boolean;
  masking?: boolean;
  mask?: string;
  scale?: number;
  dynamic?: boolean;
  unMaskedValue?: boolean;
  error?: string;
  id?: string;
  testid?: string;
  min?: number;
  max?: number;
  allowNegative?: boolean;
  optional?: boolean;
  thousandSeparator?: boolean;
}

const TextBox: React.FC<Props> = (props: Props) => {
  let {
    label = "",
    helperText = "",
    disabled = false,
    required = false,
    type = "text",
    value = "",
    onChange,
    startAdornment = "",
    endAdornment = "",
    onBlur,
    masking = false,
    currency = false,
    thousandSeparator = false,
    numeric = false,
    mask = " ",
    scale = 2,
    min = 0,
    max = Number.MAX_VALUE,
    allowNegative = false,
    dynamic = true,
    unMaskedValue = true,
    error = "",
    id = "textbox",
    testid = "",
    optional = false,
  } = props;

  const getLabel = () => {
    if (optional) {
      return label + " (Optional)";
    } else {
      return label;
    }
  };

  return (
    <Tooltip theme={unityTheme} title={helperText} placement={"right-end"}>
      <Input
        label={getLabel()}
        //helperText={helperText}
        theme={flexTheme}
        type={type}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        fullWidth
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        masking={masking}
        inputMask={mask}
        maskCurrency={currency}
        thousandSeparator={currency}
        maskDecimalScale={scale}
        min={min}
        max={max}
        allowNegative={allowNegative}
        maskNumeric={numeric}
        // dynamic={!dynamic && (numeric || currency) ? false : dynamic}
        dynamic={dynamic}
        placeholder=" "
        unMaskedValue={unMaskedValue}
        error={error}
        testid={testid}
      />
    </Tooltip>
  );
};

export default TextBox;
