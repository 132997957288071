import { IReducerRentalCalculation } from "../calc-store/reducers/reducersRentalCalculation";
export const ValidateRentalCalculation = (state: IReducerRentalCalculation) => {
  if (state.rentalCalcMethod == "" || state.rentalCalcMethod == undefined) {
    return {
      visible: true,
      error: "Please select Rental Calculation Method.",
    };
  } else if (state.rentalMode == "" || state.rentalMode == undefined) {
    return {
      visible: true,
      error: "Please Select Payment Mode.",
    };
  } else if (
    state.rentalFrequency == "" ||
    state.rentalFrequency == undefined
  ) {
    return {
      visible: true,
      error: "Please select Payment Frequency.",
    };
  } else if (
    state.contractTerms == "" ||
    state.contractTerms == "0" ||
    state.contractTerms == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Contract Duration in Months.",
    };
  } else if (
    state.financedAmount == "" ||
    state.financedAmount == "0" ||
    state.financedAmount == undefined
  ) {
    return {
      visible: true,
      error: "Please provide valid Lending amount.",
    };
  } else if (
    state.rentalStructure == "true" &&
    state.structureRentalDetail.length <= 0
  ) {
    return {
      visible: true,
      error:
        "Structure Rentals is selected 'Yes'. Please provide atleast one Structure Rental.",
    };
  } else {
    return {
      visible: false,
      error: "",
    };
  }
};
