import IExtendRVParm from "../../interfaces/IExtendRV";
import ActionTypes from "../actionTypes";

let IExtendRVPlanDetail: IExtendRVParm[] = [];

export interface IReducerExtendRV {
  rentalCalcMethod: string;
  rentalMode: string;
  rentalFrequency: string;
  rvAmount: string;
  contractTerms: string;
  rentalComponent: number;
  APR: string;
  taxRate: number;
  IsTaxInclusive: boolean;
  addTax: boolean;
  RepaymentResponse: [];
}
const initialState: IReducerExtendRV = {
  rentalCalcMethod: "Annuity",
  rentalMode: "",
  rentalFrequency: "",
  rvAmount: "",
  contractTerms: "",
  rentalComponent: 0,
  APR: "",
  taxRate: 0,
  IsTaxInclusive: false,
  addTax: false,
  RepaymentResponse: [],
};
export function reducersExtendRV(currentState = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.SET_EXT_RENTAL_CALC_METHOD:
      return {
        ...currentState,
        rentalCalcMethod: action.payload,
      };
    case ActionTypes.SET_EXT_RENTAL_MODE:
      return {
        ...currentState,
        rentalMode: action.payload,
      };
    case ActionTypes.SET_EXT_RENTAL_FREQ:
      return {
        ...currentState,
        rentalFrequency: action.payload,
      };
    case ActionTypes.SET_EXT_RV_AMOUNT: {
      return {
        ...currentState,
        rvAmount: action.payload,
      };
    }
    case ActionTypes.SET_EXT_CONTRACT_TERMS: {
      return {
        ...currentState,
        contractTerms: action.payload,
      };
    }
    case ActionTypes.SET_EXT_RENTAL_COMPONENT: {
      return {
        ...currentState,
        rentalComponent: action.payload,
      };
    }

    case ActionTypes.SET_EXT_APR: {
      return {
        ...currentState,
        APR: action.payload,
      };
    }
    case ActionTypes.SET_EXT_TAX_RATE: {
      return {
        ...currentState,
        taxRate: action.payload,
      };
    }
    case ActionTypes.SET_EXT_IS_TAX_INCLUSIVE: {
      return {
        ...currentState,
        IsTaxInclusive: action.payload,
      };
    }
    case ActionTypes.SET_EXT_ADD_TAX: {
      return {
        ...currentState,
        addTax: action.payload,
      };
    }
    case ActionTypes.SET_EXT_REPAYMENT_RESPONSE: {
      return {
        ...currentState,
        RepaymentResponse: action.payload,
      };
    }
    default:
      return currentState;
  }
}
