import ActionTypes from "../actionTypes";

export function setRentalMode(parm: string = "") {
  return {
    type: ActionTypes.SET_ANNUITY_RENTAL_MODE,
    payload: parm,
  };
}
export function setRentalFrequency(parm: string = "") {
  return {
    type: ActionTypes.SET_ANNUITY_RENTAL_FREQUENCY,
    payload: parm,
  };
}
export function setContractTerms(parm: number = 12) {
  return {
    type: ActionTypes.SET_ANNUITY_CONTRACT_TERMS,
    payload: parm,
  };
}
export function setAPR(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_ANNUITY_APR,
    payload: parm,
  };
}
export function setFinancedAmount(parm: any) {
  return {
    type: ActionTypes.SET_ANNUITY_FINANCED_AMOUNT,
    payload: parm,
  };
}
export function setResidualValue(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_ANNUITY_RESIDUAL_VALUE,
    payload: parm,
  };
}
export function setRentalStructure(parm: string = "false") {
  return {
    type: ActionTypes.SET_ANNUITY_RENTAL_STRUCTURE,
    payload: parm,
  };
}
export function setRentalStructures(parm: any) {
  return {
    type: ActionTypes.SET_ANNUITY_RENTAL_STRICTURES,
    payload: parm,
  };
}
export function setIncomeExpense(parm: string = "false") {
  return {
    type: ActionTypes.SET_ANNUITY_INCOME_EXPENSE,
    payload: parm,
  };
}
export function setIncomeExpenses(parm: any) {
  return {
    type: ActionTypes.SET_ANNUITY_INCOME_EXPENSES,
    payload: parm,
  };
}
export function setTaxRate(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_ANNUITY_TAX_RATE,
    payload: parm,
  };
}
export function setInclusiveTax(parm: boolean) {
  return {
    type: ActionTypes.SET_ANNUITY_IS_INCLUSIVE_TAX,
    payload: parm,
  };
}
export function setRentalComponent(parm: number = 0.0) {
  return {
    type: ActionTypes.SET_ANNUITY_COMPONENT_NAME,
    payload: parm,
  };
}
export function setAddTax(parm: boolean) {
  return {
    type: ActionTypes.SET_ANNUITY_ADD_TAX,
    payload: parm,
  };
}
export function setIsRoundingRequired(parm: boolean) {
  return {
    type: ActionTypes.SET_ANNUITY_IS_ROUNDING_REQUIRED,
    payload: parm,
  };
}
export function setRoundingUpTp(parm: number) {
  return {
    type: ActionTypes.SET_ANNUITY_ROUNDING_UP_TO,
    payload: parm,
  };
}
export function setStartDate(parm: any) {
  return {
    type: ActionTypes.SET_ANNUITY_START_DATE,
    payload: parm,
  };
}
