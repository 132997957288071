import { Grid, unityTheme } from "@ntpkunity/controls";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalCharges,
  setSundryCharges,
  setLatePaymentCharges,
} from "../../../calc-store/actions/actionEarlyTermination";
import { RootState } from "../../../calc-store/reducers";
import { TextBox } from "../../controls";
import { Divider } from "../../controls/Divider/Divider";

export const Receivables = () => {
  const storeState = useSelector(
    (state: RootState) => state.reducersEarlyTermination
  );
  const dispatch = useDispatch();
  return (
    <div>
      <hr />
      <Grid theme={unityTheme} container spacing={3} marginBottom={2}>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"SundryCharges"}
            id="SundryCharges"
            label="Sundry Charges"
            value={storeState.SundryCharges}
            type="text"
            onChange={(e) => {
              dispatch(setSundryCharges(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"AdditionalCharges"}
            id="AdditionalCharges"
            label="Additional Charges"
            value={storeState.Additionalcharges}
            type="text"
            onChange={(e) => {
              dispatch(setAdditionalCharges(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
        <Grid theme={unityTheme} item xs={12} sm={4} md={4} lg={4}>
          <TextBox
            testid={"LatePaymentPenalty"}
            id="LatePaymentPenalty"
            label="Late Payment Penalty"
            value={storeState.LatePaymentCharges}
            type="text"
            onChange={(e) => {
              dispatch(setLatePaymentCharges(e));
            }}
            scale={2}
            currency
            masking
          />
        </Grid>
      </Grid>
    </div>
  );
};
